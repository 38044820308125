.mainContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  max-width: 324px;
  width: 100%;
  .logo {
    width: 137px;
    height: 26.62px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .Mbutton {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, 50%);

    border-radius: 40px;
    background: #47d7ac;
    display: block;
    width: 90%;
    margin-top: 400px;
    height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    z-index: 1111;
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 700;
    font-size: 14.463px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    a {
      color: #243746;
    }
  }

  .MenuItems {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 70px;
    .items {
      display: flex;
      //   justify-content: center;
      width: 227px;
      height: 24px;
      font-family: Gilroy-Medium;

      font-size: 26px;
      line-height: 24px;

      color: #ffffff;
    }
    .subItems {
      display: flex;
      //   justify-content: center;
      width: 227px;
      height: 24px;
      font-family: Gilroy-Regular;

      font-size: 22px;
      line-height: 24px;

      color: #ffffff;
    }

    .divider {
      width: 86.4vw;
      height: 0px;

      border: 1px solid #ffffff;
      opacity: 0.7;
    }
  }
}
