.container {
  display: flex;
  flex-direction: column;
  row-gap: 4.562043795620438vh;
  justify-content: center;
  align-items: center;
  .summaryText {
    font-family: Gilroy-Bold;
    font-size: 6.25vw;
    line-height: 104.5%;
    text-align: center;
  }
  .labelText {
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: 900;
    font-size: 1.25vw;
    line-height: 2.645985401459854vh;
    color: #243746;

    width: 10.416666666666666vw;
    height: 4.6992481203007515vh;

    text-align: center;

    /* Neutral / 600 */

    color: #243746;
  }
}
@media (max-width: 900px) {
  .container {
    width: 160px;
    row-gap: 1vh;
    .summaryText {
      font-size: 50px;
      line-height: 104.5%;
    }
    .labelText {
      font-size: 16px;
      line-height: 2.645985401459854vh;
      width: 100%;
    }
  }
}
