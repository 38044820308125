.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .heroContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    width: 100%;
    .leftContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 35%;
      row-gap: 3.6458333333333335vw;

      .textContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1.3020833333333333vw;
        width: 60%;

        .heading {
          font-family: Gilroy-Bold;
          font-weight: lighter;
          text-transform: uppercase;

          width: 23.125vw;
          height: 100%;

          font-size: 2.34375vw;
          line-height: 2.7604166666666665vw;
          /* or 31px */

          /* Neutral / 600 */

          color: #243746;
        }

        .subText {
          width: 23.125vw;
          height: 100%;

          font-family: Gilroy-Light;
          font-weight: lighter;
          font-size: 1.25vw;
          line-height: 1.4583333333333333vw;

          color: #4d4d4d;
        }
        .MbtnContainer {
          display: none;
        }
      }

      .cards {
        width: 60%;
        display: flex;
      }
    }

    .rightContainer {
      width: 65%;
      height: 51.458333333333336vw;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 54px;
    padding-top: 50px;

    .heroContainer {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      align-items: center;
      height: 100%;

      width: 100%;
      .leftContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        row-gap: 80px;

        .textContainer {
          display: flex;
          flex-direction: column;
          row-gap: 22px;
          width: 100%;
          margin-top: 54px;

          .heading {
            font-family: Gilroy-Bold;
            font-weight: lighter;
            text-transform: uppercase;

            width: 100%;
            height: 100%;

            font-size: 24px;
            line-height: 29px;
            text-align: center;
            /* or 31px */

            /* Neutral / 600 */

            color: #243746;
          }

          .subText {
            width: 100%;
            height: 100%;

            font-family: Gilroy-Light;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;

            color: #4d4d4d;
          }
          .MbtnContainer {
            display: none;
          }
        }

        .cards {
          width: 80%;
          display: flex;
        }
      }

      .rightContainer {
        width: 100%;
        height: 63.2vw;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
