.mainContainer {
  display: flex;
  height: 100%;
  width: 100%;
  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5.46875vw;
    row-gap: 2.65625vw;
    width: 100%;
    margin-bottom: 4.739583333333333vw;
    .mainText {
      width: 71.30208333333333vw;
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: bold;
      font-size: 2.34375vw;
      line-height: 2.8125vw;
      text-align: center;

      color: #243746;
    }
    .subText {
      width: 38.177083333333336vw;

      font-family: Gilroy-Light;
      font-style: normal;
      font-weight: normal;
      font-size: 1.25vw;
      line-height: 1.4583333333333333vw;
      text-align: center;

      color: #4d4d4d;
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    .textContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 43px;
      // height:100%;
      row-gap: 37px;
      margin-bottom: 0px;
      .mainText {
        width: 79.46666vw;
        height: 100%;
        font-family: Gilroy-Medium;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #243746;
      }
      .subText {
        width: 80.8vw;
        height: 100%;
        font-family: Gilroy-Regular;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #4d4d4d;
        height: 63px;
        margin-bottom: 71px;
      }
    }
  }
}
