.container {
  width: 21.875vw;
  height: 28.125vw;
  box-shadow: 0px 4px 12px rgba(27, 40, 48, 0.15);
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 0.5208333333333334vw;

  .imageContainer {
    .image {
      width: 100%;
      height: 100%;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  .textContainer {
    margin-top: 2.0833333333333335vw;
    display: flex;
    flex-direction: column;
    row-gap: 1.5625vw;
    margin-left: 2.2916666666666665vw;
    .mainText {
      width: 17.708333333333332vw;
      height: 6.458333333333333vw;
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: 900;
      font-size: 1.5625vw;
      line-height: 104.5%;
      color: #243746;
    }

    .MbtnContainer {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .container {
    width: 300px;
    height: 397px;
    // box-shadow: 0px 4px 12px rgba(27, 40, 48, 0.15);
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 10px;

    .imageContainer {
      .image {
        width: 100%;
        height: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
    }

    .textContainer {
      margin-top: 3.7593984962406015vh;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-left: 25px;
      .mainText {
        width: 242.41px;
        height: 88.41px;
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 20px;
        color: #243746;
      }

      .btnContainer {
        display: none;
      }
      .MbtnContainer {
        display: flex;
      }
    }
  }
}
