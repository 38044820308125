.container {
  background: linear-gradient(180deg, #050c13 20.46%, rgba(36, 52, 70, 0) 128.81%);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -2;

  // .gradient {
  //   position: absolute;
  //   top: 0;
  //   height: 100%;
  //   width: 100%;
  //   z-index: -1;
  // }
  .subcontainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 160px;
    padding-top: 160px;
    @media (max-width: 980px) {
      padding-bottom: 80px;
      padding-top: 80px;
    }

    // width: 80%;
    max-width: 940px;

    @media (max-width: 980px) {
      width: 90%;
    }
    .benefitContainer {
      // margin-top: 101px;
      // margin-bottom: 201px;
      display: flex;
      padding: 40px;
      background: #243746;
      border-radius: 20px;
      flex-direction: column;
      row-gap: 30px;
      // box-sizing: border-box;
      // padding: 58px 61px;
      // @media (max-width: 880px) {
      //   padding: 35px 31px;
      //   margin-top: 0px;
      // }
      // @media (max-width: 580px) {
      //   margin-top: 0px;
      //   margin-bottom: 0px;
      // }
      // width: 1440px;
      // height: 522px;

      h2 {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 700;
        color: #ffffff;
        // font-size: 44px;
        font-size: 3em;
        line-height: 62px;
        font-weight: 600;
        // @media (max-width: 1495px) {
        //   font-size: 70px;
        // }
        // @media (max-width: 1280px) {
        //   font-size: 60px;
        //   line-height: 100%;
        // }
        @media (max-width: 540px) {
          font-size: 30px;
          line-height: 100%;
        }
      }
      .descriptionTopContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .imageContainer {
          max-width: 419px;
          // width: 15.822916666666668vw;
          height: auto;
          img {
            margin-top: -111px;
            width: 100%;
            height: 100%;
          }
          // @media (max-width: 1660px) {
          //   width: 319px;
          // }
          // @media (max-width: 1495px) {
          //   width: 319px;
          // }
          // @media (max-width: 1340px) {
          //   width: 319px;
          // }

          @media (max-width: 1080px) {
            display: none;
          }
        }

        .descriptionContainer {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          width: 70%;
          column-gap: 40px;

          @media (max-width: 1280px) {
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;
          }
          @media (max-width: 880px) {
            grid-template-columns: 1fr;
            row-gap: 30px;
          }

          .column {
            display: flex;
            flex-direction: column;
            // row-gap: 30px;
            // column-gap: 30px;

            padding-right: 30px;
            @media (max-width: 730px) {
              padding-right: 0px;
            }
            // max-width: 150px;
            // min-width: 180px;

            @media (max-width: 1280px) {
              // padding-right: 50px;
              max-width: 100%;
            }

            .title {
              font-family: 'Gilroy-Medium';
              font-style: normal;
              font-weight: bolder;

              // font-size: 40px;
              // line-height: 45px;

              // font-feature-settings: 'liga' off;
              color: #47d7ac;
              // font-size: 28px;
              font-size: 2em;
              font-weight: 600;
              // @media (max-width: 1495px) {
              //   font-size: 35px;
              // }

              // @media (max-width: 1495px) {
              //   font-size: 30px;
              //   line-height: 35px;
              // }
              @media (max-width: 540px) {
                font-size: 20px;
                line-height: 100%;
              }
            }
            .para {
              font-family: 'gilroy-regular';
              // font-style: normal;
              // font-weight: 200;
              // font-size: 20px;
              // line-height: 22px;

              color: #ffffff;
              margin: 20px auto 0px;
              // font-size: 14px;
              font-size: 1em;
              line-height: 18px;
              font-weight: 400;

              // @media (max-width: 1495px) {
              //   font-size: 16px;
              // }
              @media (max-width: 880px) {
                padding-bottom: 20px;
                margin: 20px 0px 0px;
                padding-right: 0px;
                &:not(:last-child) {
                  padding-bottom: 0px;
                }
              }
              // @media (max-width: 540px) {
              //   font-size: 14px;
              //   line-height: 18px;
              // }
            }
          }

          & .column:not(:first-child) {
            // padding-left: 20px;
            // @media (max-width: 880px) {
            //   padding-left: 0px;
            // }
          }
          & .column:not(:nth-last-child(1)) {
            border-right: 1px solid #ffffff;
            @media (max-width: 880px) {
              border-bottom: 1px solid #ffffff;
              border-right: 0px solid #ffffff;
            }
          }
        }
      }
    }
  }
}
