.container {
  position: relative;
  width: 100%;
  // overflow: hidden;
  @media (max-width: 1075px) {
    height: 600px;
  }
  @media (max-width: 775px) {
    height: 400px;
  }
  @media (max-width: 375px) {
    height: 400px;
  }
  .bgImage {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;

    @media (max-width: 375px) {
    }

    img {
      width: 100%;
      height: 100%;
      // height: 43vw;
      @media (max-width: 1975px) {
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 375px) {
        width: 100%;
        height: 400px;
      }
    }
  }
  .bgGradient {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      244.07deg,
      #243746 23.37%,
      rgba(36, 55, 70, 0) 58.14%,
      #243746 78.82%
    );
    mix-blend-mode: multiply;
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 20;

    @media (max-width: 375px) {
      background: linear-gradient(
        244.07deg,
        #243746 23.37%,
        rgba(36, 55, 70, 0) 58.14%,
        #243746 78.82%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  .textContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: translate(-50%, 50%);

    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 700;
    // font-size: 80px;
    // line-height: 100%;
    font-size: 4em;
    line-height: 68px;
    text-align: center;
    color: #ffffff;

    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    // @media (max-width: 1375px) {
    //   font-size: 75px;
    // }
    // @media (max-width: 1275px) {
    //   font-size: 70px;
    // }
    // @media (max-width: 1175px) {
    //   font-size: 65px;
    // }
    // @media (max-width: 1075px) {
    //   font-size: 60px;
    // }
    @media (max-width: 975px) {
      font-size: 55px;
    }
    @media (max-width: 875px) {
      font-size: 50px;
    }
    @media (max-width: 775px) {
      font-size: 45px;
    }
    @media (max-width: 675px) {
      font-size: 40px;
    }
    @media (max-width: 575px) {
      font-size: 35px;
      line-height: 44px;
    }

    @media (max-width: 375px) {
      // font-size: px;
      text-align: center;
    }
  }
}
