.container {
  margin-bottom: 4.010416666666667vw;

  .hoverPlayerClass {
    display: flex;
    height: 100%;
    width: 100%;
  }
}
.hover-video-player-container {
  display: flex;
  height: 100%;
  width: 100%;
}

@media (max-width: 900px) {
  .container {
    margin-bottom: 77px;

    .hoverPlayerClass {
      display: flex;
      height: 100%;
      video {
        background-color: #243746;

        height: 100% !important;
      }
    }
  }
  .hover-video-player-container {
    display: flex;
    height: 375px;
    width: 100%;
  }
}
