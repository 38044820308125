.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 6.25vw 0px;
  background: linear-gradient(180deg, #f6f7fb 0%, rgba(246, 247, 251, 0) 114.24%);

  .topSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .heading {
      width: 32.96875vw;

      font-family: Gilroy-Bold;
      font-weight: 100;

      font-size: 2.34375vw;
      line-height: 2.8125vw;
      text-align: center;

      padding-bottom: 2.9166666666666665vw;

      color: #243746;
    }
    .mainContent {
      display: flex;
      justify-content: center;
      align-items: center;
      // column-gap:5px;
      width: 100%;
      // margin-left: 17.03125vw;
      // margin-right: 27.760416666666668vw;
      column-gap: 5.260416666666667vw;
      .first {
        width: 30.633333333333333vw;
        height: 30.700000000000003vw;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .sideText1 {
      width: 22.239583333333332vw;
      height: 100%;

      font-family: Gilroy-Bold;
      font-weight: 100;

      font-size: 1.4583333333333333vw;
      line-height: 1.7708333333333333vw;
      text-align: right;

      color: #243746;
    }
  }
  .secondSec {
    height: 100%;
    //  top:0;
    // width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5.208333333333333vw;

    column-gap: 4.791666666666667vw;

    .multiImage {
      width: 25.057291666666668vw;
      height: 17.447916666666668vw;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .sideText2 {
      width: 20.3125vw;
      height: 100%;

      font-family: Gilroy-Bold;

      font-weight: 100;

      font-size: 1.4583333333333333vw;
      line-height: 1.7708333333333333vw;

      color: #243746;
    }
  }

  .thirdSec {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4.635416666666667vw;
    margin-top: 6.197916666666667vw;
    .sideText3 {
      width: 20.885416666666668vw;
      height: 5.3125vw;

      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: 100;
      font-size: 1.4583333333333333vw;
      line-height: 1.7708333333333333vw;
      text-align: right;

      color: #243746;
    }

    .thirdImage {
      width: 31.5625vw;
      height: 25.15625vw;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    padding: 66px 0px;
    background: linear-gradient(180deg, #f6f7fb 0%, rgba(246, 247, 251, 0) 114.24%);

    .topSec {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .heading {
        width: 259px;
        height: 100%;

        font-family: Gilroy-Bold;
        font-weight: 100;
        text-align: center;
        font-size: 30px;
        line-height: 36px;
        padding-bottom: 96px;

        color: #243746;
      }
      .mainContent {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        // column-gap:5px;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        column-gap: 5.260416666666667vw;
        overflow: hidden;

        .first {
          width: 586.09px;
          height: 588.43px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .sideText1 {
          width: 295px;
          height: 100%;

          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 28px;
          line-height: 34px;
          text-align: center;

          color: #243746;
        }
      }
    }
    .secondSec {
      height: 100%;
      //  top:0;
      // width:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      column-gap: 0px;

      .multiImage {
        width: 281.1px;
        height: 195.74px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .sideText2 {
        width: 338px;
        height: 170px;
        margin-bottom: 36px;

        font-family: Gilroy-Bold;

        font-weight: 100;
        text-align: center;

        font-size: 28px;
        line-height: 34px;

        color: #243746;
      }
    }

    .thirdSec {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      column-gap: 0px;
      margin-top: 100px;
      .sideText3 {
        width: 290px;
        height: 144px;
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: 100;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        margin-bottom: 56px;

        color: #243746;
      }

      .thirdImage {
        width: 258px;
        height: 205px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
