.topcontainer {
  display: flex;
  justify-content: center;
  // margin-bottom: 40px;
  padding-bottom: 160px;
  padding-top: 160px;

  @media (max-width: 980px) {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .container {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    background: #47d7ac;
    padding: 40px 40px 40px 40px;
    // width: 80%;
    background: #47d7ac;
    border-radius: 20px;
    max-width: 940px;
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 768px) {
      height: 100%;
      padding-top: 50px;
      padding-bottom: 75px;
    }
    @media (max-width: 980px) {
      width: 90%;
    }

    @media (max-width: 375px) {
      height: 100%;
      padding-top: 50px;
      padding-bottom: 75px;
    }
    .subContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // width: 85%;

      @media (max-width: 1498px) {
        // width: 80%;
      }
      // @media (max-width: 1366px) {
      //   width: 70%;
      // }
      @media (max-width: 1024px) {
        // width: 80%;
      }
      @media (max-width: 768px) {
        // width: 68%;
      }

      @media (max-width: 430px) {
        width: 90%;
      }

      @media (max-width: 375px) {
        width: 90%;
      }
      .title {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        // font-size: 60px;
        // line-height: 110%;
        font-size: 32px;
        line-height: 34px;
        text-align: center;

        color: #243746;
        margin-bottom: 60px;

        // @media (max-width: 1366px) {
        //   font-size: 45px;
        //   margin-bottom: 90px;
        // }

        // @media (max-width: 1024px) {
        //   font-size: 40px;
        // }
        @media (max-width: 768px) {
          // font-size: 36px;
          line-height: 110%;
          /* or 33px */

          text-align: center;

          color: #243746;
          // margin-bottom: 50px;
        }

        @media (max-width: 570px) {
          font-size: 32px;
        }

        @media (max-width: 375px) {
          font-size: 30px;
          line-height: 110%;
          /* or 33px */

          text-align: center;

          color: #243746;
          margin-bottom: 50px;
        }
      }
      .brandsContainer {
        display: grid;
        // flex-wrap: wrap;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: center;
        justify-items: center;
        row-gap: 30px;
        // column-gap: 70px;

        // column-gap: 150px;

        img {
          // opacity: 0.7;
          width: 225px;
          @media (min-width: 1025px) {
            // max-width: 200px;
          }
          @media (max-width: 1024px) {
            // max-width: 170px;
          }
          @media (max-width: 768px) {
            // max-width: 150px;
          }

          @media (max-width: 570px) {
            max-width: 170px;
          }
          @media (max-width: 505px) {
            max-width: 150px;

            // width: 100px;
          }
        }
        // @media (max-width: 1390px) {
        //   // width:90%;
        //   row-gap: 40px;
        //   column-gap: 2.1125vw;
        // }
        // @media (max-width: 1366px) {
        //   // width:90%;
        //   row-gap: 40px;
        //   column-gap: 3.08125vw;

        //   // column-gap: 95px;
        // }

        // @media (max-width: 1024px) {
        //   // width:90%;
        //   row-gap: 30px;
        //   column-gap: 16.08125vw;

        //   // column-gap: 50px;
        // }

        @media (max-width: 968px) {
          // width:90%;
          row-gap: 30px;
          column-gap: 10.08125vw;
          grid-template-columns: repeat(2, 160px);

          // column-gap: 28px;
        }

        @media (max-width: 515px) {
          // width:90%;
          row-gap: 30px;
          column-gap: 0px;
        }
      }
    }
  }
}
