.imageContainer {
  width: 100%;
  height: 100%;
  .joinUsSlider {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 900px) {
  .imageContainer {
    width: 100%;
    height: 100%;
    .joinUsSlider {
      width: 100%;
      height: 100%;
    }
  }
}
