.container {
  height: 100%;
  width: 100%;
  .cardContainer {
    position: relative;

    .imageContainer {
      margin-right: 1.0416666666666667vw;
      .image {
        height: 100%;
        width: 100%;
      }
    }
    .textContainer {
      position: absolute;
      top: 1.3526485507246377vw;
      left: 1.3157812500000001vw;
      .title {
        font-family: Gilroy-Medium;
        font-style: normal;
        font-weight: bold;
        font-size: 1.5vw;
        display: flex;
        align-items: center;
        color: #47d7ac;
        margin-bottom: 1.1723168276972624vw;
      }
      .divider {
        border: 2px solid #47d7ac;
        width: 1.7543749999999998vw;
        margin-bottom: 1.1723168276972624vw;
      }
      .description {
        font-family: Gilroy-Regular;
        font-style: normal;
        font-weight: 600;
        font-size: 1.5vw;
        line-height: 1.3526485507246377vw;
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 0.4508828502415458vw;
      }
      .time {
        font-family: Gilroy-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 1.5vw;
        line-height: 1.499194847020934vw;
        display: flex;
        align-items: center;
        color: #47d7ac;
        margin-bottom: 0.4508828502415458vw;
      }
      .address {
        display: flex;
        align-items: center;
        column-gap: 0.43859374999999995vw;
        .addressText {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 1.5vw;
          line-height: 1.9527536231884057vh;
          display: flex;
          align-items: center;
          color: #ffffff;
        }
      }
    }
    .chipContainer {
      position: absolute;
      top: 2.254414251207729vw;
      right: 3.5087499999999996vw;
      background-color: #ffffff;
      border-radius: 1rem;
      color: #2b4f82;
    }
    .btnContainer {
      z-index: 10000000;
      position: absolute;
      right: 40px;
      bottom: 20px;
    }

    .MbtnContainer {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    justify-content: center;
    .cardContainer {
      width: 312px;
      height: 317px;
      position: relative;
      max-width: 500px;

      .imageContainer {
        margin-right: 0px;
        width: 312px;
        height: 317px;

        .image {
          height: 100%;
          width: 100%;
        }
      }

      .textContainer {
        top: 90px;
        left: 34px;
        .title {
          font-family: Gilroy-Medium;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          color: #47d7ac;
          margin-bottom: 16px;
        }
        .divider {
          background-color: 0.21052631578947367rem solid #47d7ac;
          width: 25.69px;
          margin-bottom: 16px;
        }
        .description {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #ffffff;
          margin-bottom: 7px;
        }
        .time {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #47d7ac;
          margin-bottom: 8px;
        }
        .address {
          display: flex;
          align-items: center;
          column-gap: 7.5px;
          .addressText {
            font-family: Gilroy-Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #ffffff;
          }
        }
      }
      .chipContainer {
        position: absolute;
        top: 23px;
        right: 22px;
        background-color: #ffffff;
        border-radius: 1rem;
        color: #2b4f82;
        filter: drop-shadow(0px 0px 15.3731px rgba(0, 0, 0, 0.25));
      }

      .chipContainer {
        width: 103px;
        height: 23.06px;
      }
      .btnContainer {
        display: none;
      }
      .MbtnContainer {
        display: unset;
        z-index: 10000000;
        position: absolute;
        right: 20px;
        bottom: 20px;
      }
    }
  }
}
