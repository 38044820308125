.mainContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4.283413848631239vw;
  padding-top: 4.166666666666667vw;
  padding-bottom: 5.104166666666667vw;

  .titleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title {
      font-family: Gilroy-Bold;
      font-size: 2.34375vw;
      text-align: center;
      width: 28.802083333333332vw;
      height: 100%;
      color: #243746;
    }
  }

  .statsContainer {
    display: flex;
    column-gap: 9.895833333333334vw;
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 58px;
    padding-top: 40px;
    padding-bottom: 50px;

    .titleContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .title {
        width: 207px;
        height: 100%;
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #243746;
      }
    }

    .statsContainer {
      display: flex;
      column-gap: 9.895833333333334vw;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
