.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 80px;

  @media (max-width: 785px) {
    margin-bottom: 70px;
    margin-top: 70px;
  }
  @media (max-width: 375px) {
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .subContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 60px;
    max-width: 940px;

    @media (max-width: 1120px) {
      column-gap: 30px;
    }

    @media (max-width: 980px) {
      grid-template-columns: 1fr;
      width: 90%;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      column-gap: 40px;
      row-gap: 60px;
      // justify-content: space-between;
      max-width: 374px;

      @media (max-width: 980px) {
        margin-bottom: 0px;
        max-width: 100%;
        row-gap: 0px;
      }

      @media (max-width: 375px) {
      }
      .title {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 2.5em;
        line-height: 44px;
        color: #243746;
        width: 306px;
        @media (max-width: 980px) {
          font-size: 50px;
          margin-bottom: 20px;
          width: 100%;
        }
        @media (max-width: 768px) {
          font-family: 'Gilroy-Medium';
          padding-top: 30px;
          font-size: 30px;
          line-height: 110%;
          max-width: 234px;
        }
      }
      .description {
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 500;

        font-size: 1.2em;
        line-height: 22px;
        color: #243746;

        @media (max-width: 980px) {
          font-size: 20px;
          margin-bottom: 50px;
          width: 100%;
        }
        @media (max-width: 768px) {
          padding: 20px 0px;
          font-size: 16px;
          line-height: 120%;
        }
      }
      .btn {
        width: 159px;
        height: 46px;
        background: #47d7ac;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        text-align: center;

        color: #243746;

        @media (max-width: 980px) {
          display: none;
        }

        @media (max-width: 375px) {
        }
      }
    }
    .rightTopContainer {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      align-items: center;
      .rightContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 30px;
        column-gap: 30px;
        width: 100%;

        @media (max-width: 725px) {
          grid-template-columns: 1fr;
          row-gap: 20px;
          column-gap: 0px;
          padding-top: 20px;
        }

        .statSection {
          box-sizing: border-box;
          padding: 20px 28px;
          // width: 100%;
          min-width: 250px;
          max-width: 452px;
          height: 150px;
          background: #e4e6ed;
          border-radius: 20px;

          @media (max-width: 1230px) {
            height: 160px;
          }
          @media (max-width: 980px) {
            max-width: 100%;
            width: 100%;
            height: 100%;
          }

          @media (max-width: 768px) {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: space-between;
            align-items: center;
          }

          @media (max-width: 375px) {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: space-between;
            align-items: center;
          }
          .number {
            font-family: 'Gilroy-Bold';
            font-style: normal;
            font-weight: 100;
            font-size: 82px;
            line-height: 84px;
            color: #3b6cb3;

            @media (max-width: 768px) {
              font-size: 40px;
              line-height: 110%;
            }

            @media (max-width: 375px) {
              font-size: 40px;
              line-height: 110%;
            }
          }
          .detail {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 1em;
            line-height: 18px;
            color: #243746;

            @media (max-width: 988px) {
              max-width: 200px;
              text-align: end;
            }
            @media (max-width: 725px) {
              max-width: 100%;
            }

            @media (max-width: 768px) {
              font-family: 'Gilroy-Medium';

              font-weight: lighter;
              font-size: 18.2732px;
              line-height: 22px;
            }

            @media (max-width: 475px) {
              font-family: 'Gilroy-Medium';

              font-weight: lighter;
              font-size: 18.2732px;
              line-height: 22px;
              max-width: 60%;
            }
          }
        }
      }
    }
  }
  .Mbtn {
    display: none;

    @media (max-width: 980px) {
      margin-top: 10px;
      font-family: 'Gilroy-Bold';
      width: 330px;
      height: 60.5px;
      background: #47d7ac;
      border-radius: 40px;
      font-size: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #243746;
    }
    @media (max-width: 730px) {
      height: 40.5px;
    }
  }
}

.numberSection {
  @media (max-width: 980px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
