.mainContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  .containerWrapper {
    padding-top: 9.739583333333334vw;

    .Header {
      width: 14.895833333333334vw;
      height: 2.7083333333333335vw;

      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 1.5625vw;
      line-height: 3.0208333333333335vw;
      margin-bottom: 0.8333333333333334vw;
      text-transform: uppercase;
      color: #3b6cb3;
    }
  }
}

.courselContainer {
  display: none;
}
@media (max-width: 900px) {
  .mainContainer {
    display: none;
  }

  .courselContainer {
    display: flex;
    justify-content: center;

    padding: 30px 0px;
    .containerWrapper {
      width: 80%;
      .Header {
        width: 182px;
        height: 30px;
        font-family: Gilroy-Medium;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 58px;

        display: flex;
        align-items: center;
        text-transform: uppercase;

        color: #3b6cb3;
      }
    }
  }
}
