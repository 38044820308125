.container {
  display: flex;
  width: 100%;
  // height: 56.203007518796994vh;
  background: #e4e6ed;
  .leftContainer {
    width: 45vw;
    height: 100%;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  .rightContainer {
    display: flex;
    // height: 100%;
    width: 55vw;
    flex-direction: column;
    // row-gap: 4.6992481203007515vh;
    justify-content: center;
    align-items: center;

    .textContainer {
      color: #243746;
      // width: 100%;
      // height:100%;
      display: flex;
      flex-direction: column;
      row-gap: 3.6458333333333335vw;
      // justify-content: center;
      // align-items: center;
      .mainText {
        // width: 17.704166666666666vw;
        // height: 3.8721804511278197vh;

        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: lighter;
        font-size: 1.5625vw;
        line-height: 1.25vw;
        display: flex;
        align-items: center;
        color: #243746;
      }
      .subText {
        width: 35.625vw;
        // height: 23.68421052631579vh;
        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: lighter;
        font-size: 1.5625vw;
        line-height: 1.8229166666666667vw;
        color: #243746;
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    width: 100%;
    height: 100%;
    background: #e4e6ed;
    flex-direction: column;
    .leftContainer {
      width: 100%;
      height: 100%;
      .image {
        width: 100%;
        height: 100%;
      }
    }
    .rightContainer {
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 0;

      .textContainer {
        color: #243746;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        row-gap: 0px;
        .mainText {
          width: 80vw;
          height: 100%;
          font-family: Gilroy-Bold;
          font-size: 20px;
          line-height: 23px;
          display: flex;
          align-items: center;
          margin-top: 6.6666vw;
          color: #243746;
        }
        .subText {
          width: 80vw;
          height: 100%;

          margin-top: 6.6666vw;

          font-family: Gilroy-Light;
          font-size: 16px;
          line-height: 19px;

          color: #243746;
          margin-bottom: 6.6666vw;
        }
      }
    }
  }
}
