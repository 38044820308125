.mainContainer {
  display: flex;
  background: #f6f7fb;
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  justify-content: center;

  .headerContainer {
    height: 2.6041666666666665vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.0416666666666667vw;
    width: 90vw;
    max-width: 940px;

    .logoContainer {
      img {
        width: 100%;
        height: 100%;
      }
    }
    .navigationContainer {
      display: flex;
      column-gap: 1.8333333333333334vw;
      align-items: center;

      .DropdownContainer {
        display: flex;
        align-items: center;
      }
      .navbarHighlightItem {
        // padding: 10px 20px;
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 120% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #243746;
        background: #47d7ac;
        border-radius: 40px;

        width: 159px;
        height: 46px;
        justify-content: center;

        @media (max-width: 1200px) {
          // font-size: 18px;
        }
        @media (max-width: 1150px) {
          font-size: 16px;
          // padding: 10px 15px;
        }
        @media (max-width: 1100px) {
          font-size: 16px;
          // padding: 10px 10px;
        }
      }
    }
    .navigationItem {
      color: #243746;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      @media (max-width: 1200px) {
        font-size: 18px;
      }

      @media (max-width: 1100px) {
        font-size: 16px;
      }
      .ddHeader {
        position: absolute;

        .ddItemlist {
          .ddItem {
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    .headerContainer {
      height: 59px;
      .logoContainer {
        width: 140px;
        height: 27px;
      }
      .navigationContainer {
        display: none;
      }
    }
  }
}

.navSubItem {
  a {
    text-decoration: none;
  }
}
