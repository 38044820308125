.container {
  display: flex;
  justify-content: center;
  // padding-top: 79px;
  background: linear-gradient(180deg, #b9bec1 0%, rgba(228, 230, 237, 0.35) 40.45%);
  mix-blend-mode: multiply;
  padding-bottom: 160px;

  @media (max-width: 980px) {
    padding-bottom: 80px;
  }
  // @media (max-width: 786px) {
  //   padding-top: 40px;
  // }

  .subcontainer {
    // width: 80%;
    margin-bottom: 40px;
    max-width: 940px;

    @media (max-width: 786px) {
      padding-bottom: 40px;
      margin-bottom: 0px;
    }
    @media (max-width: 980px) {
      width: 90%;
    }
    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 786px) {
        flex-direction: column;
        row-gap: 30px;
      }
      .title {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 700;
        color: #243746;
        width: 170px;
        font-size: 3em;
        // line-height: 3.875em;
        // @media (max-width: 1280px) {
        //   font-size: 60px;
        // }
        @media (max-width: 1080px) {
          // font-size: 45px;
          width: 100%;
        }
        @media (max-width: 786px) {
          font-weight: 700;
          font-size: 30px;
          line-height: 100%;
        }
      }
      .details {
        margin-left: 220px;
        width: 510px;
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 500;
        color: #243746;
        // font-size: 20px;
        font-size: 1.2em;

        @media (max-width: 1280px) {
          // font-size: 30px;
          // width: 60vw;
        }
        @media (max-width: 1080px) {
          font-size: 20px;
          // width: 60vw;
        }
        @media (max-width: 786px) {
          font-weight: 500;
          font-size: 16px;
          width: 100%;
          line-height: 120%;
          margin-left: 0px;
        }
      }
    }
    .imageContainer {
      display: flex;
      align-items: center;
      margin-top: 60px;
      flex-direction: column;
      z-index: 1;

      @media (max-width: 786px) {
        margin-top: 59px;
      }

      @media (max-width: 1080px) {
        flex-direction: column;
        align-items: flex-end;
      }
      img {
        width: 100%;
        height: 100%;
        // background-color: #243746;
        @media (max-width: 1080px) {
          width: 100%;
          z-index: 1;
        }
      }

      .textContainer {
        width: 100%;
        box-sizing: border-box;
        background: #243746;
        margin-top: -10px;
        // padding: 70px 80px 70px 80px;
        padding: 40px;
        border-radius: 0px 0px 20px 20px;
        display: flex;

        @media (max-width: 730px) {
          flex-direction: column;
        }

        // @media (max-width: 1680px) {
        //   padding: 70px 55px 70px 95px;
        //   font-size: 55px;
        // }

        // @media (max-width: 1380px) {
        //   padding: 50px 35px 50px 75px;
        //   height: 100%;
        // }
        // @media (max-width: 1180px) {
        //   padding: 40px 25px 40px 65px;
        //   height: 100%;
        // }
        @media (max-width: 1080px) {
          // margin-top: 0%;
          border-radius: 0px 0px 20px 20px;
          // width: 65%;
        }
        @media (max-width: 980px) {
          // margin-top: 0%;
          border-radius: 0px 0px 20px 20px;
          // width: 75%;
          padding: 20px 15px 30px 25px;
        }
        h2 {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          // font-size: 60px;
          // line-height: 110%;
          color: #ffffff;
          margin-bottom: 30px;
          // width: 195px;
          // font-size: 32px;
          font-size: 2.5em;

          @media (max-width: 980px) {
            font-size: 30px;
            margin-bottom: 20px;
            width: 395px;
            line-height: 32px;
          }
          @media (max-width: 730px) {
            width: 100%;
          }
          @media (max-width: 480px) {
            font-size: 24px;
            margin-bottom: 20px;
          }
        }
        p {
          font-family: 'Gilroy-Regular';
          font-style: normal;
          font-weight: 500;
          // font-size: 30px;
          // line-height: 110%;
          color: hsl(0, 0%, 100%);
          // width: 684px;
          padding-right: 40px;
          padding-left: 140px;
          // font-size: 14px;
          font-size: 1.2em;
          line-height: 1.25;

          // @media (max-width: 1380px) {
          //   font-size: 20px;
          // }
          @media (max-width: 730px) {
            padding: 0px;
            width: 100%;
            font-size: 1em;
          }
        }
      }
    }
  }
}
