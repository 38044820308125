.container {
  width: 36.458333333333336vw;
  height: 17.708333333333332vw;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin: 0.2604166666666667vw 0.2604166666666667vw;
  // margin: 20px 0px;
  .imageContainer {
    width: 14.583333333333334vw;
    height: 17.708333333333332vw;

    // object-fit: cover;
    .image {
      width: 100%;
      height: 100%;
      object-position: top;
    }
  }
  .textContainer {
    margin: 2.0833333333333335vw;
    width: 17.395833333333332vw;

    .text {
      height: 12.135416666666666vw;
      font-family: Gilroy-Regular;
      font-style: normal;
      font-weight: 800;
      font-size: 0.8333333333333334vw;
      line-height: 1.0416666666666667vw;
      color: #253746;
      // margin-bottom: 1.0965104166666668vw;
    }

    .divider {
      width: 50px;
      border: 3px solid #47d7ac;
      margin-bottom: 0.78125vw;
    }

    .bottomDiv {
      display: flex;
      align-items: center;
      column-gap: 2.0833333333333335vw;
      .socialContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        // flex-direction: column;

        .iconImage {
          width: 15.11px;
        }
        // flex-direction: column;
      }

      .highlightedText {
        font-family: Gilroy-Regular;
        width: 106px;
        height: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 0.8333333333333334vw;
        line-height: 0.9895833333333334vw;
        color: #4fdcb5;
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-shadow: none;
    margin: 0px;
    .imageContainer {
      height: 170px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .textContainer {
      margin: 20px;
      width: 254px;

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        width: 254px;
        height: 300px;
        line-height: 20px;
      }
      .divider {
        width: 50px;
        border: 2px solid #47d7ac;
        margin-bottom: 15px;
      }
      .bottomDiv {
        flex-direction: row;
        align-items: center;
        row-gap: 20px;
        .highlightedText {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          height: 100%;
          width: 100%;
          color: #4fdcb5;
        }
        .socialContainer {
          display: flex;
          .iconImage {
            height: 17.13px;
            width: 17.13px;
          }
        }
      }
    }
  }
}
