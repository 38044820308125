.slick-dots {
  bottom: 60px !important;
  right: 10% !important;
  transform: rotate(90deg);
  font-size: 30px !important;
}

.slick-dots li button:before {
  font-size: 15px !important;
  color: white !important;
  opacity: 1 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #4fdcb5 !important;
}
/* 
.slick-active:nth-child(3n) {
  filter: brightness(300%);
  direction: rtl;
} */
