.ArrowContainerRight {
  height: 2.19296875vw !important;
  width: 2.19296875vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  background: #243746;
  top: 45%;
  right: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.19296875vw !important;
  width: 2.19296875vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 45%;
  left: -2%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  cursor: pointer;
}
.ArrowClass {
  color: #fff;
  font-size: 1.5625vw;
}

.container {
  position: relative;
  overflow: hidden;
  margin-bottom: 3.3391575425790756vw;
  margin-top: 3.3391575425790756vw;

  .sliderContainer {
    width: 60%;
  }
  .overlay1 {
    position: absolute;
    top: -3rem;
    right: -3%;
    z-index: 5;
    width: 45%;
    height: 30.795496779388085vw;
    background: linear-gradient(180deg, #ffff 15.42%, rgba(246, 247, 251, 0) 100%);
    // background-color: red;
    transform: matrix(0, -1, -1, 0, 0, 0);
    pointer-events: none;
  }
  .overlay2 {
    position: absolute;
    top: -2rem;
    left: -2%;
    z-index: 5;
    width: 45%;
    height: 30.795496779388085vw;
    background: linear-gradient(180deg, #ffff 15.42%, rgba(246, 247, 251, 0) 100%);
    transform: rotate(-90deg);
    pointer-events: none;
  }
  .titleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 2.434799516908212vw;

    .title {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 1.5625vw;
      line-height: 1.9275362318840579vw;
      display: flex;
      align-items: center;
      text-align: center;
      color: #243746;
    }
  }
  .btnContainer {
    margin: 3.766727053140096vw 0px;
    display: flex;
    justify-content: center;
    // border-radius: 80px;

    .btnSubContainer {
      display: flex;
      column-gap: 2.6041666666666665vw;
      // border-radius: 800px;
    }
    .MbtnSubContainer {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .ArrowContainerRight {
    height: 25px !important;
    width: 25px !important;
    right: -1%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .ArrowContainerLeft {
    height: 25px !important;
    width: 25px !important;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    cursor: pointer;

    left: -1%;
  }
  .ArrowClass {
    color: #000;
    font-size: 1.5625vw;
  }

  .container {
    .sliderContainer {
      width: 90%;
    }
    .overlay1 {
      display: none;
    }
    .overlay2 {
      display: none;
    }
    .titleContainer {
      margin-top: 72px;
      margin-bottom: 50px;
      .title {
        font-size: 24px;
        height: 29px;
      }
    }
    .btnContainer {
      .btnSubContainer {
        display: none;
      }
      .MbtnSubContainer {
        margin-top: 61px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 25px;
      }
    }
  }
}

@media (max-width: 750px) {
  .container {
    .sliderContainer {
      width: 312px;
      height: 317px;
    }

    .ArrowContainerRight {
      height: 25px !important;
      width: 25px !important;
      right: -5%;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    }

    .ArrowContainerLeft {
      height: 25px !important;
      width: 25px !important;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

      left: -5%;
    }
  }
}

@media (max-width: 550px) {
  .container {
    .sliderContainer {
      width: 312px;
      height: 317px;
    }
  }
}
