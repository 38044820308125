.videoContainer {
  position: relative;
  height: 100%;
  transition: all 0.5s ease;

  .video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    z-index: 1;
  }
  .hoverOverlayContainer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  .pausedOverlayContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
  }
  .blind {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    pointer-events: none !important;
    transition: opacity 0.3s ease-in-out;
  }
}
