.container {
  display: flex;
  justify-content: center;

  .subContainer {
    display: flex;

    display: flex;
    background: #1b2830;
    border-radius: 20px 20px 20px 20px;
    width: 940px;
    height: 464px;

    .leftContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      video {
        border-radius: 20px 0px 0px 20px;
        height: 100%;
        object-fit: cover;
      }
      img {
        width: 100%;
        border-radius: 20px 0px 0px 20px;
        height:100%;
      }
    }
  }

  .rightContainer {
    // background: #21303d;
    //     border-radius: 20px 20px 20px 20px;
    background: #21303d;
    width: 300px;
    padding: 30px 55px 38px 38px;
    border-radius: 0px 20px 20px 0px;

    box-sizing: border-box;

    .title {
      margin-top: 28px;
      width: 207px;
      // height: 110px;
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 110%;
      color: #64ddb9;
    }

    .description {
      margin-top: 26px;
      width: 207px;
      height: 170px;
      font-family: 'Gilroy-Light';
      font-style: normal;
      font-weight: 500;
      font-size: 19.2px;
      line-height: 110%;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 980px) {
  .container {
    display: flex;
    justify-content: center;
    // padding-top: 64px;
    // padding-bottom: 64px;
    .subContainer {
      display: flex;
      background: #1b2830;
      border-radius: 20px 20px 20px 20px;
      width: 90%;
      height: 100%;
      flex-direction: column;
      .rightContainer {
        background: #21303d;
        width: 100%;
        padding: 38px 20px 38px 38px;
        border-radius: 0px 0px 20px 20px;

        box-sizing: border-box;
        // .subtitle {
        //   font-family: 'Gilroy-Bold';
        //   font-style: normal;
        //   font-weight: 700;
        //   font-size: 24px;
        //   line-height: 110%;
        //   color: #47d7ac;
        // }
        .title {
          width: 100%;
          height: 100%;
          margin-top: 10px;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 110%;
        }
        .description {
          margin-top: 28px;
          width: 100%;
          height: 100%;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 19.2px;
          line-height: 110%;
          color: #ffffff;
          margin-bottom: 60px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: center;
          background: #64ddb9;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
      .leftContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        video {
          border-radius: 20px 20px 0px 0px;
        }

        img {
          width: 100%;
          border-radius: 20px 20px 0px 0px;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .container {
    display: flex;
    justify-content: center;
    // padding-top: 64px;
    // padding-bottom: 64px;
    .subContainer {
      display: flex;
      background: #1b2830;
      border-radius: 20px 20px 20px 20px;
      width: 90%;
      height: 100%;
      flex-direction: column;
      .rightContainer {
        background: #21303d;
        width: 100%;
        padding: 20px 25px 23px 25px;
        border-radius: 0px 0px 20px 20px;

        box-sizing: border-box;
        // .subtitle {
        //   font-family: 'Gilroy-Bold';
        //   font-style: normal;
        //   font-weight: 700;
        //   font-size: 16px;
        //   line-height: 110%;
        //   color: #47d7ac;
        // }
        .title {
          margin-top: 0px;
          width: 100%;
          height: 100%;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 110%;
        }
        .description {
          margin-top: 20px;
          width: 100%;
          height: 100%;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 16.2px;
          line-height: 110%;
          margin-bottom: 28px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: center;
          background: #64ddb9;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
      .leftContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}
