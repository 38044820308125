.container {
  background: #e4e6ed;
  display: flex;
  justify-content: center;
  // min-height: 100vh;

  .subcontainer {
    max-width: 940px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .textContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 80px;
      padding-bottom: 80px;
      .heading {
        width: 940px;
        height: 100%;

        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 110%;
        text-align: center;
        color: #141d24;
      }
    }
    .featureContainer {
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 20px;
      .cardContainer {
        box-sizing: border-box;
        width: 460px;
        height: 229px;
        background: #ffffff;

        border: 1px solid #bdc2c7;
        border-radius: 20px;
        padding: 36px;

        .card {
          .highlightContainer {
            box-sizing: border-box;
            padding: 5px;
            background: #64ddb9;
            border-radius: 20px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            column-gap: 10px;

            .iconContainer {
              width: 30px;
              height: 30px;
              img {
                height: 100%;
                width: 100%;
              }
            }
            .text {
              font-family: 'Gilroy-Medium';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 110%;
              color: #141d24;
            }
          }
          .listContainer {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;

            color: #141d24;
            margin-top: 25px;

            ul {
              list-style: none; /* Remove default bullets */
              display: flex;
              flex-direction: column;
              row-gap: 10px;
              justify-content: center;
            }

            ul li::before {
              display: flex;
              align-items: center;
              content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
              color: black; /* Change the color */
              font-weight: bold; /* If you want it to be bold */
              font-size: 32px;
              // width: 1em; /* Also needed for space (tweak if needed) */
              margin-right: 12px;
            }
            li {
              display: flex;
              align-items: flex-start;
              font-family: 'Gilroy-Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 110%;

              color: #141d24;
            }
          }
        }
      }
    }

    .btnCenter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 80px;
      margin-bottom: 80px;

      // .btnCenter {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   margin-top: 50px;
      .btnContainer {
        display: flex;
        align-items: center;
        width: 185px;
        height: 40px;
        justify-content: center;
        background: #64ddb9;
        border-radius: 20px;
        column-gap: 10px;
        .btnText {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 110%;
          color: #141d24;
          margin-left: 20px;
          margin-top: 2px;
          // }
        }
      }
      // }
    }
  }
}

@media (max-width: 980px) {
  .container {
    background: #e4e6ed;
    display: flex;
    justify-content: center;
    // min-height: 100vh;

    .subcontainer {
      max-width: 940px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .textContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 45px;
        padding-bottom: 45px;
        .heading {
          width: 85%;
          height: 100%;

          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 110%;
          /* or 33px */

          text-align: center;

          /* Gray/gray-12 */

          color: #141d24;
        }
      }
      .featureContainer {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        column-gap: 20px;
        .cardContainer {
          box-sizing: border-box;
          width: 330px;
          height: 192px;
          background: #ffffff;

          border: 1px solid #bdc2c7;
          border-radius: 20px;
          padding: 14px;

          .card {
            .highlightContainer {
              box-sizing: border-box;
              padding: 5px;
              background: #64ddb9;
              border-radius: 20px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              column-gap: 10px;

              .iconContainer {
                width: 30px;
                height: 30px;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
              .text {
                font-family: 'Gilroy-Medium';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 110%;

                color: #141d24;
              }
            }
            .listContainer {
              margin-top: 15px;

              font-family: 'Gilroy-Medium';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 110%;

              color: #141d24;

              ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                justify-content: center;
              }

              ul li::before {
                display: flex;
                align-items: center;
                content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: black; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                font-size: 28px;
                margin-top: 0.5px;
              }
              li {
                display: flex;
                align-items: start;
              }
            }
          }
        }
      }

      .btnCenter {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        margin-bottom: 36px;

        // .btnCenter {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   margin-top: 50px;
        .btnContainer {
          display: flex;
          align-items: center;
          // column-gap: 18px;
          width: 330px;
          height: 40px;
          justify-content: space-around;
          background: #64ddb9;
          border-radius: 20px;

          img {
            display: none;
          }
          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
            margin-left: 0px;
            margin-top: 2px;
            // }
          }
        }
        // }
      }
    }
  }
}
