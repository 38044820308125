.Grid {
  display: flex;
  //   position: relative;
  justify-content: center;
}
.gradient1 {
  width: 33.67139756944444vw;
  height: 33.1172310875vw;
  /* margin-top: -0.7518796992481203vh; */
  left: 0;
  /* left: -12vw; */
  position: absolute;
  background: linear-gradient(to right, #ffffff 14.48%, rgba(255, 255, 255, 0) 100%);
  /* transform: rotate(
180deg); */
  z-index: 1000;
}

.gradient2 {
  width: 33.67139756944444vw;
  height: 33.1172310875vw;
  /* margin-top: -0.7518796992481203vh; */
  right: 0;
  /* left: -12vw; */
  position: absolute;
  background: linear-gradient(to left, #ffffff 14.48%, rgba(255, 255, 255, 0) 100%);
  /* transform: rotate(
180deg); */
  z-index: 1000;
}

.container {
  display: flex;
  flex-direction: column;
  width: 85%;
}
.images {
  display: flex;
  column-gap: 1.0416666666666667vw;

  .img1 {
    width: 26.145833333333332vw;
    height: 17.447916666666668vw;
  }

  .img2 {
    width: 20.3125vw;
    height: 17.447916666666668vw;
  }

  .img3 {
    width: 21.875vw;
    height: 17.447916666666668vw;
  }

  .img4 {
    width: 21.25vw;
    height: 14.21875vw;
  }

  .img5 {
    width: 22.65625vw;
    height: 14.21875vw;
  }

  .img6 {
    width: 26.145833333333332vw;
    height: 14.21875vw;
  }
}
.ArrowContainerRight {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  background: #243746;
  top: 50%;
  right: 9.375vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 9.375vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  z-index: 1000;
  cursor: pointer;
}

.topContainer {
  display: flex;
}
.subContainer {
  display: flex;
}
.subsubContainer {
  display: flex;
  column-gap: 1.0416666666666667vw;
}
.left {
  width: 18.43vw;
  height: 32.706916666666665vw;
}
.rightContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1.0362916666666666vw;
}

@media (max-width: 900px) {
  .Grid {
    display: flex;
    justify-content: center;

    .gradient1 {
      display: none;
    }

    .gradient2 {
      display: none;
    }
    .container {
      width: 100%;

      .topContainer {
        display: flex;

        .subContainer {
          display: flex;
          justify-content: center;

          .subsubContainer {
            display: flex;
            column-gap: 1.0416666666666667vw;
            overflow: hidden;

            .left {
              display: none;
            }
            .rightContainer {
              display: flex;
              flex-direction: column;
              row-gap: 10px;

              .images {
                display: flex;
                column-gap: 9px;

                .img1 {
                  // width: 26.145833333333332vw;
                  // height: 17.447916666666668vw;
                  width: 215px;
                  height: 151px;
                }

                .img2 {
                  width: 153px;
                  height: 151px;
                  object-fit: cover;
                }

                .img3 {
                  display: none;
                }

                .img4 {
                  // width: 21.25vw;
                  // height: 25.657894736842106vh;
                  width: 176px;
                  height: 123px;
                }

                .img5 {
                  width: 186px;
                  height: 123px;
                }

                .img6 {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .ArrowContainerRight {
    display: none;
  }

  .ArrowContainerLeft {
    display: none;
  }
}
