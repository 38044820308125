.container {
  display: flex;
  justify-content: center;
  padding-top: 64px;
  padding-bottom: 64px;
  .subContainer {
    display: flex;
    background: #1b2830;
    border-radius: 20px 20px 20px 20px;
    width: 940px;
    height: 520px;
    .leftContainer {
      background: #21303d;
      width: 300px;
      padding: 38px 20px 31px 38px;
      border-radius: 20px 0px 0px 20px;

      box-sizing: border-box;
      .subtitle {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        color: #47d7ac;
      }
      .title {
        margin-top: 28px;
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 110%;
        color: #ffffff;
      }
      .description {
        margin-top: 28px;
        width: 242px;
        height: 170px;
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 19.2px;
        line-height: 110%;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .btnContainer {
        display: flex;
        align-items: center;
        // column-gap: 5px;
        width: 165px;
        height: 40px;
        justify-content: space-evenly;
        background: #64ddb9;
        border-radius: 20px;

        .btnText {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 110%;
          color: #141d24;
          margin-left: 10px;
          margin-top: 2px;
        }
      }
    }
    .rightContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      video {
        border-radius: 0px 20px 20px 0;
      }
    }
  }
}

@media (max-width: 980px) {
  .container {
    display: flex;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
    .subContainer {
      display: flex;
      background: #1b2830;
      border-radius: 20px 20px 20px 20px;
      width: 90%;
      height: 100%;
      flex-direction: column-reverse;
      .leftContainer {
        background: #21303d;
        width: 100%;
        padding: 38px 20px 38px 38px;
        border-radius: 0px 0px 20px 20px;

        box-sizing: border-box;
        .subtitle {
          font-family: 'Gilroy-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 110%;
          color: #47d7ac;
        }
        .title {
          margin-top: 28px;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 110%;
          color: #ffffff;
        }
        .description {
          margin-top: 28px;
          width: 100%;
          height: 100%;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 19.2px;
          line-height: 110%;
          color: #ffffff;
          margin-bottom: 84px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: center;
          background: #64ddb9;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
      .rightContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        video {
          border-radius: 20px 20px 0px 0px;
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .container {
    display: flex;
    justify-content: center;
    padding-top: 64px;
    padding-bottom: 64px;
    .subContainer {
      display: flex;
      background: #1b2830;
      border-radius: 20px 20px 20px 20px;
      width: 90%;
      height: 100%;
      flex-direction: column-reverse;
      .leftContainer {
        background: #21303d;
        width: 100%;
        padding: 28px 20px 28px 28px;
        border-radius: 0px 0px 20px 20px;

        box-sizing: border-box;
        .subtitle {
          font-family: 'Gilroy-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 110%;
          color: #47d7ac;
        }
        .title {
          margin-top: 18px;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 110%;
          color: #ffffff;
        }
        .description {
          margin-top: 18px;
          width: 100%;
          height: 100%;
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 16.2px;
          line-height: 110%;
          color: #ffffff;
          margin-bottom: 84px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: center;
          background: #64ddb9;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
      .rightContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
