.container {
  height: 13.095726885644769vw;
  display: flex;
  justify-content: center;
  align-items: center;
  .itemContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    row-gap: 1.8204508856682768vw;
    .textContainer {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 1.5625vw;
      line-height: 1.2850241545893721vw;
      display: flex;
      align-items: center;
      text-align: center;
      color: #243746;
    }
    .Mbutton {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .container {

    margin-bottom: 0px;
    height: 215px;
    // width:1900px;
    display: flex;
    justify-content: center;
    align-items: center;
    .itemContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 3.285024154589372vh;
      .textContainer {
        width: 298px;
        height: 58px;
        font-size: 24px;
        line-height: 29px;
      }
      .Dbutton {
        display: none;
      }
      .Mbutton {
        display: block;
      }
    }
  }
}
