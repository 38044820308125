.container {
  width: 21.875vw;
  height: 32.5vw;
  box-shadow: 0px 4px 12px rgba(27, 40, 48, 0.15);
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  margin-bottom: 1.0416666666666667vw;

  .imageContainer {
    .image {
      width: 100%;
      height: 100%;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  .textContainer {
    margin-top: 2.0833333333333335vw;
    display: flex;
    flex-direction: column;
    row-gap: 1.5625vw;
    margin-left: 2.2916666666666665vw;
    .heading {
      width: 17.708333333333332vw;
      height: 7.8125vw;
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: 900;
      font-size: 1.5625vw;
      line-height: 104.5%;
      color: #243746;
    }

    .subheading {
      font-family: Gilroy-Light;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8333333333333334vw;
      line-height: 1.25vw;
      color: #5a6874;
      width: 17.708333333333332vw;
      margin-top: 0.9895833333333334vw;
    }

    .btnContainer {
      position: absolute;
      bottom: 1.875vw;

      .btnStyle {
        width: 12.984375vw;
        height: 100%;
        padding: 0.5208333333333334vw 0px;

        transition: all 0.5s ease;
        background: #ffff;
        border-radius: 8px;
        font-size: 1.0416666666666667vw;
        font-family: Gilroy-Medium;
        color: #243746;
        border: 1px solid #243746;
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    height: 450px;
    box-shadow: unset;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 10px;
    box-shadow: 0px 4px 12px rgba(27, 40, 48, 0.15);

    position: relative;

    .imageContainer {
      .image {
        width: 100%;
        height: 100%;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
      }
    }

    .textContainer {
      margin-top: 27px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      margin-left: 0px;
      .heading {
        width: 250px;
        height: 100px;
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 20px;
        color: #243746;
      }

      .subheading {
        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #5a6874;
        width: 250px;
        height: 50px;
      }

      .btnContainer {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: 10px;

        .btnStyle {
          width: 250px;
          height: 40px;

          padding: 10px 0px;

          transition: all 0.5s ease;
          background: #ffff;
          border-radius: 8px;
          font-size: 16px;
          line-height: 24px;
          font-family: Gilroy-Medium;
          color: #243746;
          border: 1px solid #243746;
        }
      }
    }
  }
}
