.container {
  display: flex;
  justify-content: center;
  // margin-bottom: 40px;
  position: relative;

  padding-bottom: 160px;
  padding-top: 160px;

  @media (max-width: 980px) {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  // @media (max-width: 860px) {
  //   margin-bottom: 40px;
  // }
  .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(36, 55, 70, 0.14) -0.87%,
      rgba(228, 230, 237, 0) 99.13%
    );
    mix-blend-mode: multiply;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .subcontainer {
    // width: 80%;
    // display: flex;
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 40px;
    max-width: 940px;
    @media (max-width: 980px) {
      grid-template-columns: 1fr;
      row-gap: 30px;
      width: 90%;
    }

    .column1 {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      .paraContainer {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        @media (max-width: 1096px) {
          margin-top: 20px;
          row-gap: 10px;
        }
        p {
          font-family: 'Gilroy-Regular';
          font-style: normal;
          font-weight: 500;
          // font-size: 30px;
          font-size: 1.2em;
          line-height: 22px;
          color: #000000;

          // @media (max-width: 1760px) {
          //   font-size: 30px;
          // }
          // @media (max-width: 1560px) {
          //   font-size: 26px;
          // }
          // @media (max-width: 1360px) {
          //   font-size: 24px;
          // }
          // @media (max-width: 1200px) {
          //   font-size: 22px;
          // }
          // @media (max-width: 1096px) {
          //   font-size: 20px;
          // }
          @media (max-width: 960px) {
            font-size: 16px;
          }
        }
      }
      h3 {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 700;
        // font-size: 60px;
        // line-height: 110%;
        font-size: 2.5em;
        line-height: 44px;
        color: #000000;

        // @media (max-width: 1560px) {
        //   font-size: 50px;
        // }
        // @media (max-width: 1360px) {
        //   font-size: 40px;
        // }
        // @media (max-width: 1060px) {
        //   font-size: 30px;
        // }
        @media (max-width: 860px) {
          font-size: 30px;
          line-height: 32px;
          width: 163px;
          margin-bottom: 0px;
        }
      }
    }
    .imagesContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      @media (max-width: 1060px) {
        column-gap: 10px;
      }
      .column2 {
        // width: 30%;

        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .column3 {
        // width: 30%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
