.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3.9438868613138687vw;
  margin-bottom: 3.8933242092457423vw;
  margin-top: 3.8933242092457423vw;
  .sectionTitle {
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5vw;
    line-height: 2.6135036496350364vw;
    text-align: center;
    color: #243746;
    width: 48.75vw;
  }
  .imageContainer {
    // width: 822px;
    width: 63.854166666666664vw;
    height: 100%;
    // object-fit: fill;
    // .rex {
    //   // object-fit: fill !important;
    // }
    video {
      border-radius: 10px;
      // width: 63.854166666666664vw !important;
      // height: 36.064583333333336vw;
      // object-fit: cover;
      background-color: #243746;
    }

    .hoverPlayerClass {
      height: 100%;
      width: 100%;
      display: flex !important;
      // left: 00px;
    }
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 29px;
    margin-bottom: 0px;
    margin-top: 29px;
    .sectionTitle {
      width: 313px;
      height: 70px;
      font-family: Gilroy-Bold;
      font-size: 24px;
      line-height: 24px;
      font-weight: 100;
      color: #243746;
      display: flex;
      align-items: center;
      text-align: start;
    }
    .imageContainer {
      height: 100%;
      width: 100%;
      video {
        border-radius: 0px;
        width: 100% !important;
        height: 100%;
        background-color: #243746;
      }
      .hoverPlayerClass {
      }
    }
  }
}
