.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 117px;
  margin-bottom: 86px;

  font-family: Gilroy-Bold;
  font-style: normal;
  font-weight: lighter;
  font-size: 30px;
  line-height: 100%;
  text-align: center;

  /* Neutral / 600 */

  color: #243746;
}

.seenOuter {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 177px;
}

.seenOuter h4 {
  // color: #cc0136;
  font-weight: 600;
  text-align: center;
}

.seenOuter img {
  width: 100% !important;
  filter: grayscale(100%);
  opacity: 0.7;
}

.imgSeenOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 143px;
  height: 94px;
}

.logoOuter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
  row-gap: 50px;
  width: 900px;
}
