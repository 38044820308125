.mainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  row-gap: 5.208333333333333vw;
  padding: 5.208333333333333vw 0px;
}
.heading {
  width: 100%;
  height: 100%;

  font-family: Gilroy-Bold;
  font-style: normal;
  font-weight: lighter;
  font-size: 2.0833333333333335vw;
  line-height: 1.25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #243746;
}
.ArrowContainerRight {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  background: #243746;
  top: 25%;
  right: -5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 25%;
  left: -10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  cursor: pointer;
}

.arrowClass {
  fill: #fff;
  font-size: 30px;
  color: #fff;
}

.sliderSubContainer {
  width: 55%;
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    row-gap: 54px;
    padding: 50px 0px;
  }
  .heading {
    height: 87px;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #243746;
    width: 79.46666vw;
    margin: 0 auto;
  }
  .ArrowContainerRight {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 30%;
    right: -10%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: drop-shadow(0px 0px 22.7653px rgba(0, 0, 0, 0.25));
  }

  .ArrowContainerLeft {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 30%;
    left: -11%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
    backdrop-filter: drop-shadow(0px 0px 22.7653px rgba(0, 0, 0, 0.25));
  }

  .arrowClass {
    fill: #fff;
    font-size: 20px;
    color: #fff;
  }

  .sliderSubContainer {
    width: 80%;
  }
}
