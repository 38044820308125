.ArrowContainer {
  background-color: #ffffff;
  height: 2.0833333333333335vw !important;
  width: 2.0833333333333335vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: -2%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.ArrowContainer:hover {
  z-index: 10;
}

@media (max-width: 900px) {
  .ArrowContainer {
    background-color: #ffffff;
    height: 28.46px !important;
    width: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: -2%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  }
  .ArrowContainer:hover {
    z-index: 10;
  }
}
