.mainContainer {
  display: flex;
  height: 40.885416666666664vw;
  background: #243746;
  display: flex;
  justify-content: center;
  align-items: center;

  .leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 37%;
    height: 100%;
    .contentContainer {
      display: flex;
      flex-direction: column;
      row-gap: 2.6041666666666665vw;

      .mainText {
        width: 25.729166666666668vw;
        height: 16.145833333333332vw;

        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: lighter;
        font-size: 3.096276041666667vw;
        line-height: 3.75vw;
        text-transform: uppercase;
        color: #ffffff;
      }
      .btnContainer {
        width: 21.822916666666668vw;
        height: 2.9166666666666665vw;
      }
    }
  }

  .rightContainer {
    width: 63%;
    height: 100%;
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        169.83deg,
        #243746 5.18%,
        rgba(36, 55, 70, 0) 56.8%,
        #243746 107.08%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
      z-index: 100;
    }
    .ArrowContainerRight {
      height: 2.6041666666666665vw !important;
      width: 2.6041666666666665vw !important;
      border-radius: 50%;
      z-index: 10;
      position: absolute;
      background: #ffff;
      top: 45%;
      right: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }

    .ArrowContainerLeft {
      height: 2.6041666666666665vw !important;
      width: 2.6041666666666665vw !important;
      border-radius: 50%;
      z-index: 10;
      position: absolute;
      top: 45%;
      left: 3%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffff;
      z-index: 1000;
    }
  }
  .MbtnContainer {
    display: none;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: 50px;
    .leftContainer {
      display: flex;
      background: #243746;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 30px;
      box-sizing: border-box;
      .contentContainer {
        display: flex;
        flex-direction: column;
        row-gap: 4.6992481203007515vh;

        .mainText {
          height: 100%;
          width: 254.05714416503906px;
          line-height: 36px;
          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: lighter;
          font-size: 30px;
          text-transform: uppercase;
          color: #ffffff;
        }
        .btnContainer {
          display: none;
        }
      }
    }

    .rightContainer {
      background-color: black;
      width: 100%;
      height: 100%;
      z-index: 10;

      .overlay {
        position: absolute;
        width: 100%;
        height: 105%;
        top: -3%;
        background: linear-gradient(
          169.83deg,
          #243746 5.18%,
          rgba(36, 55, 70, 0) 56.8%,
          #243746 107.08%
        );
        mix-blend-mode: multiply;
        transform: matrix(1, 0, 0, -1, 0, 0);
        z-index: 10;
      }
      .ArrowContainerRight {
        display: none;
      }

      .ArrowContainerLeft {
        display: none;
      }
    }
    .MbtnContainer {
      display: flex;
      justify-content: center;
      margin: 31px 0px 47px 0px;
    }
  }
}
