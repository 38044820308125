.mainContainer {
  display: flex;
  flex-direction: column;
  width: 21.875vw;
  height: 100%;
  padding-bottom: 3.4375vw;
  align-items: center;
  padding-top: 2.9166666666666665vw;

  background: #ffffff;

  box-shadow: 0px 4px 12px rgba(27, 40, 48, 0.15);
  border-radius: 8px;
  transition: all 0.5s ease;

  .textContainer {
    display: flex;
    flex-direction: column;
    width: 80%;

    .cardHeading {
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: 900;
      font-size: 1.5625vw;
      line-height: 104.5%;
      margin-bottom: 2.5520833333333335vw;
      /* or 31px */

      /* Neutral / 600 */

      color: #243746;
    }

    .cardText {
      height: 6.979166666666667vw;

      font-family: Gilroy-Regular;
      font-size: 1.25vw;
      line-height: 1.8229166666666667vw;
      /* or 146% */
      margin-bottom: 3.1770833333333335vw;

      color: #243746;
    }

    .cardSubText {
      font-family: Gilroy-Light;
      font-weight: normal;
      font-size: 0.8333333333333334vw;
      line-height: 1.25vw;
      /* or 150% */

      display: flex;
      margin-bottom: 4.739583333333333vw;
      align-items: center;

      /* Gray / gray-8 */

      color: #5a6874;
    }

    .MbtnContainer {
      display: none;
    }
  }
}

// .mainContainer:hover {
//   transform: translate3D(0, -1px, 0) scale(1.13);
//   box-shadow: 0px 4px 40px rgba(27, 40, 48, 0.15);
// }

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(27, 40, 48, 0.15);
    border-radius: 8px;
    transition: all 0.5s ease;
    padding-top: 32px;
    padding-bottom: 44px;

    .textContainer {
      display: flex;
      flex-direction: column;
      // width:
      .cardHeading {
        width: 250px;
        height: 25px;
        margin-bottom: 21px;

        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: lighter;
        font-size: 20px;
        line-height: 104.5%;
        color: #243746;
      }

      .cardText {
        height: 57px;
        width: 250px;
        margin-bottom: 34px;
        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #243746;
      }

      .cardSubText {
        height: 32px;
        width: 250px;
        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: bolder;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        color: #5a6874;
        margin-bottom: 49px;
      }
      .btnContainer {
        display: none;
      }
      .MbtnContainer {
        display: flex;
      }
    }
  }
}
