.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 105px 0px;

  @media (max-width: 900px) {
    padding-top: 50px;
  }

  .Heading {
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: 100;
    font-size: 45px;
    line-height: 54px;
    text-align: center;

    margin-bottom: 50px;

    color: #243746;
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    width: 70%;

    .mainText {
      font-family: Gilroy-Bold;

      font-size: 18px;
      line-height: 32px;

      color: #4d4d4d;
      padding: 5px 0px;
    }

    .subText {
      font-family: Gilroy-Regular;

      padding: 50px 0px;

      font-size: 18px;
      line-height: 32px;

      color: #4d4d4d;
      ul {
        width: 90%;
      }
      strong {
        font-family: Gilroy-Bold;
        font-weight: 100;
      }
    }
  }
}
