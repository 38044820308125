.container {
  width: 8.854166666666666vw;
  border: 1px solid #929ba3;
  border-radius: 1.25vw;
  padding-top: 0.2604166666666667vw;
  padding-bottom: 0.2604166666666667vw;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375vw;
  font-family: 'Gilroy-Medium';
  font-style: normal;
  font-weight: 500;
  color: #929ba3;
  cursor: pointer;
}

@media (max-width: 900px) {
  .container {
    width: fit-content;
    border: 1px solid #929ba3;
    border-radius: 6.25vw;
    padding-top: 0.2604166666666667vw;
    padding-bottom: 0.2604166666666667vw;
    padding-left: 25px;
    padding-right: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.787234042553192vw;
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: 500;
    color: #929ba3;
    cursor: pointer;
  }
}
