.container {
  margin-bottom: 26.785714285714285vh;
  height: 100%;

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabPanelContainer {
    display: flex;
    width: 66.66666666666667vw;
    flex-wrap: wrap;
    column-gap: 8.1770833333333335vw;
    row-gap: 10.157894736842104vh;
    justify-content: center;
  }
}
