.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #4fdcb5;
  .leftContainer {
    width: 50vw;
    // height: 700px;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  .rightContainer {
    display: flex;
    justify-content: center;
    width: 50vw;
    padding-top: 30px;
    padding-bottom: 50px;

    .itemContainer {
      display: flex;
      height: 100%;

      flex-direction: column;
      row-gap: 4.6992481203007515vh;
      justify-content: center;

      .comma {
        width: 2.5vw;
        height: 1.8229166666666667vw;
      }

      .commaEnd {
        width: 2.5vw;
        height: 1.8229166666666667vw;
        transform: rotate(180deg);
        margin-left: 34.416666666666664vw;
        img {
          height: 100%;
          width: 100%;
        }
      }

      .textContainer {
        width: 41.5625vw;
        height: 9.875vw;
        font-family: Gilroy-Bold;

        font-size: 2.5vw;
        line-height: 2.6041666666666665vw;
        color: #243746;
      }
      .authorName {
        font-family: Gilroy-Medium;
        font-size: 1.5625vw;
        line-height: 1.25vw;
        font-weight: 600;
        margin-top: -1.0416666666666667vw;
        color: #243746;
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    height: 100%;
    .leftContainer {
      width: 100%;
      height: 100%;
      .image {
        width: 100%;
        height: 100%;
      }
    }
    .rightContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      .itemContainer {
        display: flex;
        height: 100%;

        flex-direction: column;
        row-gap: 30px;
        justify-content: center;

        // margin-left: 5.677083333333333vw;
        // padding: 5.208333333333333vw 0px;
        .comma {
          width: 23px;
          height: 17px;
        }

        .commaEnd {
          margin-left: 270px;
          width: 23px;
          height: 17px;
        }

        .textContainer {
          width: 300px;
          height: 95px;
          font-family: Gilroy-Bold;

          font-size: 20px;
          line-height: 24px;
          color: #243746;
        }
        .authorName {
          width: 117px;
          height: 17px;
          font-family: Gilroy-Medium;
          font-size: 14px;
          line-height: 17px;
          font-weight: bolder;
          margin-top: -1.0416666666666667vw;
          color: #243746;
        }
      }
    }
  }
} ;
