.bottomSec {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  column-gap: 3.90625vw;
  margin-bottom: 5.78125vw;

  .leftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5625vw;
    .image {
      width: 11.869791666666666vw;
      height: 11.869791666666666vw;
    }
    .leftContainer_imageText {
      height: 2.1458333333333335vw;
      width: 17vw;
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: bold;
      font-size: 1.5625vw;
      line-height: 1.8041666666666667vw;
      margin-top: 1.6145833333333333vw;

      text-align: center;

      color: #243746;
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    row-gap: 3.4375vw;
    .rightContainerMainText {
      width: 15.208333333333334vw;
      height: 2.1354166666666665vw;
      margin-top: 2.6041666666666665vw;

      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: lighter;
      font-size: 1.5625vw;
      line-height: 1.25vw;

      color: #243746;
    }

    .rightContainerSubText {
      width: 37.96875vw;
      height: 15.625vw;
      margin-top: 2.0833333333333335vw;
      font-family: Gilroy-Light;
      font-weight: 100;
      font-size: 1.5625vw;
      line-height: 1.8229166666666667vw;
      color: #243746;
    }
  }
}

@media (max-width: 900px) {
  .bottomSec {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .leftContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        width: 150px;
        height: 150px;
      }
      .leftContainer_imageText {
        height: 100%;
        width: 30vw;
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin-top: 6.8vw;
        margin-bottom: 20px;

        text-align: center;

        color: #243746;
      }
    }

    .rightContainer {
      display: flex;
      flex-direction: column;
      row-gap: 22.09vw;
      .rightContainerMainText {
        display: none;
      }

      .rightContainerSubText {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 0px;

        font-family: Gilroy-Light;
        font-weight: 100;
        font-size: 16px;
        line-height: 18px;
        color: #243746;
      }
    }
  }
}
