.mainContainer {
  display: flex;
  flex-direction: column;
  // height: 25.833333333333332vw;
  align-items: center;
  justify-content: center;
  row-gap: 5.729166666666667vw;
  margin-top: 4.791666666666667vw;
  margin-bottom: 5.9375vw;

  .textContainer {
    width: 52.760416666666664vw;
    left: 9.375vw;

    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: 1000;
    font-size: 1.25vw;
    line-height: 1.5625vw;
    /* or 125% */

    display: flex;
    align-items: center;

    color: #243746;
  }

  .images {
    display: flex;
    justify-content: center;
    column-gap: 2.6041666666666665vw;
    filter: drop-shadow(0px 4px 12px rgba(27, 40, 48, 0.15));
    width: 100%;

    .Image {
      width: 9.375vw;
      height: 9.375vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sliderWidthM {
    display: none;
  }

  // .ArrowContainerRight {
  //   height: 2.6041666666666665vw !important;
  //   width: 2.6041666666666665vw !important;
  //   border-radius: 50%;
  //   z-index: 10;
  //   position: absolute;
  //   background: #243746;
  //   top: 30%;
  //   right: 0%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  // .ArrowContainerLeft {
  //   height: 2.6041666666666665vw !important;
  //   width: 2.6041666666666665vw !important;
  //   border-radius: 50%;
  //   z-index: 10;
  //   position: absolute;
  //   top: 30%;
  //   left: 0%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #243746;
  // }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 0.739583333333333vw;
    margin: 34px 0px;
    .textContainer {
      width: 300px;
      height: 100%;
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 100;
      font-size: 14px;
      line-height: 17px;
      color: #243746;
      margin-bottom: 28px;
    }

    .images {
      display: none;
    }

    .sliderWidthM {
      display: flex;
      flex-direction: column;
      width: 500px;
      margin-left: 30px;

      .Image {
        width: 100px !important;
        height: 100px;
        img {
          width: 100%;
          height: 100%;
          // object-fit:contain;
        }
      }
    }

    .ArrowContainerRight {
      height: 28px !important;
      width: 28px !important;
      border-radius: 50%;
      z-index: 10;
      position: absolute;
      background: #243746;
      top: 32%;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .ArrowContainerLeft {
      height: 28px !important;
      width: 28px !important;
      border-radius: 50%;
      z-index: 10;
      position: absolute;
      top: 32%;
      left: -5%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #243746;
      cursor: pointer;
    }
    .ArrowFont {
      color: #ffff;
    }
  }
}

@media (max-width: 540px) {
  .sliderWidthM {
    display: flex;
    flex-direction: column;
    width: 300px !important;
    margin-left: 0px !important;

    .Image {
      width: 82px !important;
      height: 82px;
      img {
        width: 100%;
        height: 100%;
        // object-fit:contain;
      }
    }
  }

  .ArrowContainerRight {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 32%;
    right: -8% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowContainerLeft {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 32%;
    left: -8% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
  }
}
