.mainContainer {
  //   justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 5.208333333333333vw;

  .mainText {
    width: 49.270833333333336vw;
    margin: 6.71875vw 0px;

    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25vw;
    text-align: center;

    color: #4d4d4d;
  }
  .cardList {
    width: 73%;
    column-gap: 4.6875vw;
    row-gap: 4.6875vw;
    display: flex;
    align-items: center;
    // justify-content: center;

    flex-wrap: wrap;
    //   background: linear-gradient(to top left, #243746 31.76%, rgba(36, 55, 70, 0) 64.19%);
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.8708333333333333vw;
    margin: 6.770833333333333vw 0px;

    .btnText {
      width: 100%;
      // margin-top: 6.40625vw;

      font-family: Gilroy-Medium;
      font-weight: 600;
      font-size: 1.5625vw;
      line-height: 1.25vw;
      /* or 80% */

      display: flex;
      align-items: center;
      text-align: center;

      color: #243746;
    }

    .MbtnContainer {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    //   justify-content: center;
    height: 100%;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;

    .mainText {
      width: 80%;
      height: 100%;
      margin: 55px 0px;

      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #4d4d4d;
    }
    .cardList {
      width: 95%;
      // padding:20px;
      column-gap: 20.5px;
      row-gap: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      //   background: linear-gradient(to top left, #243746 31.76%, rgba(36, 55, 70, 0) 64.19%);
    }

    .bottomContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      justify-content: center;
      margin: 70px 0px;

      .btnText {
        width: 250px;
        margin-top: 0px;

        font-family: Gilroy-Medium;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        /* or 80% */

        display: flex;
        align-items: center;
        text-align: center;

        color: #243746;
      }

      .btnContainer {
        display: none;
      }

      .MbtnContainer {
        display: block;
      }
    }
  }
}
