.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -12.479166666666664vw;
  padding-bottom: 5vw;

  background: linear-gradient(0deg, #243746 53.5%, rgba(36, 55, 70, 0) 80.72%);
  .MtabContainer {
    display: none;
  }
  .tabsContainer {
    display: flex;
    justify-content: center;
  }
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mainText {
      width: 40.104166666666664vw;
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: 100;
      font-size: 2.34375vw;
      line-height: 2.8125vw;
      text-align: center;
      margin-top: 24.341666666666665vw;

      /* Neutral / 100 */

      color: #ffff;
    }

    .subText {
      width: 44.635416666666664vw;
      margin-bottom: 8.854166666666666vw;
      margin-top: 2.0833333333333335vw;
      font-family: Gilroy-Light;
      font-style: normal;
      font-weight: normal;
      font-size: 1.25vw;
      line-height: 1.4583333333333333vw;
      text-align: center;

      /* Neutral / 100 */

      color: #ffffff;
    }
  }

  .sliderItemContainer {
    position: relative;

    .itemSlider {
      margin-right: 0.5208333333333334vw;

      width: 14.3296875vw;
      height: 24.4640625vw;
      border-radius: 10px;

      float: left;
      position: relative;
      transition: height 0.5s;
      -webkit-transition: height 0.5s;
      overflow: hidden;
      transition: 1.5s;

      .cardImage {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
      video {
        background-color: #243746;
        height: 100%;
        border-radius: 10px !important;
      }
    }

    .itemSlider:hover {
      width: 190%;
      z-index: 1000;
    }
  }

  .bottomSubText {
    width: 30.833333333333332vw;
    height: 1.4583333333333333vw;
    margin-top: 4.635416666666667vw;
    font-family: Gilroy-Light;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25vw;
    line-height: 1.4583333333333333vw;
    text-align: center;
    color: #ffffff;
  }

  .btnContainer {
    margin-top: 3.4895833333333335vw;
  }
  .MbtnContainer {
    display: none;
    width: 100%;
  }

  .ArrowContainerRight {
    height: 2.6041666666666665vw !important;
    width: 2.6041666666666665vw !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #ffff;
    top: 11.854166666666666vw;
    right: -2%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .ArrowContainerLeft {
    height: 2.6041666666666665vw !important;
    width: 2.6041666666666665vw !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 11.854166666666666vw;
    left: -3%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffff;
    cursor: pointer;
  }

  .ArrowFont {
    color: #000000;
    font-size: 2.2625vw;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: -230px;
    padding-bottom: 68px;

    background: linear-gradient(0deg, #243746 57.5%, rgba(36, 55, 70, 0) 80.72%);
    .tabsContainer {
      display: none;
    }
    .MtabContainer {
      display: block;
      margin-bottom: 50px;
    }
    .heading {
      display: flex;
      flex-direction: column;
      align-items: center;

      .mainText {
        width: 259px;
        height: 100%;
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: lighter;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        padding-top: 370px;
        margin-top: 0px;
        color: #ffff;
      }

      .subText {
        width: 282px;
        height: 100%;
        margin-bottom: 15.977443609022556vh;
        margin-top: 38px;
        font-family: Gilroy-Light;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
      }
    }

    .sliderItemContainer {
      position: relative;
      .itemSlider {
        margin-right: 0.5208333333333334vw;
        width: 95%;
        height: 263px;
        border-radius: 10px;

        float: left;
        position: relative;
        transition: height 0.5s;
        -webkit-transition: height 0.5s;
        overflow: hidden;
        transition: 1.5s;

        .cardImage {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
        video {
          background-color: #243746;
          height: 100%;
          border-radius: 10px !important;
        }
      }

      .itemSlider:hover {
        width: 190%;
        z-index: 1000;
      }
    }
    .bottomSubText {
      width: 282px;
      height: 100%;
      margin-top: 47px;
      font-family: Gilroy-Light;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
    }

    .btnContainer {
      display: none;
    }
    .MbtnContainer {
      display: block;
      width: 303px;
      padding-top: 40px;
    }

    .ArrowContainerRight {
      width: 28.46px !important;
      height: 28.46px !important;
      border-radius: 50%;
      z-index: 10;
      position: absolute;
      background: #ffffff;
      top: 50%;
      right: -4%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ArrowContainerLeft {
      width: 28.46px !important;
      height: 28.46px !important;
      border-radius: 50%;
      z-index: 10;
      position: absolute;
      top: 50%;
      left: -4%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffff;
    }
    .ArrowFont {
      color: #000000;
      font-size: 25px;
    }
  }
}
