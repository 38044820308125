.container {
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 4em;

  .backgroundStyles {
    height: inherit;
    background: #142232;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2.6041666666666665vw;
    padding: 5.208333333333333vw 0px;

    .textContainer {
      display: flex;
      flex-direction: column;
      row-gap: 1.6062801932367148vw;
      z-index: 100;
      .MbtnContainer {
        display: none;
      }
      .title {
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 2.5083802083333335vw;
        line-height: 3.105475040257649vw;
        text-transform: uppercase;
        width: 26.041666666666668vw;
        color: #ffffff;
      }
      .subtitle {
        font-family: Gilroy-Light;
        font-size: 1.25vw;
        line-height: 1.499194847020934vw;
        width: 25.104166666666668vw;
        height: 3.3731884057971016vw;
        color: #ffffff;
      }
      .btnContainer {
        margin-top: 1.6062801932367148vw;
        display: flex;
        column-gap: 1.5625vw;
      }
    }
    .imageContainer {
      height: 23.6658615136876vw;
      width: 33.834375vw;

      .imageScyptp {
        width: 100%;
        height: 100%;
      }
    }
    .DoctorContainer {
      height: 100%;
      width: 3.6458333333333335vw;
      font-weight: 600;
      font-size: 0.8333333333333334vw;
    }
  }
  .overlayContainer {
    position: absolute;
    top: 0px;
    height: 26.771336553945254vw;
    width: 100%;
    .overlay1 {
      height: 35.12399355877617vw;
      width: inherit;
      background: linear-gradient(
        93.76deg,
        rgba(43, 72, 105, 0.678161) 3.08%,
        rgba(59, 108, 179, 0) 83.84%,
        #243746 99.78%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
}

@media (max-width: 900px) {
  .container {
    height: 100%;
    .backgroundStyles {
      flex-direction: column-reverse;
      height: 100%;
      padding: 0px;
      .textContainer {
        margin-top: 29px;
        display: flex;
        flex-direction: column;
        row-gap: 2.898550724637681vh;
        z-index: 100;
        height: 100%;
        .MbtnContainer {
          margin: 50px 0px;
          display: flex;
          flex-direction: column;
          row-gap: 25px;
        }
        .title {
          font-size: 30px;
          line-height: 36px;
          width: 295px;
        }
        .subtitle {
          font-family: Gilroy-Light;
          font-size: 16px;
          line-height: 18px;
          width: 276.43px;
          color: #ffffff;
        }
        .btnContainer {
          display: none;
        }
      }
      .imageContainer {
        height: 100%;
        width: 375px;
        .imageScyptp {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      }
      .DoctorContainer {
        height: 100%;
        width: 3.6458333333333335vw;
        font-weight: 600;
        font-size: 0.8333333333333334vw;
      }
    }
    .overlayContainer {
      position: absolute;
      top: 0px;
      height: 100%;
      width: 100%;
      .overlay1 {
        height: 100%;
        width: inherit;
        background: linear-gradient(
          93.76deg,
          rgba(43, 72, 105, 0.678161) 3.08%,
          rgba(59, 108, 179, 0) 83.84%,
          #243746 99.78%
        );
        mix-blend-mode: multiply;
        transform: matrix(1, 0, 0, -1, 0, 0);
        pointer-events: none;
      }
    }
  }
}
