.container {
  display: flex;
  width: 100%;
  height: 40.885416666666664vw;
  // left: 0px;
  // top: 144px;
  background: #243746;

  .leftContainer {
    display: flex;
    // justify-content: center;
    // align-items: center;
    width: 37vw;
    // height: 785px;
    // left: 0px;
    // background-color: red;
    // top: 144px;
    position: relative;

    .textContainer {
      display: flex;
      flex-direction: column;
      row-gap: 3.125vw;
      margin-top: 11.979166666666666vw;

      .mainText {
        width: 15.15625vw;
        margin-left: 7.5vw;
        font-family: Gilroy-Bold;
        font-weight: 100;
        font-size: 3.096276041666667vw;
        line-height: 3.75vw;
        color: #ffffff;
        text-transform: uppercase;
      }
      .subText {
        margin-left: 7.5vw;
        font-family: Gilroy-Light;
        width: 16.822916666666668vw;
        height: 4.375vw;

        font-weight: 100;
        font-size: 1.25vw;
        line-height: 1.4583333333333333vw;
        opacity: 0.8;

        /* Neutral / 100 */

        color: #ffffff;
      }
    }
  }

  .rightContainer {
    width: 63vw;
    height: 100%;
    position: relative;

    background-color: #efefef;
    img {
      width: 100%;

      height: 100%;
    }
    .imageContainer {
      width: 100%;
      // width: 1240px;
      height: 100%;
      // height: 90.43478260869566vh;
    }
    .gradient {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      // z-index:100;

      background: linear-gradient(
        145.83deg,
        #243746 0.18%,
        rgba(36, 55, 70, 0) 56.8%,
        #243746 107.08%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
  .btnContainer {
    display: none;
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    height: inherit;
    padding-top: 50px;
    .leftContainer {
      display: flex;
      // justify-content: center;
      // align-items: center;

      width: 100%;
      // height: 785px;
      // left: 0px;
      // background-color: red;
      // top: 144px;
      position: relative;

      .textContainer {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-left: 38px;
        margin-top: 60px;
        margin-bottom: 30px;

        .mainText {
          width: 241px;
          height: 70px;
          margin-left: 0px;
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 30px;
          line-height: 36px;
          color: #ffffff;
          text-transform: uppercase;
        }
        .subText {
          width: 244px;
          height: 63px;
          margin-left: 0px;
          font-family: Gilroy-Light;

          font-weight: 100;
          font-size: 18px;
          line-height: 21px;
          opacity: 0.8;

          /* Neutral / 100 */

          color: #ffffff;
        }
      }
    }

    .rightContainer {
      width: 100%;
      height: 63.2vw;
      position: relative;

      background-color: #efefef;

      .imageContainer {
        width: 100%;
        // width: 1240px;
        height: 100%;
        // height: 90.43478260869566vh;
      }
      .gradient {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        // z-index:100;

        background: linear-gradient(
          145.83deg,
          #243746 0.18%,
          rgba(36, 55, 70, 0) 56.8%,
          #243746 107.08%
        );
        mix-blend-mode: multiply;
        transform: matrix(1, 0, 0, -1, 0, 0);
      }
    }
    .btnContainer {
      margin-top: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 34px;
    }
  }
}
