.textContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 9.910802775024777vh;
  margin-bottom: 9.10802775024777vh;
  .text {
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5vw;
    line-height: 2.8vw;
    width: 53.854166666666664vw;
    text-align: center;

    color: #243746;
  }
}
.locationSection {
  .textContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3.7661050545094152vh;
    .text {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 1.25vw;
      line-height: 2.0833333333333335vw;
      color: #1b2830;
    }
  }
  .chipOuterContainer {
    display: flex;
    align-items: center;
    column-gap: 1.0416666666666667vw;
    margin-bottom: 3.7661050545094152vh;
    justify-content: center;
    .chipContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1.0416666666666667vw;
      row-gap: 0.6416666666666667vw;
    }
    .divider {
      width: 0px;
      height: 36.5px;
      left: 925px;
      top: 1265px;

      border: 1px solid #000000;
    }
  }
  .locationMobileTabContainer {
    display: none;
  }
}

@media (max-width: 900px) {
  .textContainer {
    margin-top: 7.910802775024777vh;
    margin-bottom: 7.10802775024777vh;
    .text {
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 6.5vw;
      line-height: 7.8vw;
      width: 79.854166666666664vw;
      text-align: center;
      color: #243746;
    }
  }
  .locationSection {
    .textContainer {
      .text {
        font-size: 4.8vw;
      }
    }
    .chipOuterContainer {
      display: none;

      .chipContainer {
      }
      .divider {
      }
    }

    .locationMobileTabContainer {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-bottom: 20px;

      .MdividerContainer {
        display: flex;
        justify-content: center;
        .Mdivider {
          border: 1px solid #000000;
          width: 50vw;
        }
      }
    }
  }
}
