.mainContainer {
  display: flex;
  height: 100%;
  width: 100%;

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 70vw;
    width: 55vw;

    .textContainer {
      display: flex;
      flex-direction: column;
      row-gap: 3.3333333333333335vw;
      // margin-top: 3.7593984962406015vh;
      // margin-left: 2.0833333333333335vw;
      // justify-content: center;
      // align-items: center;
      .mainText {
        // text-align: center;
        width: 17.704166666666666vw;
        // height: 7.631578947368421vh;

        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: lighter;
        font-size: 1.5625vw;
        line-height: 1.8229166666666667vw;
        /* or 117% */

        color: #243746;
      }

      .subText {
        // text-align: center;
        width: 36.40625vw;
        // height: 23.68421052631579vh;

        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: lighter;
        font-size: 1.5625vw;
        line-height: 1.8229166666666667vw;
        /* or 117% */

        color: #243746;
      }
    }
  }

  .rightContainer {
    //   width:50vw;
    width: 45vw;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    flex-direction: column-reverse;

    .leftContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .textContainer {
        display: flex;
        flex-direction: column;
        row-gap: 0px;
        margin-top: 6.6666vw;
        margin-left: 2.0833333333333335vw;

        //   align-items: center;
        .mainText {
          width: 80vw;
          font-family: Gilroy-Bold;
          font-size: 20px;
          line-height: 23px;
          height: 100%;
          color: #243746;
        }

        .subText {
          width: 80vw;
          height: 100%;
          font-family: Gilroy-Light;
          font-size: 16px;
          line-height: 19px;
          margin-top: 6.6666vw;

          color: #243746;
        }
      }
    }

    .rightContainer {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
