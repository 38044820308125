.mainContainer {
  display: none;
  // width: 100%;
  // height: 100%;
  // display: unset;

  // background: unset;
  // padding-top: 0px;
  // padding-bottom: 0px;
  // .mainText {
  //   display: none;
  // }

  // .Image {
  //   display: none;
  // }

  // .feature {
  //   display: none;
  // } // background: linear-gradient(180deg, #f6f7fb 0%, rgba(246, 247, 251, 0) 114.24%);
  // // display: flex;
  // // flex-direction: column;
  // // //   height: 4023.15px;
  // // width: 100%;
  // // row-gap: 9.398496240601503vh;
  // // margin-bottom: 14.097744360902256vh;
  // // //   align-items: center;
  // // //   justify-content: center;

  // // .headingWrapper {
  // //   display: flex;
  // //   flex-direction: column;
  // //   // align-items: center;
  // //   margin-top: 23.026315789473685vh;
  // //   .firstHeading {
  // //     // flex-direction: column;
  // //     width: 43.177083333333336vw;
  // //     height: 4.417293233082707vh;
  // //     margin-left: 28.125vw;

  // //     align-items: center;
  // //     justify-content: center;

  // //     font-family: Gilroy-Bold;
  // //     font-style: normal;
  // //     font-weight: 900;
  // //     font-size: 2.34375vw;
  // //     line-height: 104.5%;
  // //     color: #243746;
  // //     /* identical to box height, or 47px */

  // //     text-align: center;
  // //   }

  // //   .subHeading {
  // //     width: 38.385416666666664vw;
  // //     height: 8.270676691729323vh;
  // //     margin-top: 8.270676691729323vh;
  // //     margin-left: 30.520833333333332vw;

  // //     font-family: Gilroy-Light;
  // //     font-style: normal;
  // //     font-weight: normal;
  // //     font-size: 1.25vw;
  // //     line-height: 2.6315789473684212vh;
  // //     text-align: center;
  // //   }

  // //   .TabsSec {
  // //     display: flex;
  // //     justify-content: center;
  // //     margin-top: 41px;
  // //   }
  // // }
  // // .secondHeading {
  // //   display: flex;
  // //   width: 38.385416666666664vw;
  // //   height: 8.270676691729323vh;
  // //   margin-left: 32.239583333333336vw;
  // //   font-family: Gilroy-Bold;
  // //   font-weight: 100;
  // //   font-size: 3.3333333333333335vw;
  // //   line-height: 7.2368421052631575vh;
  // //   text-align: center;

  // //   color: #4d4d4d;
  // // }

  // // .image {
  // //   img {
  // //     width: 100%;
  // //     height: 100%;
  // //   }
  // }
}

@media (max-width: 900px) {
  .mainContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #f6f7fb 0%, rgba(246, 247, 251, 0) 114.24%);
    padding-top: 70px;
    padding-bottom: 70px;
    .mainText {
      width: 259px;
      height: 108px;
      font-family: Gilroy-Bold;
      font-weight: 100;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 152px;
    }

    .Image {
      margin-bottom: 15px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      margin-top: 55px;
      .dot1 {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fb637e;
      }

      .dot2 {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #47d7ac;
      }

      .dot3 {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #3b6cb3;
      }
      .Text {
        width: 186px;
        height: 38px;

        font-family: Gilroy-Medium;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: #000000;
      }
    }
  }

  // .mainContainer {
  //   background: linear-gradient(180deg, #f6f7fb 0%, rgba(246, 247, 251, 0) 114.24%);
  //   display: flex;
  //   flex-direction: column;
  //   //   height: 4023.15px;
  //   width: 100%;
  //   row-gap: 0px;
  //   margin-bottom: 0px;
  //   //   align-items: center;
  //   //   justify-content: center;

  //   .headingWrapper {
  //     display: flex;
  //     flex-direction: column;
  //     // align-items: center;
  //     margin-top: 0px;
  //     .firstHeading {
  //       // flex-direction: column;
  //       width: 43.177083333333336vw;
  //       height: 4.417293233082707vh;
  //       margin-left: 28.125vw;

  //       align-items: center;
  //       justify-content: center;

  //       font-family: Gilroy-Bold;
  //       font-style: normal;
  //       font-weight: 900;
  //       font-size: 2.34375vw;
  //       line-height: 104.5%;
  //       color: #243746;
  //       /* identical to box height, or 47px */

  //       text-align: center;
  //     }

  //     .subHeading {
  //       display: none;
  //     }

  //     .TabsSec {
  //       display: none;
  //     }
  //   }
  //   .secondHeading {
  //     display: none;
  //   }

  //   .image {
  //     display: none;
  //   }
  // }
}
