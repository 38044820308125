.chipWrapper {
  height: 100%;
  background: #ffffff;
  border-radius: 16px;
  // padding:5px;
  width: 100%;
  padding: 0.2604166666666667vw;

  font-family: Gilroy-Regular;
  font-weight: normal;
  font-size: 1.09375vw;
  text-align: center;
  color: #2b4f82;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
}

@media (max-width: 900px) {
  .chipWrapper {
    font-size: 14px;
    width: 103px;
  }
}
