.mainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 5.208333333333333vw;
  row-gap: 5.208333333333333vw;
}
.heading {
  width: 100%;
  height: 1.6666666666666667vw;

  font-family: Gilroy-Bold;
  font-style: normal;
  font-weight: lighter;
  font-size: 2.0833333333333335vw;
  line-height: 1.25vw;
  text-align: center;
  color: #243746;
}

.mainSliderWidth {
  width: 55%;
}
.ArrowContainerRight {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  background: #243746;
  top: 30%;
  right: -5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 30%;
  left: -10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  cursor: pointer;
}

.ArrowFont {
  color: #ffff;
  font-size: 2.2625vw;
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 19.4666vw;
    row-gap: 10.133333vw;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7.73333vw;

    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: 100;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #243746;
  }

  .mainSliderWidth {
    width: 80%;
    position: relative;

    .overlay1 {
      position: absolute;
      left: 0px;
      width: 150px;
      height: 100px;
      background: linear-gradient(to right, #ffffff 26.04%, rgba(255, 255, 255, 0) 100%);
      z-index: 10;
      pointer-events: none;
    }

    .overlay2 {
      position: absolute;

      right: 0px;
      width: 150px;
      height: 100px;
      background: linear-gradient(to left, #ffffff 26.04%, rgba(255, 255, 255, 0) 100%);
      z-index: 10;
      pointer-events: none;
    }
  }
  .ArrowContainerRight {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 30%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowContainerLeft {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 30%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
  }

  .ArrowFont {
    color: #ffff;
    font-size: 2.2625vw;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 19.4666vw;
    row-gap: 10.133333vw;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 7.73333vw;

    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: 100;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #243746;
  }

  .mainSliderWidth {
    width: 100%;
    position: relative;

    .overlay1 {
      position: absolute;
      left: 0px;
      width: 100px;
      height: 100px;
      background: linear-gradient(to right, #ffffff 26.04%, rgba(255, 255, 255, 0) 100%);
      z-index: 10;
      pointer-events: none;
    }

    .overlay2 {
      position: absolute;

      right: 0px;
      width: 100px;
      height: 100px;
      background: linear-gradient(to left, #ffffff 26.04%, rgba(255, 255, 255, 0) 100%);
      z-index: 10;
      pointer-events: none;
    }
  }
  .ArrowContainerRight {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 30%;
    right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;

    filter: drop-shadow(0px 0px 22.7653px rgba(0, 0, 0, 0.25));
  }

  .ArrowContainerLeft {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 30%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;

    filter: drop-shadow(0px 0px 22.7653px rgba(0, 0, 0, 0.25));
  }

  .ArrowFont {
    color: #ffff;
    font-size: 2.2625vw;
  }
}
