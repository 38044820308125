.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f6f7fb;
  position: relative;

  .mainText {
    width: 34.895833333333336vw;
    height: 6.041666666666667vw;
    margin-top: 4.99vw;
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: Bolder;
    font-size: 2.0833333333333335vw;
    line-height: 3.0208333333333335vw;
    margin-bottom: 3.0208333333333335vw;
    text-align: center;
    color: #243746;
  }
  .gradient1 {
    position: absolute;
    width: 32.708333333333336vw;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, #f6f7fb 57.29%, rgba(246, 247, 251, 0) 100%);
    pointer-events: none;
    z-index: 1000;
  }
  .gradient2 {
    position: absolute;
    width: 32.708333333333336vw;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;

    background: linear-gradient(to left, #f6f7fb 57.29%, rgba(246, 247, 251, 0) 100%);
    z-index: 1000;
  }

  .mainSliderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: 32.708333333333336vw;

    margin-left: 4.166666666666667vw;
    // margin-bottom:83px;
    .sliderContainer {
      width: 100%;
      height: 32.7083333360625vw;
      border-radius: 8px !important;
    }
  }
}
.ArrowContainerRight {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  background: #243746;
  top: 45%;
  right: 9%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  top: 45%;
  left: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  cursor: pointer;
}

.ArrowFont {
  color: #ffff;
  font-size: 8px;
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f6f7fb;
    position: relative;
    padding-bottom: 53px;

    .mainText {
      width: 298px;
      height: 29px;
      margin-top: 42px;
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: Bolder;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 31px;
      text-align: center;
      color: #243746;
    }
    .gradient1 {
      display: none;
    }
    .gradient2 {
      display: none;
    }

    .mainSliderContainer {
      display: flex;
      justify-content: center;
      width: 700px;
      height: 100%;
      margin-left: 0px;
      // margin-bottom:83px;
      .sliderContainer {
        width: 100%;
        height: 100%;
        border-radius: 8px !important;
      }
    }
  }
  .ArrowContainerRight {
    height: 25px !important;
    width: 25px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 45%;
    right: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowContainerLeft {
    height: 25px !important;
    width: 25px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 45%;
    left: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
  }

  .ArrowFont {
    color: #ffff;
    font-size: 8px;
  }
}

@media (max-width: 700px) {
  .mainContainer {
    .mainSliderContainer {
      display: flex;
      justify-content: center;
      width: 330px;
      .sliderContainer {
        width: 330px;
        height: 100%;
      }
    }

    .ArrowContainerLeft {
      left: -2%;
    }

    .ArrowContainerRight {
      right: -2%;
    }
  }
}

@media (max-width: 550px) {
  .mainContainer {
    .mainSliderContainer {
      display: flex;
      justify-content: center;
      .sliderContainer {
        width: 330px;
        height: 100%;
        border-radius: 20px;
      }
    }
  }
}
