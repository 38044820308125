.container {
  display: flex;
  justify-content: center;
  margin-bottom: 7.65625vw;
  .subContainer {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .titleContainer {
      margin-top: 4.081825684380032vw;
      margin-bottom: 1.3102427536231884vw;

      .text {
        font-family: Gilroy-Medium;
        font-style: normal;
        font-weight: 600;
        font-size: 2.0833333333333335vw;
        line-height: 2.5700483091787443vw;
        display: flex;
        align-items: center;
        color: #243746;
      }
    }
    .textContainer {
      margin-bottom: 4.132209339774557vw;

      font-family: Gilroy-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8333333333333334vw;
      line-height: 1.392109500805153vw;
      color: #253746;
      opacity: 0.65;
      display: flex;
      flex-direction: column;
      row-gap: 1.0078872785829307vw;
    }
    .imageContainer {
      // width: 822px;
      height: 100%;
      width: 61.666666666666664vw;

      video {
        border-radius: 10px;
        width: 99.8% !important;
        background-color: #243746;
        height: 36.064583333333336vw;

        // height:100%;
      }

      .hoverPlayerClass {
        height: 100%;
        width: 100%;
        display: flex !important;
      }
    }
    .emailContainer {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 6.425120772946859vw;
      margin-bottom: 5.049930756843801vw;
      .emailTextContainer {
        .text {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: 800;
          font-size: 0.8771874999999999vw;
          line-height: 1.2598055555555556vw;
          color: #243746;
          strong {
            font-family: Gilroy-Bold;
          }
        }
      }
      .inputContainer {
        .input {
          width: 29.385989583333334vw;
          background: #ffffff;
          border: 0.058823529411764705rem solid #243746;
          box-sizing: border-box;
          border-radius: 0.47058823529411764rem;
          padding: 0.5882352941176471rem;
        }
      }
    }
    .MemailContainer {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;

    .subContainer {
      width: 100%;
      .titleContainer {
        margin-top: 31px;
        margin-bottom: 28px;
        width: 100%;

        .text {
          font-family: Gilroy-Medium;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #243746;
          padding-left: 36px;
          box-sizing: border-box;
        }
      }
      .textContainer {
        padding: 0px 36px;
        box-sizing: border-box;
        margin-bottom: 36px;
        font-family: Gilroy-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #253746;
        opacity: 0.65;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }
      .imageContainer {
        height: 100%;
        width: 100%;
        video {
          border-radius: 0px !important;
          width: 100% !important;
          background-color: #243746;
        }
        .hoverPlayerClass {
        }
      }
      .emailContainer {
        display: none;
      }
      .MemailContainer {
        display: none;
        justify-content: center;
        margin: 20px 0px 73px 0px;
      }
    }
  }
}
