@font-face {
  font-family: 'gilroy-bold';
  font-style: normal;
  /* font-weight: 400; */
  src: url(./fonts/Gilroy-Bold.woff) format('woff');
}
@font-face {
  font-family: 'gilroy-heavy';
  font-style: normal;
  /* font-weight: 400; */
  src: url(./fonts/Gilroy-Heavy.woff) format('woff');
}
@font-face {
  font-family: 'gilroy-light';
  font-style: normal;
  /* font-weight: 400; */
  src: url(./fonts/Gilroy-Light.woff) format('woff');
}
@font-face {
  font-family: 'gilroy-medium';
  font-style: normal;
  /* font-weight: 400; */
  src: url(./fonts/Gilroy-Medium.woff) format('woff');
}
@font-face {
  font-family: 'gilroy-regular';
  font-style: normal;
  /* font-weight: 400; */
  src: url(./fonts/Gilroy-Regular.woff) format('woff');
}

.MuiAccordionSummary-root {
  font-family: Gilroy-Medium !important;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #243746;
}
.title_teamPage {
  width: 653px;
  height: 81px;
  left: 634px;
  top: 2299px;

  font-family: Gilroy-Bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  margin-top: 140px;
  margin-bottom: 61px;
  color: #243746;
}

.react-player__preview {
  border-radius: 20px;
}
.sliderCont {
  width: 63.697916666666664vw;
}
#exploreInstructor .swiper-slide:hover {
  float: left !important;
  z-index: 10000 !important;
}

#exploreInstructor3 .swiper-slide:hover {
  float: left !important;
  z-index: 10000 !important;
}

#exploreInstructor .swiper-slide-active + .swiper-slide + .swiper-slide + .swiper-slide > div {
  float: right !important;
}

#exploreInstructor .swiper-slide {
  width: auto;
}

#exploreInstructor3 .swiper-slide {
  width: auto;
}

#exploreInstructor3 .swiper-slide-active + .swiper-slide + .swiper-slide > div {
  float: right !important;
}

#exploreInstructor2 .swiper-slide-active + .swiper-slide > div {
  float: right !important;
}

@media (max-width: 900px) {
  .sliderCont {
    width: 100%;
  }
  #exploreInstructor .swiper-slide-active + .swiper-slide + .swiper-slide > div {
    float: right !important;
  }
}
.instructor-slider .slick-active + .slick-active + .slick-active > div > div > div {
  float: right !important;
}

.physician-slider {
  max-width: 67%;
}
.physician-slider-2 {
  max-width: 67%;
}

.physician-slider .slick-active + .slick-active + .slick-active > div > div > div {
  float: right !important;
}

.physician-slider-2 .slick-active + .slick-active > div > div > div {
  float: right !important;
}

@media (max-width: 600px) {
  .instructor-slider .slick-active + .slick-active > div > div > div {
    float: right !important;
  }
  .physician-slider {
    max-width: 65%;
  }
  .physician-slider-2 {
    max-width: 65%;
  }
  .physician-slider .slick-active + .slick-active > div > div > div {
    float: right !important;
  }

  .physician-slider-2 .slick-active + .slick-active > div > div > div {
    float: right !important;
  }

  #exploreInstructor .swiper-slide-active + .swiper-slide > div {
    float: right !important;
  }
  #exploreInstructor3 .swiper-slide-active + .swiper-slide > div {
    float: right !important;
  }
}

.coreValues-slider .slick-dots {
  bottom: 0px !important;
  right: 0% !important;
  font-size: 30px !important;
  transform: rotate(0deg);
}

.coreValues-slider .slick-dots li button:before {
  font-size: 15px !important;
  color: #f6f7fb !important;
  opacity: 1 !important;
}

.coreValues-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #4fdcb5 !important;
}

.expandMore {
  font-size: 30px !important;
}

.expandLess {
  font-size: 30px !important;
}

@media (max-width: 480px) {
  .physician-slider {
    max-width: 85%;
  }
  .physician-slider-2 {
    max-width: 85%;
  }
}

/* for home mobile tabs slider  */

#homeCourselMTabs .swiper-slide {
  width: auto !important;
}

.fullscreen {
  height: inherit !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out.mapboxgl-ctrl-icon {
  background-image: url('../node_modules/mapbox-gl/src/css/svg/mapboxgl-ctrl-zoom-out.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass.mapboxgl-ctrl-icon {
  background-image: url('../node_modules/mapbox-gl/src/css/svg/mapboxgl-ctrl-compass.svg');
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in.mapboxgl-ctrl-icon {
  background-image: url('../node_modules/mapbox-gl/src/css/svg/mapboxgl-ctrl-zoom-in.svg');
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

#locationMTabs .swiper-slide {
  width: fit-content !important;
}

.main-header.fixed-header {
  top: 0px;
  left: 0;
  position: fixed;
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  padding: 10px 10px;
  background: #f6f7fb;
  z-index: 10000;
}
.mobile-header {
  top: 0px;
  left: 0;
  position: fixed;
  padding: 10px;
}
.mobile-header.fixed-header {
  -webkit-animation: sticky 1s;
  animation: sticky 1s;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
  padding: 10px 10px;
  background: #f6f7fb;
  z-index: 10000;
  width: 100%;
}

.gradientBg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(180deg, rgba(36, 55, 70, 0.14) 0%, rgba(228, 230, 237, 0) 100%);
  mix-blend-mode: multiply;
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}
