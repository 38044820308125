.bigContainer {
  position: relative;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    margin-bottom: 3.7479871175523347vw;

    .innerLeftContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 3.0917874396135265vh;
      z-index: 100;
      margin-right: 1.0416666666666667vw;

      .textContainer {
        display: flex;
        width: 31.25vw;
        height: 30vw;

        .textSubContainer {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: 600;
          font-size: 0.8771874999999999vw;
          line-height: 1.6062801932367148vw;

          color: #243746;
          margin-left: 2.19296875vw;
          align-items: center;

          .textHeader {
            font-family: Gilroy-Medium;
            font-style: normal;
            font-weight: 600;
            font-size: 2.6041666666666665vw;
            line-height: 2.6771336553945253vw;
            // margin-top: -30px;

            display: flex;
            align-items: center;
            color: #243746;
            margin-bottom: 1.9275362318840579vw;
          }

          ul {
            list-style: none;
          }

          ul li {
            font-family: Gilroy-Medium;
            font-weight: 600;
            font-size: 1.0416666666666667vw;
            line-height: 2.6771336553945253vw;
            color: #243746;

            align-items: center;
          }
          ul li::before {
            content: '\2022';
            color: #47d7ac;
            font-size: 2.19296875vw;
            width: 2em;
            vertical-align: middle;
            margin-right: 0.8771874999999999vw;
          }
        }
      }
      .MtextContainer {
        display: none;
      }

      .btnContainer {
        display: flex;
        column-gap: 1.3541666666666667vw;
        float: left;
      }
      .MbtnContainer {
        display: none;
      }
      .phoneImageContainer {
        width: 7.903645833333333vw;
        margin-top: 2.6209138486312398vw;
      }
    }
    .innerRightContainer {
      .mtextHeader {
        display: none;
        width: 195px;
        height: 33px;

        font-family: Gilroy-Medium;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-align: center;

        color: #243746;
      }
      .rightScreenContainer {
        width: 33.854166666666664vw;
        img {
          width: 120%;
          height: 100%;
        }
      }
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, #ffffff 5.42%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(-180deg);
    width: 100%;
    height: 15.795088566827697vw;
  }
}

@media (max-width: 900px) {
  .bigContainer {
    position: relative;
    .container {
      display: flex;
      flex-direction: column-reverse;

      .innerLeftContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        // width:345.54px;
        row-gap: 5.0917874396135265vh;
        z-index: 100;
        margin-right: 1.0416666666666667vw;
        .textContainer {
          display: none;

          .textSubContainer {
            font-family: Gilroy-Regular;
            font-style: normal;
            font-weight: 600;
            font-size: 0.8771874999999999vw;
            line-height: 2.898550724637681vh;

            color: #243746;
            margin-left: 2.19296875vw;
            align-items: center;

            .textHeader {
              font-family: Gilroy-Medium;
              font-style: normal;
              font-weight: 600;
              font-size: 2.6041666666666665vw;
              line-height: 4.830917874396135vh;
              display: flex;
              align-items: center;
              color: #243746;
              margin-bottom: 3.4782608695652173vh;
            }

            ul {
              list-style: none;
            }

            ul li {
              font-family: Gilroy-Medium;
              font-weight: 600;
              font-size: 1.0416666666666667vw;
              line-height: 4.830917874396135vh;
              color: #243746;

              align-items: center;
            }
            ul li::before {
              content: '\2022';
              color: #47d7ac;
              font-size: 2.19296875vw;
              width: 2em;
              vertical-align: middle;
              margin-right: 0.8771874999999999vw;
            }
          }
        }

        .MtextContainer {
          display: block;

          .MtextSubContainer {
            // font-family: Gilroy-Regular;
            // font-style: normal;
            // font-weight: 600;
            // font-size: 0.8771874999999999vw;
            // line-height: 2.898550724637681vh;

            // color: #243746;
            // margin-left: 2.19296875vw;
            // align-items: center;

            ul {
              list-style: none;
            }

            ul li {
              font-family: Gilroy-Medium;
              font-weight: 600;
              font-size: 16.4189px;
              line-height: 4.830917874396135vh;
              color: #243746;

              align-items: center;
            }
            ul li::before {
              content: '\2022';
              color: #47d7ac;
              font-size: 30.4189px;
              width: 2em;
              vertical-align: middle;
              padding-right: 4.8771874999999999vw;
            }
          }
        }

        .btnContainer {
          display: none;
          column-gap: 1.3541666666666667vw;
          float: left;
        }
        .MbtnContainer {
          display: flex;
          flex-direction: column;
          float: left;
          row-gap: 25px;
          margin-bottom: 80px;
        }
        .phoneImageContainer {
          width: 7.903645833333333vw;
          margin-top: 4.729468599033816vh;
        }
      }
      .innerRightContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .mtextHeader {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .rightScreenContainer {
          width: 100%;
          height: 309px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .overlay {
      display: none;
    }
  }
}
