.container {
  margin-bottom: 14.84375vw;
  margin-top: 4.8687499999999995vw;

  .subContainer {
    display: flex;
    flex-direction: column;
    row-gap: 5.208333333333333vw;
  }
}
