.Card {
  display: flex;
  //   justify-content: center;
  position: relative;
  width: 20.833333333333332vw;
  height: 26.145833333333332vw;
  //   background: linear-gradient(to top left, #243746 31.76%, rgba(36, 55, 70, 0) 64.19%);

  .itemSlider {
    // margin-right: 0.5208333333333334vw;
    height: 100%;
    border-radius: 10px;
    .overlay {
      position: absolute;
      background: linear-gradient(105.5deg, #243746 21.76%, rgba(36, 55, 70, 0) 64.19%);
      border-radius: 8px;
      transform: matrix(0, -1, -1, 0, 0, 0);
      z-index: 100000000;
    }
    .cardImage {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }
  .cardLogo {
    z-index: 100;
    position: absolute;
    top: 0;

    .cardLogoImage {
      width: 4.166666666666667vw;
      height: 4.166666666666667vw;
    }
  }
  .textContainer {
    position: absolute;
    z-index: 10;
    bottom: 5%;
    display: flex;
    flex-direction: column;
    row-gap: 1.0416666666666667vw;
    padding: 1.5625vw;
    .cardTitle {
      font-family: Gilroy-Medium;
      width: 15vw;
      font-size: 1.5625vw;
      line-height: 1.9275362318840579vw;
      color: #ffffff;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .cardSubTitle {
      font-family: Gilroy-Light;
      width: 8.125vw;

      font-size: 1.0785416666666665vw;
      line-height: 1.2850241545893721vw;
      color: #ffffff;
      text-shadow: 0px 0px 2.76106px rgba(0, 0, 0, 0.25);
    }
  }
}

@media (max-width: 900px) {
  .Card {
    display: flex;
    //   justify-content: center;
    position: relative;
    width: 149.36px;
    height: 264px;
    //   background: linear-gradient(to top left, #243746 31.76%, rgba(36, 55, 70, 0) 64.19%);

    .itemSlider {
      // margin-right: 0.5208333333333334vw;
      height: 100%;
      border-radius: 10px;
      .overlay {
        position: absolute;
        background: linear-gradient(105.5deg, #243746 21.76%, rgba(36, 55, 70, 0) 64.19%);
        border-radius: 8px;
        transform: matrix(0, -1, -1, 0, 0, 0);
        z-index: 100000;
      }
      .cardImage {
        border-radius: 2.5px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cardLogo {
      z-index: 100;
      position: absolute;
      top: 0;
      width: 40px;
      height: 40px;

      .cardLogoImage {
        width: 100%;
        height: 100%;
      }
    }
    .textContainer {
      position: absolute;
      z-index: 10;
      bottom: 25px;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 8px;
      .cardTitle {
        font-family: Gilroy-Medium;
        font-style: normal;
        font-size: 15.7769px;
        line-height: 19px;
        width: 113px;
        color: #ffffff;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      }

      .cardSubTitle {
        font-family: Gilroy-Light;
        width: 100px;

        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 100%;
        color: #ffffff;
        text-shadow: 0px 0px 2.76106px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
