.mainContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3.3854166666666665vw;
  padding-top: 5.572916666666667vw;
  padding-bottom: 6.9vw;
  .heading {
    display: flex;
    width: 58.28125vw;
    // height: 99px;

    font-family: Gilroy-Bold;
    font-weight: 100;
    font-size: 2.34375vw;
    line-height: 2.8125vw;
    text-align: center;

    color: #243746;
  }

  .subText {
    width: 35.572916666666664vw;
    // height: 88px;

    font-family: Gilroy-Light;
    font-style: normal;
    // font-weight: normal;
    font-size: 1.25vw;
    line-height: 1.4583333333333333vw;
    text-align: center;

    color: #4d4d4d;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 55px;
    margin-top: 59px;
    margin-bottom: 67px;
    .heading {
      display: flex;
      width: 69.066666vw;
      height: 100%;

      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: 100;
      font-size: 30px;
      line-height: 36px;
      text-align: center;

      color: #243746;
    }

    .subText {
      width: 75.2vw;
      height: 100%;
      // height: 88px;

      font-family: Gilroy-Light;
      font-style: normal;
      // font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      text-align: center;

      color: #4d4d4d;
    }
  }
}
