$sizeChangeTransition: 0.7s;

.container {
  height: 100%;
  width: 100%;
  position: relative;
  margin-bottom: 100px;
  .sectionTitle {
    font-family: Gilroy-medium;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5625vw;
    line-height: 1.820255474452555vw;
    text-align: left;
    color: #243746;
    margin-bottom: 2.8315085158150857vw;
  }

  .sliderWidth {
    width: 100%;
    display: flex;
    justify-content: center;

    .sliderItemContainer {
      position: relative;
      outline: 0 !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;

      display: table !important;
      margin: 0 auto;

      .itemSlider {
        margin-right: 0.5208333333333334vw;

        width: 14.3296875vw;
        height: 24.4640625vw;
        border-radius: 10px;

        float: left;
        position: relative;
        transition: height 0.5s;
        -webkit-transition: height 0.5s;
        overflow: hidden;
        transition: $sizeChangeTransition ease-in-out;
        z-index: 100;
        margin: 0 15px 0 15px;

        .cardImage {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
        video {
          height: 100%;
          border-radius: 10px !important;
          background-color: #243746;
        }
      }

      .itemSlider:hover {
        width: 195%;
        z-index: 1000;
        transition: $sizeChangeTransition;
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sectionTitle {
      font-family: Gilroy-medium;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 29px;
      text-align: center;
      color: #243746;
      margin-bottom: 6.7vw;
      margin-top: 34px;
    }

    .sliderWidth {
      width: 780px;
      display: flex;
      justify-content: center;
      .sliderItemContainer {
        position: relative;
        // width: unset !important;
        .itemSlider {
          margin-right: 0.5208333333333334vw;
          width: 149px;
          height: 263px;

          border-radius: 10px;

          float: left;
          position: relative;
          transition: height 0.5s;
          -webkit-transition: height 0.5s;
          overflow: hidden;
          transition: $sizeChangeTransition;
          margin: auto;
          .cardImage {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
          video {
            height: 100%;
            border-radius: 10px !important;
            background-color: #243746;
          }
        }

        .itemSlider:hover {
          width: 200%;
          z-index: 1000;
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sectionTitle {
      font-family: Gilroy-medium;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #243746;
      margin-bottom: 6.7vw;
      margin-top: 34px;
    }

    .sliderWidth {
      width: 570px;
      display: flex;
      justify-content: center;
      .sliderItemContainer {
        position: relative;
        // width: unset !important;
        .itemSlider {
          margin-right: 0.5208333333333334vw;
          width: 33.3296875vw;
          height: 57.7vw;

          border-radius: 10px;

          float: left;
          position: relative;
          transition: height 0.5s;
          -webkit-transition: height 0.5s;
          overflow: hidden;
          transition: $sizeChangeTransition;

          .cardImage {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
          video {
            height: 100%;
            border-radius: 10px !important;
            background-color: #243746;
          }
        }

        .itemSlider:hover {
          width: 200%;
          z-index: 1000;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sectionTitle {
      font-family: Gilroy-medium;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #243746;
      margin-bottom: 6.7vw;
      margin-top: 34px;
    }

    .sliderWidth {
      width: 375px;
      display: flex;
      justify-content: center;
      .sliderItemContainer {
        position: relative;
        // width: unset !important;
        .itemSlider {
          margin-right: 0.5208333333333334vw;
          width: 33.3296875vw;
          height: 57.7vw;

          border-radius: 10px;

          float: left;
          position: relative;
          transition: height 0.5s;
          -webkit-transition: height 0.5s;
          overflow: hidden;
          transition: $sizeChangeTransition;

          .cardImage {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
          video {
            height: 100%;
            border-radius: 10px !important;
            background-color: #243746;
          }
        }

        .itemSlider:hover {
          width: 200%;
          z-index: 1000;
        }
      }
    }
  }
}
// ------------------------------------------------------------------------------

// for new slider
//  ==============================================================
.container {
  height: 100%;
  width: 100%;
  position: relative;

  .sectionTitle {
    font-family: Gilroy-medium;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5625vw;
    line-height: 1.820255474452555vw;
    text-align: center;
    color: #243746;
    margin-bottom: 2.8315085158150857vw;
  }

  .sliderItemContainer {
    display: flex;
    justify-content: center;
    .swiperSliderContainer {
      max-width: 70%;
      position: relative;
      .itemSlider {
        width: 16.3296875vw;
        height: 28.7vw;
        border-radius: 10px;
        position: relative;
        transition: height 0.5s;
        -webkit-transition: height 0.5s;
        overflow: hidden;
        transition: 1.5s ease-in-out;
        z-index: 100;

        .cardImage {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
        video {
          height: 100%;
          border-radius: 10px !important;
          background-color: #243746;
        }
      }

      .itemSlider:hover {
        width: 200%;
        z-index: 1000;
        transition: 0.5s;
      }
    }
    .swiperSliderContainer3 {
      max-width: 51%;
      position: relative;
      .itemSlider {
        width: 16.3296875vw;
        height: 28.7vw;
        border-radius: 10px;
        position: relative;
        transition: height 0.5s;
        -webkit-transition: height 0.5s;
        overflow: hidden;
        transition: 1.5s ease-in-out;
        z-index: 100;

        .cardImage {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
        video {
          height: 100%;
          border-radius: 10px !important;
          background-color: #243746;
        }
      }

      .itemSlider:hover {
        width: 200%;
        z-index: 1000;
        transition: 0.5s;
      }
    }

    .swiperSliderContainer2 {
      max-width: 36.5%;
      position: relative;
      .itemSlider {
        width: 16.3296875vw;
        height: 28.7vw;
        border-radius: 10px;
        position: relative;
        transition: height 0.5s;
        -webkit-transition: height 0.5s;
        overflow: hidden;
        transition: 1.5s ease-in-out;
        z-index: 100;

        .cardImage {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
        video {
          height: 100%;
          border-radius: 10px !important;
          background-color: #243746;
        }
      }

      .itemSlider:hover {
        width: 200%;
        z-index: 1000;
        transition: 0.5s;
      }
    }
    .sliderFor1 {
      width: 17%;
      position: relative;
      transition: 1.5s ease-in-out;
      // max-width: 17%;

      .itemSlider {
        width: 16.3296875vw;
        height: 28.7vw;
        border-radius: 10px;
        position: relative;
        transition: height 0.5s;
        -webkit-transition: height 0.5s;
        overflow: hidden;
        transition: 1.5s ease-in-out;
        z-index: 100;

        .cardImage {
          border-radius: 8px;
          width: 100%;
          height: 100%;
        }
        video {
          height: 100%;
          border-radius: 10px !important;
          background-color: #243746;
        }
      }
    }
  }
  // }
}

// ===============================================================

@media (max-width: 900px) {
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sectionTitle {
      font-family: Gilroy-medium;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 29px;
      text-align: center;
      color: #243746;
      margin-bottom: 6.7vw;
      margin-top: 34px;
    }
    .sliderItemContainer {
      .swiperSliderContainer {
        max-width: 63%;
        .itemSlider {
          margin-right: 0.5208333333333334vw;
          // width: 165px;
          // height: 263px;
          width: 20.3296875vw;
          height: 32.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
      .swiperSliderContainer3 {
        max-width: 80%;
        .itemSlider {
          margin-right: 0.5208333333333334vw;

          width: 25.3296875vw;
          height: 42.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
    }
  }
}

@media (max-width: 740px) {
  .container {
    .sectionTitle {
    }
    .sliderItemContainer {
      .swiperSliderContainer {
        max-width: 80%;
        .itemSlider {
          margin-right: 0.5208333333333334vw;
          // width: 165px;
          // height: 263px;
          width: 25.3296875vw;
          height: 47.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
          width: 190%;
          z-index: 1000;
        }
      }
      .swiperSliderContainer3 {
        max-width: 80%;
        .itemSlider {
          margin-right: 0.5208333333333334vw;
          // width: 165px;
          // height: 263px;
          width: 25.3296875vw;
          height: 47.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
          width: 190%;
          z-index: 1000;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .container {
    .sectionTitle {
    }
    .sliderItemContainer {
      .swiperSliderContainer {
        max-width: 70%;

        .itemSlider {
          margin-right: 0.5208333333333334vw;
          width: 33.3296875vw;
          height: 57.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
      .swiperSliderContainer3 {
        max-width: 70%;

        .itemSlider {
          margin-right: 0.5208333333333334vw;
          width: 33.3296875vw;
          height: 57.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
      .swiperSliderContainer2 {
        max-width: 70%;

        .itemSlider {
          margin-right: 0.5208333333333334vw;
          width: 33.3296875vw;
          height: 57.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .container {
    .sectionTitle {
    }
    .sliderItemContainer {
      .swiperSliderContainer {
        max-width: 85%;

        .itemSlider {
          margin-right: 0.5208333333333334vw;

          width: 41.3296875vw;
          height: 67.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
      .swiperSliderContainer3 {
        max-width: 85%;

        .itemSlider {
          margin-right: 0.5208333333333334vw;

          width: 41.3296875vw;
          height: 67.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
      .swiperSliderContainer2 {
        max-width: 85%;

        .itemSlider {
          margin-right: 0.5208333333333334vw;

          width: 41.3296875vw;
          height: 67.7vw;

          .cardImage {
          }
          video {
          }
        }

        .itemSlider:hover {
        }
      }
    }
  }
}
// for new slider
//  ======================================================
@media (max-width: 740px) {
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .sectionTitle {
      font-family: Gilroy-medium;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 29px;
      text-align: center;
      color: #243746;
      margin-bottom: 6.7vw;
      margin-top: 34px;
    }

    .sliderWidth {
      width: 570px;
      display: flex;
      justify-content: center;
      .sliderItemContainer {
        position: relative;
        // width: unset !important;
        .itemSlider {
          margin-right: 0.5208333333333334vw;
          width: 149px;
          height: 263px;

          border-radius: 10px;

          float: left;
          position: relative;
          transition: height 0.5s;
          -webkit-transition: height 0.5s;
          overflow: hidden;
          transition: 1.5s;

          .cardImage {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
          video {
            height: 100%;
            border-radius: 10px !important;
            background-color: #243746;
          }
        }

        .itemSlider:hover {
          width: 190%;
          z-index: 1000;
        }
      }
    }
  }
}
