.hoverContainer {
  position: relative;
  height: 100%;
  width: 100%;
  .container {
    position: relative;
    height: 100%;
    width: 100%;
    img {
      cursor: pointer;
      position: absolute;
      bottom: 20px;
      height: 30px;
      width: 30px;
    }
  }
}
.fullScreenIcon {
  right: 60px;
}
.muteIcon {
  right: 20px;
}
