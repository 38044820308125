.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .imageContainer {
    width: 100%;
    height: 48.958333333333336vw;
    video {
      background-color: #243746;
      width: 100% !important;
    }

    .hoverPlayerClass {
      width: 100%;
      height: 100%;
      display: flex !important;
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.7708333333333333vw;
    width: 100%;
    height: 100%;
    padding: 4.635416666666667vw 0px;
    background: #243746;

    .Text {
      height: 100%;

      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: bolder;
      font-size: 1.5625vw;
      line-height: 1.25vw;
      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
    }
    .btnContainer {
    }
    .MbtnContainer {
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .imageContainer {
      width: 100%;
      height: 59.3333vw;

      video {
        background-color: #243746;
        border-radius: 0px;
        width: 100% !important;
      }

      .hoverPlayerClass {
        width: 100%;
        height: 100%;
        display: flex !important;
      }
    }

    .bottomContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 23px;
      width: 100%;
      height: 100%;
      padding: 34px 0px 48px 0px;

      background: #243746;

      .Text {
        height: 100%;
        font-family: Gilroy-Medium;
        font-size: 24px;
        line-height: 24px;
        text-align: center;

        color: #ffffff;
      }
      .btnContainer {
        display: none;
      }

      .MbtnContainer {
        display: flex;
      }
    }
  }
}
