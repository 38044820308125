.container {
  display: flex;
  justify-content: center;

  padding-bottom: 160px;

  @media (max-width: 980px) {
    padding-bottom: 80px;
  }
  .subcontainer {
    width: 100%;
    max-width: 940px;
    @media (max-width: 980px) {
      width: 90%;
    }

    .imageContainer {
      width: 100%;
      // height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .textContainer {
      margin-top: -10px;
      background: #ced1dd;
      border-radius: 0px 0px 20px 20px;
      display: grid;
      grid-template-columns: 1fr 1.3fr;
      padding: 52px 80px 79px 94px;
      @media (max-width: 1480px) {
        padding: 52px 60px 79px 74px;
      }
      @media (max-width: 650px) {
        padding: 21px 35px 40px 35px;
      }
      @media (max-width: 1050px) {
        grid-template-columns: repeat(1, 1fr);
        height: 209px;
        // margin-top: 30px;
      }
      .column1 {
        width: 100%;
        height: 100%;
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 700;
        // font-size: 60px;
        // line-height: 110%;
        font-size: 2.5em;
        line-height: 44px;
        color: #000000;
        width: 339px;
        display: flex;
        // justify-content: center;
        // align-items: center;
        // @media (max-width: 1350px) {
        //   font-size: 50px;
        // }
        // @media (max-width: 1250px) {
        //   font-size: 40px;
        //   width: 339px;
        // }
        @media (max-width: 1050px) {
          // font-size: 30px;
          width: 100%;

          // width: 80%;
        }
        @media (max-width: 650px) {
          font-size: 24px;
          line-height: 28px;

          // width: 80%;
        }
      }
      .column2 {
        width: 100%;
        height: 100%;
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 500;
        // font-size: 30px;
        // line-height: 110%;
        font-size: 1.2em;
        line-height: 22px;
        color: #000000;
        padding-top: 30px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // @media (max-width: 1450px) {
        //   font-size: 30px;
        //   // padding-top: 0px;
        // }
        // @media (max-width: 1350px) {
        //   font-size: 30px;
        //   padding-top: 0px;
        // }
        // @media (max-width: 1250px) {
        //   font-size: 25px;
        // }
        @media (max-width: 1050px) {
          // font-size: 20px;
          width: 100%;
        }
        @media (max-width: 980px) {
          // font-size: 20px;
          font-size: 1em;
          line-height: 18px;
        }
        @media (max-width: 650px) {
          padding-top: 0px;

          // font-size: 18px;
          // width: 80%;
          align-items: flex-start;
        }
      }
    }
  }
}
