.mainContainer {
  background-color: #243746;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  height: 36.458333333333336vw;

  .leftContainer {
    display: flex;
    flex-direction: column;
    width: 37vw;
    // height: 100%;
    align-items: center;
    justify-content: center;
    // row-gap:11.458333333333334vw;
    // text-align: start;
    // margin-top: 183px;

    .textContainer {
      width: 21.145833333333332vw;
      // height: 19.736842105263158vh;

      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: bold;
      font-size: 3.096276041666667vw;
      line-height: 3.75vw;
      font-weight: 100;
      text-transform: uppercase;
      color: #ffff;
    }

    .leftMiddle {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2.6041666666666665vw 0px;
      column-gap: 2.6041666666666665vw;
      margin-right: 2.6041666666666665vw;

      .middleImage {
        width: 3.75vw;
        height: 5vw;
        background: #243746;

        img {
          height: 100%;
          width: 100%;
          // width: 116px;
          // height: 94px;
        }
      }
      .middleText {
        width: 12.239583333333334vw;
        height: 100%;

        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: 100;
        font-size: 1.1416666666666667vw;
        line-height: 1.25vw;
        /* or 120% */

        display: flex;
        align-items: center;

        color: #f6f7fb;
      }
    }
    .btnContainer {
      margin-top: 14.473684210526315vh;
    }
  }

  .GradientContainer {
    position: relative;
    // width:100%;
    .rightContainer {
      height: 100%;
      width: 63vw;
      // background-color: red;
    }
    .gradient {
      top: 0;
      background: linear-gradient(
        163.93deg,
        #243746 20.3%,
        rgba(36, 55, 70, 0) 63.64%,
        #243746 88.77%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
      position: absolute;
      height: 100%;
      width: 100%;
      // background-color: red;
    }

    .image {
      height: 100%;
      width: 100%;
    }
  }
  .MbtnContainer {
    display: none;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    height: 80vh;
    flex-direction: column;
    padding-top: 30px;

    .leftContainer {
      justify-content: unset;
      width: 100%;
      height: 306px;
      padding-left: 36px;
      padding-top: 59px;
      box-sizing: border-box;
      align-items: flex-start;
      margin-top: 0px;

      .textContainer {
        width: 59vw;
        font-family: Gilroy-Bold;
        font-size: 30px;
        line-height: 35px;
        text-transform: uppercase;
        color: #ffffff;
      }

      .leftMiddle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0px;
        column-gap: 20px;

        .middleImage {
          width: 59.54px;
          height: 79px;
          background: #243746;

          img {
            height: 100%;
            width: 100%;
            // width: 116px;
            // height: 94px;
          }
        }
        .middleText {
          width: 53vw;
          height: 100%;

          font-family: Gilroy-Light;
          font-style: normal;
          font-weight: 100;
          font-size: 18px;
          line-height: 24px;
          /* or 120% */

          display: flex;
          align-items: center;

          color: #f6f7fb;
        }
      }
      .btnContainer {
        display: none;
      }
    }

    .GradientContainer {
      position: relative;
      .rightContainer {
        width: 100%;
        height: 100%;
        // background-color: red;
      }
      .gradient {
        top: 0;
        background: linear-gradient(
          163.93deg,
          #243746 20.3%,
          rgba(36, 55, 70, 0) 63.64%,
          #243746 88.77%
        );
        mix-blend-mode: multiply;
        transform: matrix(1, 0, 0, -1, 0, 0);
        position: absolute;
        height: 100%;
        width: 100%;
      }

      .image {
        height: 100%;
        width: 100%;
      }
    }
    .MbtnContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 31px 0px;
    }
  }
}
