.container {
  background: #050c13;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  @media (max-width: 998px) {
    // height: 100%;
    // padding-top: 50px;
    // padding-bottom: 50px;
    height: 100vh;
  }
  .subcontainer {
    background: #141d24;
    border-radius: 20px;
    max-width: 940px;
    padding: 55px 70px 0px 70px;
    box-sizing: border-box;
    @media (max-width: 998px) {
      background: #050c13;
      padding: 0px;
      width: 90%;
    }

    h3 {
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 2.5em;
      line-height: 100%;
      color: #47d7ac;
      margin-bottom: 20px;
      // @media (max-width: 1050px) {
      //   font-size: 50px;
      // }
      @media (max-width: 998px) {
        margin-bottom: 10px;
      }

      @media (max-width: 875px) {
        font-size: 45px;
      }
      @media (max-width: 775px) {
        font-size: 40px;
      }
      @media (max-width: 675px) {
        font-size: 35px;
      }
      @media (max-width: 575px) {
        font-size: 30px;
        line-height: 44px;
      }
    }
    p {
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 2.5em;
      line-height: 120%;
      color: #ffffff;
      // @media (max-width: 1050px) {
      //   font-size: 50px;
      // }

      @media (max-width: 875px) {
        font-size: 45px;
      }
      @media (max-width: 775px) {
        font-size: 40px;
      }
      @media (max-width: 675px) {
        font-size: 35px;
      }
      @media (max-width: 575px) {
        font-size: 30px;
        line-height: 44px;
      }
    }
    .imageContainer {
      margin-top: 80px;
      display: flex;
      align-items: center;
      @media (max-width: 998px) {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .image1 {
        width: 200px;
      }
      .image2 {
        width: 300px;
        margin-left: 180px;
        @media (max-width: 998px) {
          display: none;
        }
      }
    }
  }
}
