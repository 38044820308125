.container {
  .dropdown {
    position: relative;
    display: inline-block;
    .DropdownContainer {
      display: flex;
      align-items: center;
      column-gap: 0px;
      overflow: hidden;
    }
    .btn {
      display: flex;
      font-family: 'Gilroy-Regular';
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #243746;
      width: 16.614583333333334vw;
      padding: 15px;
      box-sizing: border-box;

      @media (max-width: 1200px) {
        font-size: 18px;
        width: 20.614583333333334vw;
        padding: 10px 25px;
      }
    }
    .btnShow {
      padding: 10px;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #243746;

      @media (max-width: 1200px) {
        // font-size: 18px;
      }
      @media (max-width: 1150px) {
        font-size: 16px;
        padding: 10px 15px;
      }
      @media (max-width: 1100px) {
        font-size: 16px;
      }
    }
    .dropdownContent {
      background: #f6f7fb;
      display: none;
      row-gap: 0.5208333333333334vw;
      left: -50%;
      position: absolute;
      padding-top: 0.78125vw;
      padding-bottom: 0.78125vw;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: 0px 0px 30px 30px;
      overflow: hidden;
    }
    .SubLinkContainer {
      display: flex;
      width: 16.614583333333334vw;
      @media (max-width: 1200px) {
        width: 20.614583333333334vw;
      }
    }
    .SubLinkContainer:hover {
      background: #e4e6ed;
    }
    .SubLinkContainer:hover .btn {
      font-family: 'Gilroy-Bold';
      font-style: normal;
      font-weight: 700;
    }
  }
  .dropdown:hover .dropdownContent {
    display: flex;
    flex-direction: column;
  }
  .dropdown:hover .DropdownContainer {
    background: #3b6cb3;
    border-radius: 20px;
    color: #fff;
  }
  .dropdown:hover .DropdownContainer .btnShow {
    color: #fff;
  }
}
