.tab {
  width: 180px !important;
  // padding: 20px 0px;
  background: #f5f5f5;
  border-radius: 20px;
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 50px !important;
  color: #c4c4c4;
}

.activeTab {
  width: 180px !important;
  height: 50px !important;
  background: #47d7ac;
  border-radius: 20px;
  text-align: center;
  font-family: Gilroy-Bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  color: #000000;
}
