.main {
  display: flex;
  align-items: center;
  column-gap: 5.277777777777778vw;
  margin-bottom: 6.458333333333333vw;
  margin-top: 6.458333333333333vw;

  .imageContainer {
    width: 38.416666666666668vw;
    height: 28.916666666666668vw;
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  .rightContainer {
    max-width: 36.388888888888886vw;
    .chipContainer {
      margin-bottom: 1.0416666666666667vw;
    }
    .textSection {
      .title {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 1.6666666666666667vw;
        line-height: 2.7777777777777777vw;
        display: flex;
        align-items: center;
        color: #1b2830;
        margin-bottom: 0.3645833333333333vw;
      }
      .locationContainer {
        display: flex;
        align-items: center;
        margin-bottom: 1.9270833333333333vw;
        img {
          width: 1.1111111111111112vw;
          height: 1.1111111111111112vw;
          margin-right: 2px;
        }

        .locationText {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 400;
          font-size: 1.1111111111111112vw;
          line-height: 1.6666666666666667vw;
          display: flex;
          align-items: center;
          color: #243746;
        }
      }
      .Mbutton {
        display: none;
      }
      .Dbutton {
        display: block;
      }
      .textContainer {
        width: 36.388888888888886vw;
        height: 13.194444444444445vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.9722222222222222vw;

        text {
          font-family: 'Gilroy-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 1.1111111111111112vw;
          line-height: 1.6666666666666667vw;
          color: #5a6874;
          overflow-y: scroll;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .main {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 5.277777777777778vw;
    margin-bottom: 6.458333333333333vw;
    margin-top: 6.458333333333333vw;

    .imageContainer {
      width: 77.416666666666668vw;
      height: 58.916666666666668vw;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .rightContainer {
      max-width: 77.388888888888886vw;
      .chipContainer {
        margin-bottom: 5.0416666666666667vw;
      }
      .textSection {
        .title {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 600;
          font-size: 6.4vw;
          line-height: 7.7777777777777777vw;
          display: flex;
          align-items: center;
          color: #1b2830;
          margin-bottom: 2.8645833333333333vw;
        }
        .locationContainer {
          display: flex;
          align-items: center;
          margin-bottom: 1.9270833333333333vw;
          img {
            width: 5.1111111111111112vw;
            height: 5.1111111111111112vw;
            margin-right: 2px;
          }

          .locationText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 400;
            font-size: 4.2111111111111112vw;
            line-height: 6.4666666666666667vw;
            display: flex;
            align-items: center;
            color: #243746;
          }
        }
        .Dbutton {
          display: none;
        }
        .Mbutton {
          display: block;
        }
        .textContainer {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          row-gap: 0.9722222222222222vw;

          text {
            font-family: 'Gilroy-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 4.2111111111111112vw;
            line-height: 6.4666666666666667vw;
            color: #5a6874;
            overflow-y: scroll;
          }
        }
      }
    }
  }
}
