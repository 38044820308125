.mainContainer {
  display: flex;
  column-gap: 6.71875vw; // flex-direction: column;
  justify-content: center;
  padding: 5.46875vw 0px;
  .memberImageContainer {
    //   margin-right:139px;
  }

  .mobileContainer {
    display: none;
  }

  .memberDetails {
    width: 44.84375vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5625vw;

    .memberName {
      font-family: Gilroy-Bold;

      font-size: 2.0833333333333335vw;
      color: #243746;
      margin-bottom: 1.0416666666666667vw;
    }

    .memberText1 {
      font-family: Gilroy-Light;

      font-size: 1.3020833333333333vw;
      line-height: 1.8229166666666667vw;
      /* or 140% */
      text-align: left;
      color: #243746;
    }

    .memberQuote {
      font-family: Gilroy-Bold;

      font-size: 2.0833333333333335vw;
      line-height: 2.34375vw;
      /* or 112% */
      margin-top: 1.5625vw;
      margin-bottom: 1.5625vw;
      text-align: left;
      display: flex;
      align-items: center;

      color: #4fdcb5;
    }

    .memberText2 {
      font-family: Gilroy-Light;

      font-size: 1.3020833333333333vw;
      line-height: 1.8229166666666667vw;
      /* or 140% */
      text-align: left;

      color: #243746;
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    margin-top: 4.84375vw;
    width: 15%;
    .memberRole {
      font-family: Gilroy-Bold;
      font-size: 2.6041666666666665vw;
      line-height: 1.8229166666666667vw;
      /* or 70% */

      color: #243746;
      margin-bottom: 1.5625vw;
    }
    .socialIcons {
      display: flex;
      // flex-direction: column;
      column-gap: 1.5625vw;

      width: 2.03125vw;
      height: 2.03125vw;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    column-gap: 6.71875vw; // flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 50px 40px;
    align-items: center;

    .memberImageContainer {
      // display: flex;
      display: none;
      //   margin-right:139px;
    }

    .mobileContainer {
      display: flex;
      // justify-content: center;
      align-items: start;
      column-gap: 37px;
      width: 100%;

      .imageM {
      }

      .DetailsM {
        display: flex;
        flex-direction: column;

        .memberNameM {
          width: 92px;

          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: bolder;
          font-size: 24px;
          line-height: 24px;
          margin-bottom: 20px;
          /* or 100% */
          text-align: start;

          display: flex;
          align-items: center;

          color: #243746;
        }
        .memberRoleM {
          display: block;

          width: 67px;

          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: 800;
          font-size: 15.2273px;
          line-height: 11px;
          text-align: start;
          margin-bottom: 10px;

          /* or 70% */

          color: #243746;
        }

        .socialIconsM {
          display: flex;
          width: 11.88px;
          height: 11.88px;
          column-gap: 8px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .memberDetails {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-top: 38px;
      margin-bottom: 50px;

      .memberName {
        display: none;
      }

      .memberText1 {
        font-family: Gilroy-Light;
        text-align: start;
        font-size: 14px;
        line-height: 16px;
        /* or 140% */

        color: #243746;
      }

      .memberQuote {
        font-family: Gilroy-Bold;
        font-size: 14px;
        line-height: 17px;
        /* or 112% */
        margin-top: 1.5625vw;
        margin-bottom: 1.5625vw;

        display: flex;
        text-align: start;

        color: #4fdcb5;
      }

      .memberText2 {
        font-family: Gilroy-Light;

        font-size: 14px;
        line-height: 16px;
        /* or 140% */
        text-align: start;

        color: #243746;
      }
    }

    .rightContainer {
      display: none;
    }
  }
}
