.homeSliderImageContainer {
  position: relative;
}
.homeSliderImageTextContainer {
  position: absolute;
  bottom: 0%;
  left: 4vw;
}

#homeCoursel .imageSize {
  width: 160%;
  height: inherit;
}
.homeSliderImageText {
  animation: 2s bottom-top;
  animation-delay: 2s;
  font-family: Gilroy-Medium;
  font-style: normal;
  font-weight: bold;
  font-size: 1.875vw;
  line-height: 2.248792270531401vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  width: 20.833333333333332vw;
}
.homeSliderImageTextSubContainer {
  animation: 2s bottom-top;
  animation-delay: 2s;
  display: flex;
  align-items: center;
  margin-top: 3.319645732689211vw;
  margin-bottom: 5.729066022544283vw;
  justify-content: center;
  width: 18.229166666666668vw;
}

.heartIconStyles {
  height: 80px;
  width: 80px;
}

.subText {
  font-family: Gilroy-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 1.0416666666666667vw;
  line-height: 1.2850241545893721vw;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.swiper-button-next-btn {
  position: absolute;
  top: 50%;
  right: 17px;
  z-index: 1000 !important;
}

.swiper-button-prev-btn {
  position: absolute;
  top: 50%;
  left: 17px;
  z-index: 1000 !important;
}

#homeCoursel .swiper-container {
  margin: 0 !important;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  background: #243746;
  mix-blend-mode: multiply;
  padding: 50px 0px !important;
}

#homeCoursel .swiper-slide {
  transform: translate3d(5vw, 8.033333333333334vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  filter: grayscale(100%);
}

#homeCoursel .swiper-slide-active {
  transform: translate3d(-5vw, 5.4vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.23) !important;
  z-index: 70 !important;

  filter: none;
}

#homeCoursel .swiper-slide-prev {
  transform: translate3d(-1vw, 6.85708333333333335vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
  z-index: 20 !important;
}

#homeCoursel .swiper-slide-next {
  transform: translate3d(-8vw, 6.85708333333333335vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
  z-index: 60 !important;
}

#homeCoursel .swiper-slide-next + .swiper-slide {
  transform: translate3d(-15vw, 8.033333333333334vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
}

#homeCoursel {
  height: 44.270833333333336vw;
  overflow: hidden;
}
/*
#homeCoursel .swiper-slide:last-child img {
  margin-right: 0px;
}

.swiper-container{
  width: 100%;
}
.swiper-wrapper {
  width: 50%;
}
.swiper-slide {
  text-align: center;
  width: auto;
}
.slide-image {
  height: 400px;
  width: auto;
}
.my-gallery figure {
  margin: 0px;
} */

@media (max-width: 1600px) {
  #homeCoursel .swiper-container {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    background: #243746;
    mix-blend-mode: multiply;
    padding: 50px 0px !important;
  }

  #homeCoursel .swiper-slide {
    transform: translate3d(5vw, 8.93333333333334vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    filter: grayscale(100%);
  }

  #homeCoursel .swiper-slide-active {
    transform: translate3d(-5vw, 6.5vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.23) !important;
    z-index: 70 !important;

    filter: none;
  }

  #homeCoursel .swiper-slide-prev {
    transform: translate3d(-1vw, 7.85708333333333335vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 20 !important;
  }

  #homeCoursel .swiper-slide-next {
    transform: translate3d(-8vw, 7.85708333333333335vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 60 !important;
  }

  #homeCoursel .swiper-slide-next + .swiper-slide {
    transform: translate3d(-15vw, 8.93333333333334vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }

  #homeCoursel {
    height: 44.270833333333336vw;
    overflow: hidden;
  }
}

@media (max-width: 1300px) {
  #homeCoursel .swiper-container {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    background: #243746;
    mix-blend-mode: multiply;
    padding: 50px 0px !important;
  }

  #homeCoursel .swiper-slide {
    transform: translate3d(5vw, 9.8vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    filter: grayscale(100%);
  }

  #homeCoursel .swiper-slide-active {
    transform: translate3d(-5vw, 7.9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.23) !important;
    z-index: 70 !important;

    filter: none;
  }

  #homeCoursel .swiper-slide-prev {
    transform: translate3d(-1vw, 8.9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 20 !important;
  }

  #homeCoursel .swiper-slide-next {
    transform: translate3d(-8vw, 8.9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 60 !important;
  }

  #homeCoursel .swiper-slide-next + .swiper-slide {
    transform: translate3d(-15vw, 9.8vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }

  #homeCoursel {
    height: 44.270833333333336vw;
    overflow: hidden;
  }
}

@media (max-width: 1100px) {
  #homeCoursel .swiper-container {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    background: #243746;
    mix-blend-mode: multiply;
    padding: 50px 0px !important;
  }

  #homeCoursel .swiper-slide {
    transform: translate3d(8vw, 10.6vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    filter: grayscale(100%);
  }

  #homeCoursel .swiper-slide-active {
    transform: translate3d(-5vw, 9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.23) !important;
    z-index: 70 !important;

    filter: none;
  }

  #homeCoursel .swiper-slide-prev {
    transform: translate3d(-1vw, 9.9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 20 !important;
  }

  #homeCoursel .swiper-slide-next {
    transform: translate3d(-8vw, 9.9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 60 !important;
  }

  #homeCoursel .swiper-slide-next + .swiper-slide {
    transform: translate3d(-15vw, 10.6vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }

  #homeCoursel {
    height: 44.270833333333336vw;
    overflow: hidden;
  }
}

@media (max-width: 970px) {
 #homeCoursel .swiper-container {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    background: #243746;
    mix-blend-mode: multiply;
    padding: 50px 0px !important;
  }

  #homeCoursel .swiper-slide {
    transform: translate3d(8vw, 11.6vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    filter: grayscale(100%);
  }

  #homeCoursel .swiper-slide-active {
    transform: translate3d(-5vw, 10vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.23) !important;
    z-index: 70 !important;

    filter: none;
  }

  #homeCoursel .swiper-slide-prev {
    transform: translate3d(-1vw, 10.9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 20 !important;
  }

  #homeCoursel .swiper-slide-next {
    transform: translate3d(-8vw, 10.9vw, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 60 !important;
  }

  #homeCoursel .swiper-slide-next + .swiper-slide {
    transform: translate3d(-15vw, 11.6vw, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }

  #homeCoursel {
    height: 44.270833333333336vw;
    overflow: hidden;
  }
}

@media (max-width: 900px) {
  .swiper-button-next-btn {
    position: absolute;
    z-index: 1000 !important;
    right: 17px;
    top: 55%;
  }

  #homeCoursel .swiper-container {
    background-color: unset;
    padding-top: 0px !important;
  }

  #homeCoursel .swiper-container-3d {
    perspective: 1200px !important;
  }

  .swiper-button-prev-btn {
    position: absolute;
    z-index: 1000 !important;
    left: 17px;
    top: 55%;
  }

  #homeCoursel {
    height: auto;
    overflow: hidden;
  }

  #homeCoursel .imageSize {
    width: 140%;
    height: inherit;
  }

  #homeCoursel .swiper-slide {
    transform: translate3d(8vw, 8vh, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    filter: grayscale(100%);
  }

  #homeCoursel .swiper-slide-active {
    transform: translate3d(-5vw, 3vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.2) !important;
    z-index: 70 !important;

    filter: none;
  }

  #homeCoursel .swiper-slide-prev {
    transform: translate3d(2vw, 5vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 20 !important;
  }

  #homeCoursel .swiper-slide-next {
    transform: translate3d(-13vw, 5vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 60 !important;
  }
  #homeCoursel .swiper-arrow {
    font-size: 17px !important;
  }

  #homeCoursel .swiper-slide-next + .swiper-slide {
    transform: translate3d(-22vw, 8vh, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    z-index: 20 !important;
  }
}

@media (max-width: 640px) {
  #homeCoursel .swiper-slide {
    transform: translate3d(-10vw, 8vh, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    filter: grayscale(100%);
  }

  #homeCoursel {
    height: auto;
    overflow: hidden;
  }

  #homeCoursel .swiper-slide-active {
    transform: translate3d(-10vw, 3vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.2) !important;
    z-index: 70 !important;

    filter: none;
  }
  #homeCoursel .imageSize {
    width: 120%;
    height: inherit;
  }
  #homeCoursel .swiper-slide-prev {
    transform: translate3d(2vw, 5vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 20 !important;
  }

  #homeCoursel .swiper-slide-next {
    transform: translate3d(-20vw, 5vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 60 !important;
  }

  #homeCoursel .swiper-slide-next + #homeCoursel .swiper-slide {
    transform: translate3d(-10vw, 8vh, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }

  #homeCoursel .swiper-slide-next + .swiper-slide {
    transform: translate3d(-35vw, 8vh, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    z-index: 20 !important;
  }
}

@media (max-width: 480px) {
  #homeCoursel .swiper-slide {
    transform: translate3d(-20vw, 8vh, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
    filter: grayscale(100%);
  }

  #homeCoursel {
    height: auto;
    overflow: hidden;
  }

  #homeCoursel .swiper-slide-active {
    transform: translate3d(2vw, 3vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.2) !important;
    z-index: 70 !important;

    filter: none;
  }
  #homeCoursel .imageSize {
    width: 100%;
    height: inherit;
  }
  #homeCoursel .swiper-slide-prev {
    transform: translate3d(22vw, 5vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 20 !important;
  }

  #homeCoursel .swiper-slide-next {
    transform: translate3d(-22vw, 5vh, 0px) rotateX(0deg) rotateY(0deg) scale(1.1) !important;
    z-index: 60 !important;
  }

  #homeCoursel .swiper-slide-next + .swiper-slide {
    transform: translate3d(-5vw, 8vh, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important;
  }
}
