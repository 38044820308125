.mainText {
  font-family: Gilroy-Bold;
  font-size: 2.0833333333333335vw;
  line-height: 1.25vw;
  font-weight: lighter;
  /* or 60% */
  margin: 5.520833333333333vw 0px;

  color: #243746;
}

.sliderWidth {
  width: 70%;
}

.ArrowContainerRight {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  background: #243746;
  top: 30%;
  right: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 30%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  cursor: pointer;
}

.ArrowFont {
  color: #fff;
  font-size: 2.2625vw;
}

@media (max-width: 900px) {
  .mainText {
    width: 56vw;
    height: 100%;
    font-family: Gilroy-Bold;
    font-size: 24px;
    line-height: 29px;
    font-weight: lighter;

    text-align: center;
    /* or 60% */
    margin: 48px 0px;

    color: #243746;
  }
  .sliderWidth {
    width: 90%;
  }
  .ArrowContainerRight {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 15%;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowContainerLeft {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 15%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
  }

  .ArrowFont {
    color: #fff;
    font-size: 2.2625vw;
  }
}
