.homeSliderContainer {
  display: flex;
  height: 100%;
  width: 100%;
  .leftContainer {
    background: #243746;
    position: relative;
    width: 34vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .titleContainer {
      display: flex;
      flex-direction: column;
      row-gap: 1.71875vw;

      .title {
        font-family: Gilroy-Bold;
        font-size: 3.096276041666667vw;
        // line-height: 6.763285024154589vh;
        text-transform: uppercase;
        color: #ffffff;
        // width: 21.729166666666668vw;
        // height: 20.28985507246377vh;
      }
      .subTitle {
        font-family: Gilroy-Light;
        font-size: 1.25vw;
        line-height: 1.6646417069243156vw;
        // height: 210px;
        // font-weight:200 !important
        color: #ffffff;
        width: 19.6875vw;
        opacity: 0.8;
      }
      .btnContainer {
        margin-top: 0.4508828502415458vw;
        display: flex;
        flex-direction: column;
        row-gap: 1.8035314009661831vw;
      }
    }
  }
  .rightSliderContainer {
    width: 66vw;
    height: 100%;
    position: relative;
    z-index: 10;
    // background: #243746;
    .rightOverlay {
      background: linear-gradient(
        145.83deg,
        #243746 18.18%,
        rgba(36, 55, 70, 0) 66.8%,
        #243746 107.08%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      pointer-events: none;
    }
    .textContainer {
      position: absolute;
      bottom: 0;
      right: 21vw;
      z-index: 100;
      .btnContainer {
        display: none;
      }
      .text {
        animation: 2s bottom-top;
        animation-delay: 2s;
        font-family: Gilroy-Medium;
        font-style: normal;
        font-weight: bold;
        font-size: 1.875vw;
        line-height: 2.248792270531401vw;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        width: 20.833333333333332vw;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // height: 44px;
      }
      .subTextContainer {
        animation: 2s bottom-top;
        animation-delay: 2s;
        display: flex;
        align-items: center;
        margin-top: 3.319645732689211vw;
        margin-bottom: 5.729066022544283vw;
        justify-content: center;
        column-gap: 25px;
        width: 18.229166666666668vw;
        .heartIconStyles {
          height: 2.6041666666666665vw;
          width: 2.6041666666666665vw;
        }
        .subText {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 1.0416666666666667vw;
          line-height: 1.2850241545893721vw;

          display: flex;
          align-items: center;
          color: #ffffff;
          text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        }
      }
    }

    @keyframes bottom-top {
      from {
        margin-left: -500px;
      }

      to {
        margin-left: 0%;
      }
    }
  }

  .rightSubContainer {
    .rightOverlay {
      background: linear-gradient(
        145.83deg,
         #243746 18.18%,
         rgba(36,  55,  70,  0)  66.8%,
         #243746 107.08%
      );
      mix-blend-mode: multiply;
      transform: matrix(1,  0,  0,  -1,  0,  0);
      height: 100%;
      width: inherit;
      position: absolute;
    }
    .imageContainer {
      // height: 600px;
      // width: 800px;
      padding-top: 10%;
      background-color: #243746;
      .sliderImg {
        height: 100%;
        width: 100%;
      }
    }

    .textContainer {
      position: absolute;
      bottom: 0;
      right: 30%;
      z-index: -1;
      .btnContainer {
        display: none;
      }
      .text {
        font-family: Gilroy-Medium;
        font-style: normal;
        font-weight: bold;
        font-size: 1.875vw;
        line-height: 2.2916666666666665vw;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        width: 20.833333333333332vw;
      }
      .subTextContainer {
        display: flex;
        align-items: center;
        margin-top: 3.2291666666666665vw;
        margin-bottom: 5.572916666666667vw;
        width: 18.229166666666668vw;
        .heartIconStyles {
          height: 80px;
          width: 80px;
        }
        .subText {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 1.0416666666666667vw;
          line-height: 1.25vw;
          display: flex;
          align-items: center;
          color: #ffffff;
          text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
.tabContainer {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 10000;
}
.mobileTabs {
  display: none;
  margin-top: 20px;
  -webkit-tap-highlight-color: transparent;
}
.ArrowContainerRight {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  background: #ffffff;
  top: 45%;
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25));
}

.ArrowContainerLeft {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 45%;
  left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25));
}

@media (max-width: 900px) {
  .homeSliderContainer {
    display: block;
    height: 100%;
    background: #243746;
    padding-top: 50px;

    .leftContainer {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .titleContainer {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        padding-top: 31px;
        margin-left: 25px;
        .title {
          font-family: Gilroy-Bold;
          font-size: 30.5735px;
          line-height: 37px;
          text-transform: uppercase;
          color: #ffffff;
          width: 254.06px;
          height: 100%;
        }
        .subTitle {
          font-family: Gilroy-Light;
          font-size: 18.8571px;
          line-height: 22px;
          color: #ffffff;
          width: 80%;
          opacity: 0.8;
        }
        .btnContainer {
          display: none;
        }
      }
    }
    .rightSliderContainer {
      width: 100%;
      // height:100%;
      z-index: 10;

      .rightOverlay {
        // width:50%;
        overflow: hidden;

        background: linear-gradient(
          to top,
          rgba(36, 55, 70, 0) 39.58%,
          #243746 52.86%,
          #243746 100%
        );
        mix-blend-mode: normal;
        // transform: matrix(0, 1, 1, 0, 0, 0);
        z-index: 100;
        top: -3%;
      }

      .textContainer {
        position: relative;
        z-index: 100;
        right: 0;
        // background-color: #243746;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 50px 0px;
        padding-top: 20px;
        padding-bottom: 54px;
        margin-top: -30px;
        .btnContainer {
          padding-top: 34px;
          display: flex;
          flex-direction: column;
          row-gap: 23px;
          align-items: center;
        }
        .text {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          width: 200px;
          // height: 50px;
          // height :100%;
        }
        .subTextContainer {
          animation: 2s bottom-top;
          animation-delay: 2s;
          display: flex;
          align-items: center;
          margin-top: 3px;
          column-gap: 10px;
          margin-bottom: 0;
          justify-content: center;
          // height: 40px;
          width: 177px;
          .heartIconStyles {
            width: 23.13px;
            height: 23.13px;
          }
          .subText {
            font-family: Gilroy-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            color: #ffffff;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }

  .tabContainer {
    display: none;
  }
  .mobileTabs {
    display: block;
  }
}

@media (max-width: 640px) {
  .homeSliderContainer {
    display: block;
    height: 100%;
    background: #243746;

    .rightSliderContainer {
      width: 100%;
      // height:100%;
      z-index: 10;

      .rightOverlay {
        // width:50%;
        overflow: hidden;

        background: linear-gradient(
          to top,
          rgba(36, 55, 70, 0) 39.58%,
          #243746 52.86%,
          #243746 100%
        );
        mix-blend-mode: normal;
        // transform: matrix(0, 1, 1, 0, 0, 0);
        z-index: 100;
        top: -7%;
      }

      .textContainer {
        position: relative;
        z-index: 100;
        right: 0;
        // background-color: #243746;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 50px 0px;
        padding-top: 20px;
        padding-bottom: 54px;
        margin-top: 0px;
        .btnContainer {
          padding-top: 34px;
          display: flex;
          flex-direction: column;
          row-gap: 23px;
          align-items: center;
        }
        .text {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          width: 200px;
          // height: 50px;
          // height :100%;
        }
        .subTextContainer {
          animation: 2s bottom-top;
          animation-delay: 2s;
          display: flex;
          align-items: center;
          margin-top: 3px;
          column-gap: 10px;
          margin-bottom: 0;
          justify-content: center;
          // height: 40px;
          width: 177px;
          .heartIconStyles {
            width: 23.13px;
            height: 23.13px;
          }
          .subText {
            font-family: Gilroy-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            color: #ffffff;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }

  .tabContainer {
    display: none;
  }
  .mobileTabs {
    display: block;
  }
}

@media (max-width: 480px) {
  .homeSliderContainer {
    display: block;
    height: 100%;
    background: #243746;

    .rightSliderContainer {
      width: 100%;
      // height:100%;
      z-index: 10;

      .rightOverlay {
        // width:50%;
        overflow: hidden;

        background: linear-gradient(
          to top,
          rgba(36, 55, 70, 0) 39.58%,
          #243746 52.86%,
          #243746 100%
        );
        mix-blend-mode: normal;
        // transform: matrix(0, 1, 1, 0, 0, 0);
        z-index: 100;
        top: -7%;
      }

      .textContainer {
        position: relative;
        z-index: 100;
        right: 0;
        // background-color: #243746;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 50px 0px;
        padding-top: 20px;
        padding-bottom: 54px;
        margin-top: 0px;
        .btnContainer {
          padding-top: 34px;
          display: flex;
          flex-direction: column;
          row-gap: 23px;
          align-items: center;
        }
        .text {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          width: 200px;
          // height: 50px;
          // height :100%;
        }
        .subTextContainer {
          animation: 2s bottom-top;
          animation-delay: 2s;
          display: flex;
          align-items: center;
          margin-top: 3px;
          column-gap: 10px;
          margin-bottom: 0;
          justify-content: center;
          // height: 40px;
          width: 177px;
          .heartIconStyles {
            width: 23.13px;
            height: 23.13px;
          }
          .subText {
            font-family: Gilroy-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            color: #ffffff;
            text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }

  .tabContainer {
    display: none;
  }
  .mobileTabs {
    display: block;
  }
}
