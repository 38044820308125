.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  // padding-bottom: 64px;

  .subContainer {
    display: flex;
    height: 520px;
    width: 940px;
    max-width: 940px;
    background: #e4e6ed;
    border-radius: 20px 20px 20px 20px;

    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));

    .leftContainer {
      max-width: 640px;
      display: flex;
      align-items: center;
      border-radius: 20px 20px 20px 20px;
      video {
        border-radius: 0px 20px 20px 0px;
      }
      // padding: 20px;
    }
    .rightContainer {
      padding: 38px 0px 38px 38px;
      width: 300px;
      background: #3b6cb3;
      border-radius: 20px 0px 0px 20px;
      display: flex;
      flex-direction: column;

      .subTitle {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #fff;
        margin-bottom: 28px;
      }
      .title {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 110%;
        color: #fff;
        width: 217px;
        min-height: 138px;
        margin-bottom: 28px;
      }
      .description {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 19.2px;
        line-height: 110%;
        color: #fff;
        width: 207px;
        min-height: 168px;
      }
      .btnContainer {
        display: flex;
        align-items: center;
        // column-gap: 5px;
        width: 165px;
        height: 40px;
        justify-content: space-evenly;
        background: #fff;
        border-radius: 20px;

        .btnText {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 110%;
          color: #141d24;
          margin-left: 10px;
          margin-top: 2px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 64px;

    .subContainer {
      display: flex;
      height: 100%;
      width: 90%;
      max-width: 90%;
      background: #e4e6ed;
      border-radius: 20px 20px 20px 20px;

      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
      flex-direction: column-reverse;

      .leftContainer {
        max-width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: center;
        img {
          width: 100%;
        }
      }
      .rightContainer {
        padding: 38px 38px 38px 38px;
        box-sizing: border-box;
        width: 100%;
        background: #3b6cb3;
        border-radius: 0px 0px 20px 20px;
        display: flex;
        flex-direction: column;

        .subTitle {
          font-family: 'Gilroy-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          color: #fff;
          margin-bottom: 28px;
        }
        .title {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 110%;
          color: #fff;
          width: 100%;
          min-height: 100%;
          margin-bottom: 28px;
        }
        .description {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 19.2px;
          line-height: 110%;
          color: #fff;
          width: 100%;
          min-height: 100%;
          margin-bottom: 84px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: center;
          background: #fff;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 64px;

    .subContainer {
      display: flex;
      height: 100%;
      width: 90%;
      max-width: 90%;
      background: #e4e6ed;
      border-radius: 20px 20px 20px 20px;

      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
      flex-direction: column-reverse;

      .leftContainer {
        max-width: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: center;
      }
      .rightContainer {
        padding: 28px 28px 28px 28px;
        box-sizing: border-box;
        width: 100%;
        background: #3b6cb3;
        border-radius: 0px 0px 20px 20px;
        display: flex;
        flex-direction: column;

        .subTitle {
          font-family: 'Gilroy-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #fff;
          margin-bottom: 18px;
        }
        .title {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 110%;
          color: #fff;
          width: 100%;
          min-height: 100%;
          margin-bottom: 18px;
        }
        .description {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 16.2px;
          line-height: 110%;
          color: #fff;
          width: 100%;
          min-height: 100%;
          margin-bottom: 84px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: center;
          background: #fff;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
    }
  }
}
