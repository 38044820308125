.popOver {
  min-width: 20.677083333333332vw;
  background: white;

  transition: 0.5s ease;

  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  .container {
    display: flex;
    .imageContainer {
      width: 6.927083333333333vw;
      object-fit: contain;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .textContainer {
      padding: 10px;

      .title {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 1.25vw;

        color: #1b2830;
      }
      .locationContainer {
        display: flex;

        .locationIcon {
          width: 1.2625vw;
          height: 1.0416666666666667vw;

          margin-right: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .locationText {
          font-family: 'Gilroy-Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 0.8333333333333334vw;
          line-height: 15px;

          color: #243746;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .popOver {
    width: 75.677083333333332vw;
    background: white;
    min-height: 21.1vw;
    transition: 0.5s ease;

    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    border-radius: 5px;
    .container {
      display: flex;
      .imageContainer {
        width: 25.542vw;
        object-fit: contain;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .textContainer {
        padding: 10px;

        .title {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 600;
          font-size: 4.542845744680851vw;

          color: #1b2830;
          margin-bottom: 3.2952127659574466vw;
        }
        .locationContainer {
          display: flex;

          .locationIcon {
            width: 3.029255319148936vw;
            height: 3.029255319148936vw;

            margin-right: 5px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          .locationText {
            font-family: 'Gilroy-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 3.028563829787234vw;
            line-height: 15px;

            color: #243746;
          }
        }
      }
    }
  }
}
