.container {
  display: flex;
  justify-content: center;
  padding: 80px 0px;
    background: #142232;


  @media (max-width: 780px) {
    padding-bottom: 50px;
  }

  .subcontainer {
    position: relative;
    // width: 80%;
    // min-height: 300px;
    max-width: 940px;
    padding-bottom: 20px;

    @media (max-width: 980px) {
      width: 90%;
    }

background: #2D3F4E;
    border-radius: 20px;

    .slider {
      padding: 66px 56px;
      width: fit-content;
      box-sizing: border-box;
      @media (max-width: 560px) {
        padding: 36px 36px;
      }
      position: relative;

      .text {
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 400;
        // font-size: 60px;
        // line-height: 100%;
        font-size: 2em;
        line-height: 34px;
        color: #ffffff;
        min-height: 140px;

        // @media (max-width: 1580px) {
        //   font-size: 55px;
        // }
        // @media (max-width: 1480px) {
        //   font-size: 50px;
        // }
        // @media (max-width: 1380px) {
        //   font-size: 45px;
        // }
        // @media (max-width: 980px) {
        //   font-size: 35px;
        // }
        @media (max-width: 780px) {
          font-size: 30px;
          line-height: 38px;
          min-height: 180px;
        }
        @media (max-width: 580px) {
          font-size: 25px;
          line-height: 28px;
        }
      }
      .personDetails {
        position: absolute;
        bottom: 10px;
        right: 90px;
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 500;
        // font-size: 35px;
        // line-height: 110%;
        font-size: 1.5em;
        line-height: 34px;
        display: flex;
        align-items: center;

        color: #ffffff;
        @media (max-width: 980px) {
          font-size: 25px;

          bottom: 0px;
          left: 0px;
          margin-top: 20px;
        }
        @media (max-width: 980px) {
          position: relative;
          // font-size: 25px;
          bottom: 10px;
          // left: 60px;
        }
        @media (max-width: 780px) {
          font-size: 16px;
          bottom: -10px;
          left: 00px;
          margin-bottom: 20px;
        }
      }
    }
    .numberContainer {
      position: absolute;
      bottom: 32px;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 110%;
      text-align: center;
      color: #ffffff;
      left: 121px;
      @media (max-width: 780px) {
        bottom: 27px;
        left: 80px;
        font-size: 20px;
      }
    }
  }
}
.ArrowContainerLeft {
  position: absolute;
  bottom: 0px;
  left: 50px;
  z-index: 20;

  img {
    @media (max-width: 780px) {
      height: 30px;
      width: 30px;
    }
  }
  @media (max-width: 780px) {
    // bottom: -30px;
    left: 40px;
  }
}
.ArrowContainerRight {
  position: absolute;
  bottom: 0px;
  left: 120px;
  z-index: 20;
  img {
    @media (max-width: 780px) {
      height: 30px;
      width: 30px;
    }
  }
  @media (max-width: 780px) {
    // bottom: -30px;
    left: 80px;
  }
}
