.container {
  position: relative;
  .imageContainer {
    width: 63.854166666666664vw;
    height: 36.064583333333336vw;
    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;

      // object-fit: fill;
    }
  }
  .titleContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    .logoContainer {
      height: 70px;
      width: 70px;

      img {
        height: 100%;
        width: 100%;
      }
    }
    .title {
      width: 272px;

      font-family: Gilroy-Regular;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    }
  }
  .nameContainer {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: unset;
    height: 71px;
    text {
      font-family: Gilroy-Bold;
      font-size: 15.1485px;
      line-height: 18px;
      text-transform: uppercase;

      color: #ffffff;
    }
  }
}

@media (max-width: 900px) {
  .container {
    position: relative;
    width: 100%;
    height: 100%;
    .imageContainer {
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        border-radius: 0px;

        // object-fit: fill;
      }
    }
    .titleContainer {
      display: flex;
      align-items: center;
      position: absolute;
      top: 60%;
      right: 10%;
      .logoContainer {
        display: none;
      }
      .title {
        width: 140px;
        font-family: Gilroy-Regular;
        font-size: 7.57426px;
        line-height: 10px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .nameContainer {
      position: absolute;
      top: 40%;
      right: 10%;
      left: unset;
      width: 140px;
      height: 71px;
      text {
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 15.1485px;
        line-height: 18px;
        text-transform: uppercase;

        color: #ffffff;
      }
    }
  }
}
