.container {
  background: #050c13;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1050px) {
    height: 100%;
  }

  .subContainer {
    max-width: 940px;
    width: 100%;
    margin-top: 150px;
    margin-bottom: 100px;

    @media (max-width: 1050px) {
      margin-top: 200px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: left;
    }
    @media (max-width: 650px) {
      margin-top: 100px;
    }

    .textContainer {
      width: 90%;
      h2 {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 2.5em;
        line-height: 100%;

        color: #47d7ac;
        @media (max-width: 1050px) {
          font-size: 55px;
        }

        @media (max-width: 875px) {
          font-size: 50px;
        }
        @media (max-width: 775px) {
          font-size: 45px;
        }
        @media (max-width: 675px) {
          font-size: 40px;
        }
        @media (max-width: 575px) {
          font-size: 35px;
          line-height: 44px;
        }
      }
      p {
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 120%;
        color: #ffffff;
        margin-bottom: 20px;
        margin-top: 30px;
        width: 406px;
        @media (max-width: 1050px) {
          width: 100%;
        }
        @media (max-width: 650px) {
          font-size: 1em;
        }
      }
    }
    .topContainer {
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;

      @media (max-width: 1050px) {
        flex-direction: column-reverse;
        align-items: center;
        row-gap: 100px;
        justify-content: center;
        width: 90%;
      }

      .leftContainer {
        width: 400px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        background: #141d24;
        border-radius: 20px;
        padding: 40px 20px;
        text-align: center;
        @media (max-width: 1050px) {
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 70px;
        }

        .subTextContainer {
          // width: 616px;
          // height: 172px;

          background: #1b2830;
          border-radius: 20px;

          font-family: 'Gilroy-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 1em;
          line-height: 120%;

          display: flex;
          align-items: center;
          text-align: center;

          color: #ffffff;
          padding: 20px;
        }
      }
      .rightContainer {
        // align-items: end;
        @media (max-width: 1050px) {
          width: 100%;
        }

        label {
          font-family: 'Gilroy-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 1em;
          line-height: 120%;
          color: #e4e6ed;
          display: inline-block;
          margin-bottom: 5px;
        }

        input[type='text'] {
          box-sizing: border-box;
          width: 400px;
          height: 36px;
          background: #1b2830;
          border: 1px solid #1b2830;
          border-radius: 12px;

          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
          margin-bottom: 10px;
          color: #ffffff;
          padding: 0px 10px;
          @media (max-width: 1050px) {
            width: 100%;
          }
        }
        .btnContainer {
          text-align: -webkit-right;
          margin-top: 30px;
          input[type='submit'] {
            background: #47d7ac;
            border-radius: 40px;
            font-family: 'Gilroy-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 1em;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #243746;
            width: 159px;
            height: 46px;
            border: 0 none;
            @media (max-width: 1050px) {
              width: 100%;
            }
          }
        }
        ::placeholder {
          font-family: 'Gilroy-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 1.2em;
          line-height: 100%;
          display: flex;
          align-items: center;
          color: #5a6874;
          padding: 0px 10px;
        }
      }
    }
  }
}
