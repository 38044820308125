.container {
  margin-bottom: 100px;
  margin-top: 50px;

  .subContainer {
    display: flex;
    flex-direction: column;
    row-gap: 5.208333333333333vw;
  }
}
