.footerContainer {
  display: flex;
  padding-top: 6.216374695863747vw;
  padding-left: 7.1933333333333325vw;
  // column-gap: 250px;
  background-color: #142232;
  height: 21.459598540145983vw;

  .mainContainer {
    display: flex;
    .column1 {
      display: flex;
      flex-direction: column;
      row-gap: 1.4264229774939172vw;
      margin-left: 5.729166666666667vw;

      a {
        text-decoration: none;
        text-decoration-line: none;
      }

      a:hover {
        text-decoration: none;
        text-decoration-line: underline;
        text-decoration-color: white;
      }

      .socialIconContainer {
        display: flex;
        align-items: center;
        column-gap: 1.021921760948905vw;
      }

      .footerTradeMark {
        font-family: Gilroy-Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 0.7843229166666666vw;
        line-height: 1.021921760948905vw;
        color: #bdc2c7;
        margin-top: 3.8023114355231145vw;
      }

      .followusText {
        color: #ffff;
        font-family: Gilroy-Medium;
        font-size: 1.25vw;
        line-height: 1.021921760948905vw;
        margin-top: 0.7843229166666666vw;
      }
    }
    .column2 {
      display: flex;
      flex-direction: column;
      row-gap: 1.4264229774939172vw;
      margin-left: 9.375vw;

      a {
        text-decoration: none;
        text-decoration-line: none;
      }

      a:hover {
        text-decoration: none;
        text-decoration-line: underline;
        text-decoration-color: white;
      }

      .columnTitle {
        color: #ffff;
        font-family: Gilroy-Medium;
        font-size: 1.25vw;

        line-height: 1.2135036496350364vw;
      }
      .columnItemContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1.021921760948905vw;

        .columnItem {
          color: #ffff;
          font-family: Gilroy-Light;
          font-style: normal;
          font-weight: 500;
          font-size: 0.9375vw;
          line-height: 1.2135036496350364vw;

          // line-height: 1.8440693430656934vh;
        }
      }
    }
    .column3 {
      display: flex;
      flex-direction: column;
      row-gap: 1.4264229774939172vw;
      margin-left: 6.770833333333333vw;

      a {
        text-decoration: none;
        text-decoration-line: none;
      }

      a:hover {
        text-decoration: none;
        text-decoration-line: underline;
        text-decoration-color: white;
      }

      .columnTitle {
        color: #ffff;
        font-family: Gilroy-Medium;
        font-size: 1.25vw;
        line-height: 1.2135036496350364vw;
      }
      .columnItemContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1.021921760948905vw;

        .columnItem {
          color: #ffff;
          font-family: Gilroy-Light;
          font-style: normal;
          font-weight: 500;
          font-size: 0.9375vw;
          line-height: 1.2135036496350364vw;
        }
      }
    }
    .column4 {
      display: flex;
      flex-direction: column;
      row-gap: 1.4264229774939172vw;
      margin-left: 5.729166666666667vw;

      a {
        text-decoration: none;
        text-decoration-line: none;
      }

      a:hover {
        text-decoration: none;
        text-decoration-line: underline;
        text-decoration-color: white;
      }

      .columnTitle {
        color: #ffff;
        font-family: Gilroy-Medium;
        font-size: 1.25vw;

        line-height: 1.2135036496350364vw;
      }
      .columnItemContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1.021921760948905vw;

        .columnItem {
          color: #ffff;
          font-family: Gilroy-Light;
          font-style: normal;
          font-weight: 500;
          font-size: 0.9375vw;
          line-height: 1.2135036496350364vw;
        }
      }
    }
    .column5 {
      display: flex;
      flex-direction: column;
      row-gap: 1.4264229774939172vw;
      margin-left: 5.729166666666667vw;

      a {
        text-decoration: none;
        text-decoration-line: none;
      }

      a:hover {
        text-decoration: none;
        text-decoration-line: underline;
        text-decoration-color: white;
      }

      .columnTitle {
        color: #ffff;
        font-family: Gilroy-Medium;
        font-size: 1.25vw;
        line-height: 1.2135036496350364vw;
      }
      .columnItemContainer {
        display: flex;
        flex-direction: column;
        row-gap: 1.021921760948905vw;

        .appImageContainer {
          height: 2.500828771289538vw;
          width: 8.758854166666667vw;
        }
      }
    }
  }
}
.footerTradeMarkM {
  display: none;
}
.footerMContainer {
  display: none;
}
.footerTradeMarkM {
  display: none;
}

@media (max-width: 900px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 31px;
    padding-left: 0px;
    // column-gap: 250px;
    background-color: #142232;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    .mainContainer {
      display: flex;
      flex-direction: column;
      row-gap: 30px;

      width: 80%;
      .column1 {
        display: flex;
        flex-direction: column;
        row-gap: 19px;
        margin-left: 0px;

        a {
          text-decoration: none;
          text-decoration-line: none;
        }

        a:hover {
          text-decoration: none;
          text-decoration-line: underline;
          text-decoration-color: white;
        }

        .socialIconContainer {
          display: flex;
          align-items: center;
          column-gap: 15px;

          .socialIcon {
            width: 30px;
            height: 30px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .footerTradeMark {
          display: none;
        }

        .followusText {
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;

          color: #f9f6f2;
          margin-top: 44px;
        }
      }
      .column2 {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin-left: 0px;

        a {
          text-decoration: none;
          text-decoration-line: none;
        }

        a:hover {
          text-decoration: none;
          text-decoration-line: underline;
          text-decoration-color: white;
        }

        .columnTitle {
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          color: #f9f6f2;
        }
        .columnItemContainer {
          display: flex;
          flex-direction: row;
          column-gap: 91px;

          .columnItem {
            font-family: Gilroy-Light;
            font-size: 18px;
            line-height: 20px;
            /* identical to box height, or 111% */

            color: #ffffff;

            // line-height: 1.8440693430656934vh;
          }
        }
      }
      .column3 {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin-left: 0px;

        a {
          text-decoration: none;
          text-decoration-line: none;
        }

        a:hover {
          text-decoration: none;
          text-decoration-line: underline;
          text-decoration-color: white;
        }

        .columnTitle {
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;

          color: #f9f6f2;
        }
        .columnItemContainer {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          column-gap: 20px;
          row-gap: 15px;

          .columnItem {
            font-family: Gilroy-Light;
            font-size: 18px;
            line-height: 20px;
            width: 133px;
            /* or 111% */

            color: #ffffff;
          }
        }
      }
      .column4 {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin-left: 0px;

        a {
          text-decoration: none;
          text-decoration-line: none;
        }

        a:hover {
          text-decoration: none;
          text-decoration-line: underline;
          text-decoration-color: white;
        }

        .columnTitle {
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;
          /* identical to box height, or 120% */

          color: #f9f6f2;
        }
        .columnItemContainer {
          display: flex;
          flex-direction: row;
          row-gap: 15px;
          column-gap: 20px;

          .columnItem {
            font-family: Gilroy-Light;
            font-size: 18px;
            line-height: 20px;
            /* identical to box height, or 111% */

            color: #ffffff;
          }
        }
      }
      .column5 {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin-left: 0px;

        a {
          text-decoration: none;
          text-decoration-line: none;
        }

        a:hover {
          text-decoration: none;
          text-decoration-line: underline;
          text-decoration-color: white;
        }

        .columnTitle {
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 20px;
          line-height: 24px;

          color: #ffffff;
        }
        .columnItemContainer {
          display: flex;
          flex-direction: row;
          column-gap: 23px;

          .appImageContainer {
            display: none;
            height: 40px;
            width: 130px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .footerTradeMarkM {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 33px;

    font-family: Gilroy-Light;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 186% */

    color: #ffffff;

    .imageM {
      display: flex;
      column-gap: 23px;
      .appImageContainerM {
        display: flex;
        flex-direction: column;
        height: 40px;
        width: 130px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  // .footerMContainer {
  //   display: block;
  //   padding: 48px;
  //   background-color: #243746;
  //   // height: 58.724087591240874vh;
  //   .footerLogo {
  //     width: 100%;
  //     text-align: center;
  //   }
  //   .followusText {
  //     color: #ffff;
  //     font-family: Gilroy-Medium;
  //     font-size: 24px;
  //     margin-top: 73px;
  //     margin-bottom: 25px;
  //   }
  //   .socialIconContainer {
  //     display: flex;
  //     align-items: center;
  //     column-gap: 15px;
  //     margin-bottom: 72px;
  //   }
  //   .center {
  //     display: flex;
  //     justify-content: space-between;
  //     .leftCol {
  //       display: flex;
  //       flex-direction: column;
  //       row-gap: 40px;
  //       .column3 {
  //         a {
  //           text-decoration: none;
  //           text-decoration-line: none;
  //         }

  //         a:hover {
  //           text-decoration: none;
  //           text-decoration-line: underline;
  //           text-decoration-color: white;
  //         }

  //         .columnTitle {
  //           color: #ffff;
  //           font-family: Gilroy-Medium;
  //           font-size: 24px;
  //           margin-bottom: 24px;
  //         }
  //         .columnItemContainer {
  //           display: flex;
  //           flex-direction: column;
  //           row-gap: 24px;
  //           .columnItem {
  //             color: #ffff;
  //             font-family: Gilroy-Light;
  //             font-style: normal;
  //             font-weight: 500;
  //             font-size: 18px;
  //           }
  //         }
  //       }
  //     }
  //     .rightCol {
  //       a {
  //         text-decoration: none;
  //         text-decoration-line: none;
  //       }

  //       a:hover {
  //         text-decoration: none;
  //         text-decoration-line: underline;
  //         text-decoration-color: white;
  //       }

  //       .columnTitle {
  //         color: #ffff;
  //         font-family: Gilroy-Medium;
  //         font-size: 24px;
  //         margin-bottom: 24px;
  //       }
  //       .columnItemContainer {
  //         display: flex;
  //         flex-direction: column;
  //         row-gap: 1.8440693430656934vh;

  //         .columnItem {
  //           color: #ffff;
  //           font-family: Gilroy-Light;
  //           font-style: normal;
  //           font-weight: 500;
  //           font-size: 18px;
  //         }
  //       }
  //     }
  //   }
  //   .footerTradeMark {
  //     margin-top: 61px;
  //     font-family: Gilroy-Light;
  //     font-size: 18px;
  //     line-height: 26px;
  //     color: #ffff;
  //   }
  // }
}
