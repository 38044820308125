.container {
  // min-height: 100vh;
  background: #21303d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 190px;
  position: relative;
  @media (max-width: 880px) {
    padding-top: 100px;
    min-height: 100%;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 940px;
    justify-content: center;
    // row-gap:80px;
    .heading {
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 100%;

      color: #ffffff;
      max-width: 1300px;

      padding: 80px 0px;

      @media (max-width: 980px) {
        font-size: 30px;
        padding: 45px 0px;
      }
    }

    .heroImage {
      display: flex;
      justify-content: end;
      width: 100%;
      object-fit: contain;

      // padding-bottom: 55px;
      position: relative;
      z-index: 0;
      // min-height: 740px;
      // img:only-child {
      //   width: 100%;
      //   height: 100%;
      // }
      .hero {
        width: 100%;
        height: 100%;
      }
      @media (max-width: 980px) {
        min-height: 100%;
      }

      .pulseContainer {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 15;
      }
    }

    .videoContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 5.5%;
      // right: 0.4%;

      video {
        border-radius: 30px;
        width: 99%;
        height: 89%;
        object-fit: contain;
        // padding: 20px;
        z-index: 1;
      }
    }
    .videoEndImage {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      // padding: 20px;
      z-index: 1;
    }

    .navigationBar {
      // width: 940px;

      // height: 55px;
      padding: 17px 65px;
      border: 1px solid #bdc2c7;
      box-shadow: 0px 0px 28.2051px rgba(0, 0, 0, 0.25);
      border-radius: 28.2051px;
      .navigationItem {
        display: flex;
        column-gap: 100px;
        .itemContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 11px;
          // padding: 0px 50px;

          .iconText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;

            color: #fbfcfd;
          }
        }
      }
    }
    .textContainer {
      padding-top: 35px;
      color: #64ddb9;
      text-align: center;
      font-size: 20px;
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      @media (max-width: 980px) {
        font-size: 16px;
      }
    }
    .navBar {
      padding-top: 35px;
      padding-bottom: 140px;

      .navTab {
        display: flex;
        width: 100%;
        max-width: 940px;
        background-color: #fff;
        border-radius: 20px;
        height: 42px;
        .navIconsContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          &:hover {
            cursor: pointer;
          }

          .navIcons {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 26px;
            height: 26px;
            border-radius: 20px;
            background-color: #e4e6ed;

            .navImage {
              // width: 25px;
              // height: 26px;
              // border: 1px solid #e4e6ed;
              // background-color: #e4e6ed;
              border-radius: 20px;
            }
          }
        }
        .active {
          background: #64ddb9;
          .navIcons {
            background-color: #21303d;
          }

          &:first-child {
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
          }
          &:last-child {
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
          }
          .navImage {
            filter: invert(1);
            // opacity: 10;
          }
        }
      }
      .textTab {
        display: flex;
        width: 100%;
        max-width: 940px;

        height: 42px;
        margin-top: 15px;
        .navTextContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;

          .navText {
            color: #64ddb9;
            text-align: center;
            font-size: 16px;
            font-family: 'Gilroy-Regular';
            font-weight: 600;
            line-height: 110%;
            width: 144px;
            @media (max-width: 780px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
