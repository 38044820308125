.container {
  position: relative;
  .cardImage {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
  .cardLogo {
    z-index: 100;
    position: absolute;
    top: 5%;
    left: 5%;

    .cardLogoImage {
      height: 2.5393856447688568vw;
      width: 2.6458333333333335vw;
    }
  }
  .textContainer {
    position: absolute;
    z-index: 10;
    bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.0112530413625302vw;
    padding: 1.5625vw;
    .cardTitle {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-size: 1.5625vw;
      line-height: 1.9275362318840579vw;
      color: #ffffff;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .cardSubTitle {
      font-family: Gilroy-Regular;
      width: 8.125vw;

      font-style: normal;
      font-weight: normal;
      font-size: 1.0785416666666665vw;
      line-height: 1.2850241545893721vw;
      color: #ffffff;
      text-shadow: 0px 0px 2.76106px rgba(0, 0, 0, 0.25);
    }
  }
}

@media (max-width: 900px) {
  .container {
    height: 100%;
    .cardImage {
      border-radius: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .cardLogo {
      z-index: 100;
      position: absolute;
      top: 5%;
      width: 30px;
      height: 30px;
      .cardLogoImage {
        width: 100%;
        height: 100%;
      }
    }
    .textContainer {
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      row-gap: 1.8248175182481752vh;
      padding: 1.5625vw;
      .cardTitle {
        font-family: Gilroy-Medium;
        font-style: normal;
        font-size: 15.7171px;
        line-height: 19px;
        color: #ffffff;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .cardSubTitle {
        font-family: Gilroy-Regular;
        width: 8.125vw;

        font-style: normal;
        font-weight: normal;
        font-size: 10.849px;
        line-height: 13px;
        color: #ffffff;
        text-shadow: 0px 0px 2.76106px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
