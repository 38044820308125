.mainContainer {
  display: flex;
  background-color: #243746;

  .leftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37%;
    height: auto;
    .textContainer {
      display: flex;
      flex-direction: column;
      row-gap: 4.166666666666667vw;

      .heading {
        width: 23.125vw;
        height: 100%;

        font-family: Gilroy-Bold;
        font-size: 3.096276041666667vw;
        line-height: 3.6458333333333335vw;
        text-transform: uppercase;

        /* Neutral / 100 */

        color: #ffffff;
      }

      .btnContainer {
      }
    }
  }

  .rightContainer {
    width: 63%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .MbtnContainer {
    display: none;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    background-color: #243746;
    padding-top: 50px;

    .leftContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      .textContainer {
        display: flex;
        flex-direction: column;
        row-gap: 0px;

        .heading {
          width: 283px;
          height: 100%;
          padding-top: 59px;
          padding-bottom: 37px;
          font-family: Gilroy-Bold;
          font-size: 30px;
          line-height: 36px;
          text-transform: uppercase;

          /* Neutral / 100 */

          color: #ffffff;
        }

        .btnContainer {
          display: none;
        }
      }
    }

    .rightContainer {
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .MbtnContainer {
      display: flex;
      justify-content: center;
      margin-top: 31px;
      margin-bottom: 39px;
    }
  }
}
