.scroll_fx_video_heading {
  display: flex;
  width: 38.385416666666664vw;
  height: 100%;
  margin-left: 32.239583333333336vw;
  padding-bottom: 2.0833333333333335vw;
  font-family: Gilroy-Bold;
  font-weight: 100;
  font-size: 3.3333333333333335vw;
  line-height: 100%;
  text-align: center;

  color: #4d4d4d;
}

#scroll_fx_video_container {
  position: relative;
  display: block;
  width: 100%;
}

#scroll_fx_video_wrapper {
  position: relative;
  left: 0;
  width: 100%;
  height: 55.416666666666664vw;
}

#scroll_fx_video_wrapper.fixed {
  position: fixed;
  top: 0;
}

#scroll_fx_video_wrapper.bottom {
  position: absolute;
  bottom: 0px;
}

#scroll_fx_video {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 900px) {
  .scroll_fx_video_heading {
    display: none;
  }

  #scroll_fx_video_container {
    display: none;
  }
}
@media only screen and (max-width: 860px) {
  #scroll_fx_video {
    width: auto;
  }
}
@media only screen and (max-width: 1125px) {
  #scroll_fx_video {
    width: 100%;
    height: 100vh;
  }
}
