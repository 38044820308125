.container {
  height: 100%;
  background: #243746;
  padding: 6.077600531738118vw 0px;
  .titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2.21003655699568vw;
    margin-bottom: 4.42007311399136vw;
    .title {
      font-family: Gilroy-Bold;
      font-style: normal;
      font-weight: bold;
      font-size: 2.34375vw;
      line-height: 2.7625456962445996vw;
      text-align: center;
      color: #ffffff;
    }
    .subTitle {
      font-family: Gilroy-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 1.25vw;
      line-height: 1.5470255898969758vw;
      text-align: center;
      color: #ffffff;
      width: 44.635416666666664vw;
      height: 100%;
    }
  }
  .mainSliderContainer {
    display: flex;
    justify-content: center;
    .sliderContainer {
      width: 64%;
      border-radius: 8px !important;
      // background: #142232;
      // margin: 20px;
      // box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
    }
  }
}
.ArrowContainerLeft {
  position: absolute;
  top: 40%;
  z-index: 1000;
  left: -5%;
  width: 2.6041666666666665vw !important;
  height: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: -2%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
}
.ArrowContainerRight {
  position: absolute;
  right: -2%;
  top: 50%;
  z-index: 1000;
  width: 2.6041666666666665vw !important;
  height: 2.6041666666666665vw !important;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
}

.ArrowFont {
  color: #243746 !important;
  font-size: 2.0625vw !important;
}

@media (max-width: 900px) {
  .container {
    height: 100%;
    background: #243746;
    padding-top: 50px;
    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 3.988035892323031vh;
      margin-bottom: 43px;
      .title {
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: 100;
        width: 222px;
        height: 58px;

        font-size: 24px;
        line-height: 29px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #ffffff;
      }
      .subTitle {
        display: none;
      }
    }
    .mainSliderContainer {
      display: flex;
      justify-content: center;
      .sliderContainer {
        width: 80%;
        border-radius: 8px !important;
        margin-bottom: 70px;
      }
    }
  }
  .ArrowContainerLeft {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 45%;
    left: -5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  .ArrowContainerRight {
    width: 28.46px !important;
    height: 28.46px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 45%;
    right: -5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  .ArrowFont {
    color: #243746 !important;
    font-size: 20px !important;
  }
}
