.mainContainer {
  background-color: #243746;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  .leftContainer {
    display: flex;
    flex-direction: column;
    width: 37%;
    height: auto;
    align-items: center;
    // justify-content: center;
    margin-bottom: 9.53125vw;
    padding-top: 4.583333333333333vw;
    .middleSec {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .mainText {
        width: 25.15625vw;
        height: 100%;
        padding-bottom: 1.8229166666666667vw;
        // margin-left: 4.6875vw;
        // margin-top: 183px;

        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 3.096276041666667vw;
        line-height: 3.75vw;
        font-weight: 100;
        text-transform: uppercase;
        color: #ffff;
      }

      .subText {
        width: 20.364583333333332vw;
        height: 100%;
        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: lighter;
        font-size: 1.25vw;
        line-height: 1.4583333333333333vw;
        color: #ffff;
        opacity: 0.8;
      }
      .btnContainer {
        width: 21.822916666666668vw;
        height: 2.9166666666666665vw;
        // margin-left: 143px;
        padding-top: 3.9583333333333335vw;
      }
    }
  }

  .GradientContainer {
    width: 63%;
    position: relative;
    .rightContainer {
      height: 100%;
      width: 100%;
      // width: 63vw;
      // background-color: red;
    }
    .gradient {
      top: 0;
      background: linear-gradient(
        163.93deg,
        #243746 20.3%,
        rgba(36, 55, 70, 0) 63.64%,
        #243746 88.77%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
      position: absolute;
      height: 100%;
      width: 100%;
      // background-color: red;
    }
    .image {
      height: 100%;
      width: 100%;
    }
  }
  .MbtnContainer {
    display: none;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    background-color: #243746;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .leftContainer {
      display: flex;

      flex-direction: column;
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
      text-align: start;
      margin-bottom: 0px;
      padding-top: 0px;
      .middleSec {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        .mainText {
          display: flex;
          width: 84.53333vw;
          height: 100%;
          margin-bottom: 18px;
          margin-top: 59px;
          // margin-left: 4.6875vw;
          // margin-top: 183px;

          font-family: Gilroy-Bold;
          font-weight: lighter;
          font-size: 30px;
          line-height: 36px;
          font-weight: 100;
          text-transform: uppercase;
          color: #ffff;
        }

        .subText {
          width: 80.26666vw;
          height: 100%;
          margin-bottom: 23px;
          font-family: Gilroy-Light;
          font-style: normal;
          font-weight: lighter;
          font-size: 18px;
          line-height: 21px;
          color: #ffff;
          opacity: 0.8;
        }
        .btnContainer {
          display: none;
        }
      }
    }

    .GradientContainer {
      width: 100%;
      position: relative;
      .rightContainer {
        height: 100%;
        width: 100%;

        // width: 63vw;
        // background-color: red;
      }
      .gradient {
        top: 0;
        background: linear-gradient(
          163.93deg,
          #243746 20.3%,
          rgba(36, 55, 70, 0) 63.64%,
          #243746 88.77%
        );
        mix-blend-mode: multiply;
        transform: matrix(1, 0, 0, -1, 0, 0);
        position: absolute;
        height: 100%;
        width: 100%;
        // background-color: red;
      }

      .image {
        height: 100%;
        width: 100%;
      }
    }

    .MbtnContainer {
      display: flex;

      height: 40.5px;
      margin: 32px 0px;
    }
  }
}
