.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // text-align: center;
  background-color: #efefef;
  height: 100%;

  background: #f6f7fb;

  //   color-styles:200;

  .LeaderSec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    row-gap: 4.427083333333333vw;
    padding: 5.46875vw 0px;
  }
}

.Title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5vw;
  line-height: 3.3333333333333335vw;
  width: 34.010416666666664vw;
  height: 4.21875vw;
  font-family: Gilroy-Bold;
  font-weight: 100;
  color: #243746;
  // margin-top: 4.887218045112782vh;
  //   font-weight: 800;
}

.cardContainer {
  display: flex;
  flex-direction: column;

  // justify-content: center;
  //   background-color: red;
  align-items: center;
  text-align: center;

  //   row-gap:20px;
}

.teamContainer {
  display: flex;
  // width: 80vw;
  flex-wrap: wrap;

  // flex-direction: column;
  column-gap: 3.6458333333333335vw;
}

.fname {
  font-family: Gilroy-Medium;
  //   font-style: normal;
  font-weight: 900;
  font-size: 1.5625vw;
  line-height: 1.5625vw;
  width: 100%;
  color: #243746;
  //   margin-bottom: 38px;
  //   height: 100%;
}

.lname {
  font-family: Gilroy-Medium;
  //   font-style: normal;
  font-weight: 900;
  font-size: 1.5625vw;
  line-height: 1.5625vw;
  width: 100%;
  color: #243746;
  // height: 41px;
}

.leaderImage {
  //   font-family: Gilroy-Medium;
  //   //   font-style: normal;
  //   //   font-weight: 600;
  //   font-size: 30px;
  //   line-height: 30px;
  margin-bottom: 2.65625vw;
  // cursor :pointer ;
}
.pointer {
  cursor: pointer;
}
.leaderRole {
  font-family: Gilroy-Bold;
  font-size: 0.78125vw;
  line-height: 0.9375vw;
}

@media (max-width: 900px) {
  .mainContainer {
    height: 100%;

    .LeaderSec {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      row-gap: 43px;
      padding: 38px 0px;
    }
  }

  .Title {
    width: 298px;
    height: 29px;
    font-size: 24px;
    line-height: 29px;
    font-family: Gilroy-Bold;
    font-weight: 100;
    //   font-weight: 800;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;

    // justify-content: center;
    //   background-color: red;
    align-items: center;
    text-align: center;

    //   row-gap:20px;
  }

  .teamContainer {
    display: flex;
    // width: 80vw;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 40px;
    column-gap: 60px;
    margin-bottom: 0px;
  }

  .fname {
    font-family: Gilroy-Medium;
    //   font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    color: #243746;
    height: 50px !important;
    //   margin-bottom: 38px;
    //   height: 100%;
  }

  .leaderImage {
    //   font-family: Gilroy-Medium;
    //   //   font-style: normal;
    //   //   font-weight: 600;
    //   font-size: 30px;
    //   line-height: 30px;
    margin-bottom: 20px;
  }

  .leaderRole {
    font-family: Gilroy-Bold;
    font-size: 10px;
    line-height: 12px;
    margin-top: 0px !important;
    height: 30px !important;
  }
}
