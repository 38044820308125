.container {
  display: flex;

  // filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);

  .titleContainer {
    background: #243746;
    width: 20vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 0.8333333333333334vw;
      line-height: 1.0708534621578099vw;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #ffffff;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      width: 8.645833333333334vw;
    }
  }
}
