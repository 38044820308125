.container {
  height: 100vh;
  position: relative;
  background: #243746;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .radialGradient {
    position: absolute;
    background: linear-gradient(
      180deg,
      #243746 16.21%,
      rgba(36, 55, 70, 0) 33.43%,
      #ffffff 54.95%,
      #bdc2c7 67.86%
    );
    mix-blend-mode: multiply;
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: inherit;
    width: 100%;

    @media (max-width: 750px) {
      // height: inherit;
      display: none;
    }
  }
  .circularGradient {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: linear-gradient(180.76deg, #243746 10.98%, rgba(36, 55, 70, 0) 43.41%);
    mix-blend-mode: multiply;
    transform: matrix(1, 0, 0, -1, 0, 0);
    @media (max-width: 750px) {
      // height: inherit;
      display: none;
    }
  }
  .textGradient {
    background: linear-gradient(90deg, rgba(36, 55, 70, 0.632) 14.56%, rgba(36, 55, 70, 0) 82.85%);
    position: absolute;
    left: 0;
    height: 100vh;
    width: 100%;
    @media (max-width: 750px) {
      // height: inherit;
      display: none;
    }
  }

  .mradialGradient {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 88vh;
    width: 100%;
    background: radial-gradient(150% 17.61% at 58.93% 52.34%, rgba(36, 55, 70, 0) 0%, #243746 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    @media (max-width: 900px) {
      display: block;
      background: radial-gradient(
        150% 27.61% at 58.93% 52.34%,
        rgba(36, 55, 70, 0) 0%,
        #243746 100%
      );
    }
  }
  .mcircularGradient {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    @media (max-width: 900px) {
      display: block;
      background: linear-gradient(
        179.98deg,
        #243746 3.05%,
        rgba(36, 55, 70, 0) 43.67%,
        #243746 89.42%
      );
      mix-blend-mode: multiply;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }

  .imageContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    img {
      width: 100%;
      height: 100%;
    }
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      @media (max-width: 1160px) {
        width: 100%;
      }
      @media (max-width: 760px) {
        object-fit: contain;
        margin-top: 50px;
      }
    }
    @media (max-width: 760px) {
      height: 100%;
    }
  }
  .textContainer {
    position: relative;
    width: 100%;
    max-width: 940px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 900px) {
      width: 90%;
      justify-content: start;
      padding-top: 100px;
    }

    h2 {
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 4em;
      line-height: 68px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      margin-bottom: 57px;
      @media (max-width: 1500px) {
        margin-bottom: 25px;
      }
      @media (max-width: 750px) {
        font-size: 30px;
        line-height: 34px;
      }

      span {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        // font-size: 44px;
        line-height: 68px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // line-height: 100%;

        color: #47d7ac;
        @media (max-width: 1500px) {
          // font-size: 4rem;
        }
        @media (max-width: 750px) {
          font-size: 30px;
          line-height: 34px;
        }
      }
    }
    p {
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 500;
      // font-size: 24px;
      // line-height: 120%;
      // font-size: 24px;
      // line-height: 120%;
      width: 428px;

      color: #ffffff;
      margin-bottom: 71px;
      @media (max-width: 1500px) {
        font-size: 20px;
        margin-bottom: 51px;
      }
      @media (max-width: 750px) {
        font-size: 16px;
        width: 330px;
      }
      @media (max-width: 560px) {
        margin-bottom: 30px;
      }
    }
    .button {
      font-family: 'Gilroy-Bold';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 120% */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #243746;
      background: #47d7ac;
      border-radius: 40px;
      width: 159px;
      height: 46px;

      a {
        color: #243746 !important;
      }

      @media (max-width: 750px) {
        width: 330px;
        margin-top: 400px;
        height: 36px;
        display: none;
      }
    }
  }
  .Mbutton {
    display: none;
  }

  @media (max-width: 750px) {
    .Mbutton {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translate(-50%, 50%);

      border-radius: 40px;
      background: #47d7ac;
      font-family: 'Gilroy-Bold';
      font-style: normal;
      // font-weight: 700;
      font-size: 14.463px;
      line-height: 17px;
      display: block;
      width: 90%;
      height: 45px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      z-index: 1111;

      a {
        color: #243746 !important;
      }
    }
  }
}
