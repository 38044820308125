.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  // padding-bottom: 64px;

  .subContainer {
    display: flex;
    height: 520px;
    width: 940px;
    max-width: 940px;
    background: #e4e6ed;
    border-radius: 20px 20px 20px 20px;

    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));

    .leftContainer {
      max-width: 640px;
      display: flex;
      align-items: center;
      video {
        width: 100%;
        border-radius: 20px 0px 0px 20px;
      }
    }
    .rightContainer {
      padding: 36px 36px 31px 36px;
      width: 300px;
      background: #ffffff;
      border-radius: 0px 20px 20px 0px;
      display: flex;
      flex-direction: column;
      // row-gap: 28px;
      .subTitle {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #141d24;
        margin-bottom: 28px;
      }
      .title {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 110%;
        color: #141d24;
        width: 207px;
        min-height: 138px;
        margin-bottom: 28px;
      }
      .description {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 19.2px;
        line-height: 110%;
        color: #141d24;
        width: 207px;
        min-height: 168px;
        margin-bottom: 20px;
      }
      .btnContainer {
        display: flex;
        align-items: center;
        // column-gap: 5px;
        width: 165px;
        height: 40px;
        justify-content: space-evenly;
        background: #64ddb9;
        border-radius: 20px;

        .btnText {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 110%;
          color: #141d24;
          margin-left: 10px;
          margin-top: 2px;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 64px;
    // padding-bottom: 64px;

    .subContainer {
      display: flex;
      height: 100%;
      width: 90%;
      max-width: 90%;
      background: #e4e6ed;
      border-radius: 20px 20px 20px 20px;

      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));

      flex-direction: column;

      .leftContainer {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        video {
          border-radius: 20px 20px 0px 0px;
        }
      }
      .rightContainer {
        box-sizing: border-box;
        padding: 38px 38px 38px 38px;
        width: 100%;
        background: #ffffff;
        border-radius: 0px 0px 20px 20px;
        display: flex;
        flex-direction: column;
        // row-gap: 28px;
        .subTitle {
          font-family: 'Gilroy-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          color: #141d24;
          margin-bottom: 19px;
        }
        .title {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 110%;
          color: #141d24;
          width: 100%;
          min-height: 100%;
          margin-bottom: 19px;
        }
        .description {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 19.2px;
          line-height: 110%;
          color: #141d24;
          width: 100%;
          min-height: 100%;
          margin-bottom: 84px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          // column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: center;
          background: #64ddb9;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Medium';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 64px;
    // padding-bottom: 64px;

    .subContainer {
      display: flex;
      height: 100%;
      width: 90%;
      max-width: 90%;
      background: #e4e6ed;
      border-radius: 20px 20px 20px 20px;

      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));

      flex-direction: column;

      .leftContainer {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .rightContainer {
        box-sizing: border-box;
        padding: 28px 28px 28px 28px;
        width: 100%;
        background: #ffffff;
        border-radius: 0px 0px 20px 20px;
        display: flex;
        flex-direction: column;
        // row-gap: 28px;
        .subTitle {
          font-family: 'Gilroy-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #141d24;
        }
        .title {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 110%;
          color: #141d24;
          width: 100%;
          min-height: 100%;
        }
        .description {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 110%;
          color: #141d24;
          width: 100%;
          min-height: 100%;
          margin-bottom: 84px;
        }
        .btnContainer {
          display: flex;
          align-items: center;
          // column-gap: 5px;
          width: 100%;
          height: 40px;
          justify-content: space-evenly;
          background: #64ddb9;
          border-radius: 20px;

          .btnText {
            font-family: 'Gilroy-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #141d24;
          }
        }
      }
    }
  }
}
