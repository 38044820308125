.container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 63.16666666666667vw;
  height: 35.968344965104684vw;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);

  .imageContainer {
    width: 50%;
    height: 100%;

    .image {
      height: 100%;
      width: 100%;
    }
  }
  .textContainer {
    height: 100%;
    width: 50%;
    background: #142232;
    display: flex;
    justify-content: center;
    // align-items: center;
    .textSubContainer {
      width: 25.3125vw;
      height: 100%;
      row-gap: 3.28125vw;
      display: flex;
      flex-direction: column;
      margin-top: 5.208333333333333vw;
      // justify-content: center;

      .title {
        width: 23.75vw;
        height: 3.75vw;
        font-family: Gilroy-Bold;
        font-weight: bold;
        font-size: 1.5625vw;
        line-height: 2.0833333333333335vw;
        color: #ffffff;
        margin-bottom: 0px;
      }
      .descriptionContainer {
        display: flex;
        flex-direction: column;
        row-gap: 2.3757892987703557vw;
        width: 25.3125vw;
        height: 20.833333333333332vw;

        p {
          font-family: Gilroy-Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 1.25vw;
          line-height: 1.9337819873712196vw;
          color: #ffffff;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    height: 451px;
    .imageContainer {
      width: 100%;
      height: 160px;

      .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .textContainer {
      height: 500px;
      width: 100%;
      background: #142232;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      .textSubContainer {
        width: 80%;
        row-gap: 10px;
        display: flex;
        flex-direction: column;
        margin-top: 53px;

        .title {
          width: 65vw;
          height: 20px;
          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          margin-bottom: 30px;
        }
        .descriptionContainer {
          width: 100%;
          height: 224px;
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          p {
            font-family: Gilroy-Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;

            color: #ffffff;
          }
        }
      }
    }
  }
}
