.container {
  display: flex;
  justify-content: center;
  position: relative;
  .gradient {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(36, 55, 70, 0.14) 0%, rgba(228, 230, 237, 0) 100%);
    mix-blend-mode: multiply;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  .subcontainer {
    // width: 80%;
    width: 100%;
    height: 570px;
    display: flex;
    position: relative;
    max-width: 940px;

    @media (max-width: 1220px) {
    }

    @media (max-width: 980px) {
      width: 90%;
    }
    // @media (max-width: 720px) {
    //   height: 800px;
    //   height: 500px;
    // }

    @media (max-width: 780px) {
      height: 420px;
    }
    @media (max-width: 620px) {
      height: 500px;
    }

    @media (max-width: 560px) {
      height: 600px;
    }

    @media (max-width: 425px) {
      height: 450px;
    }

    .leftContainer {
      position: absolute;
      // width: 55%;
      max-width: 55%;
      min-width: auto;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
        @media (max-width: 560px) {
          border-radius: 20px;
        }
      }
      // @media (max-width: 980px) {
      //   width: 48.5vw;
      // }

      @media (max-width: 560px) {
        max-width: 75%;
      }
      // @media (max-width: 425px) {
      // width: 65.157vw;
      // width: 270px;
      // }
    }
    .rightContainer {
      // max-width: 780px;
      max-width: 55%;
      box-sizing: border-box;
      padding: 75px 188px 110px 128px;
      position: absolute;
      top: 12%;
      width: 100%;
      // height: 625px;
      background: #243746;
      border-radius: 20px;
      right: 0%;

      @media (max-width: 980px) {
        padding: 75px 125px 110px 128px;
      }
      @media (max-width: 930px) {
        padding: 75px 100px 110px 128px;
      }
      @media (max-width: 865px) {
        padding: 52px 78px 110px 117px;
      }

      @media (max-width: 750px) {
        width: 50.157vw;
        height: 323px;
        padding: 45px 31px 65px 90px;
      }
      @media (max-width: 675px) {
        height: 273px;
        top: 14%;
        width: 50.157vw;
        padding: 45px 31px 65px 78px;
      }
      @media (max-width: 635px) {
        right: 0%;
      }

      @media (max-width: 560px) {
        height: 273px;
        padding: 99px 18px 21px 22px;
        top: 44%;
        width: 75%;
        max-width: 100%;
      }
      @media (max-width: 520px) {
        top: 43%;
      }
      @media (max-width: 497px) {
        top: 41%;
      }
      @media (max-width: 485px) {
        top: 39%;
      }
      @media (max-width: 475px) {
        top: 37%;
      }
      @media (max-width: 455px) {
        top: 35%;
      }
      @media (max-width: 425px) {
        height: 253px;
        top: 43%;
        width: 270px;
        padding: 70px 18px 10px 22px;
      }
      @media (max-width: 395px) {
        padding: 54px 18px 10px 22px;
      }

      h2 {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 2.5em;
        line-height: 44px;
        color: #ffffff;
        margin-bottom: 40px;

        @media (max-width: 930px) {
          margin-bottom: 43px;
        }
        @media (max-width: 860px) {
          font-size: 30px;
          width: 100%;
          margin-bottom: 33px;
        }
        @media (max-width: 720px) {
          font-size: 25px;
          width: 100%;
          line-height: 28px;
          margin-bottom: 30px;
        }
        @media (max-width: 520px) {
          font-size: 25px;
          width: 100%;
          margin-bottom: 20px;
        }
      }
      p {
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 1.2em;
        line-height: 22px;
        // font-size: 30px;
        // line-height: 110%;
        display: flex;
        align-items: center;

        color: #ffffff;

        // @media (max-width: 1450px) {
        //   font-size: 26px;
        // }
        // @media (max-width: 1350px) {
        //   font-size: 24px;
        // }
        // @media (max-width: 1180px) {
        //   font-size: 22px;
        // }
        // @media (max-width: 900px) {
        //   font-size: 20px;
        //   // width: 250px;
        // }
        @media (max-width: 780px) {
          font-size: 16px;
          line-height: 18px;
          width: 100%;
        }
        @media (max-width: 720px) {
          font-size: 1em;
          width: 100%;
        }
      }
    }
  }
}
