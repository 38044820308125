.mainContainer {
  margin: 5.729166666666667vw 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .titleContainer {
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: 600;
    font-size: 2.0833333333333335vw;
    color: #243746;
  }
  .sectionTitle {
    font-family: Gilroy-Bold;
    font-weight: 100;
    font-size: 1.25vw;
    width: 100%;
    color: #243746;
    margin-top: 3.6458333333333335vw;
    margin-bottom: 1.5625vw;
  }
  .accordionContainer {
    width: 70%;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    .titleContainer {
      width: 211px;
      height: 58px;
      font-family: Gilroy-Medium;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;

      color: #243746;
      margin-bottom: 61px;
    }
    .sectionTitle {
      width: 319.57px;
      height: 15.17px;
      font-family: Gilroy-Bold;
      font-weight: 100;
      font-size: 14px;
      line-height: 17px;

      color: #243746;
      margin-bottom: 15px;
    }
    .accordionContainer {
      width: 100%;
    }
  }
}
