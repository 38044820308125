.mainContainer {
  display: flex;
  //   justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-bottom: 7.604166666666667vw;

  .subText {
    width: 46.666666666666664vw;
    height: 5.46875vw;

    font-family: Gilroy-Light;
    font-weight: lighter;

    margin-top: 1.9270833333333333vw;
    margin-bottom: 6.458333333333333vw;
    font-size: 1.5625vw;
    line-height: 1.8229166666666667vw;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }
  .title {
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: 600;
    font-size: 2.0833333333333335vw;
    line-height: 2.5700483091787443vw;
    color: #243746;
    margin-top: 6.114583333333333vw;
  }

  .positionContainer {
    max-height: 880px;
    height: 45.833333333333336vw;
    width: 70%;
    background-color: #f6f7fb;

    .iframe {
      border: 0px;
      height: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 65px;

    .title {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #243746;
      margin-top: 32px;
    }

    .subText {
      width: 80%;
      height: 100%;

      font-family: Gilroy-Light;
      font-weight: lighter;

      margin-top: 32px;
      margin-bottom: 38px;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #000000;
    }

    .positionContainer {
      width: 80%;
      height: 492px;
      background-color: #f6f7fb;

      .iframe {
        border: 0px;
        height: 482px;
        width: 100%;
      }
    }
  }
}
