.container {
  position: relative;
  height: 100%;
  width: 100%;
  img {
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    right: 20px;
    height: 30px;
    width: 30px;
  }
}
