.bm-burger-button {
  display: none;
}

@media (max-width: 900px) {
  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: white;
  }

  .bm-menu-wrap {
    display: flex;
    flex-direction: column;
    background: #243746;
    align-items: center;
    z-index: 100000 !important;
    width: 100% !important;
  }

  .bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    right: 16px;
    top: 20px;
    display: block;
    z-index: 100000 !important;
  }

  .bm-burger-bars {
    background: #fff;
    border-radius: 10px;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    color: #b8b7ad;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  #react-burger-menu-btn {
    z-index: 1000 !important;
  }
}
