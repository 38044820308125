.mainContainer {
  display: flex;
  flex-direction: column;
  margin-top: 6.25vw;
  align-items: center;
  height: 100%;
  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    .mainText {
      width: 39.385416666666664vw;
      height: 100%;
      font-size: 3.3333333333333335vw;
      line-height: 4.010416666666667vw;
      font-family: Gilroy-Bold;
      font-weight: 100;
      text-align: center;
      color: #4d4d4d;
    }
    .subText {
      width: 39.0625vw;
      height: 100%;
      font-family: Gilroy-Light;
      font-weight: 100;
      font-size: 1.25vw;
      line-height: 1.4583333333333333vw;
      text-align: center;
      color: #4d4d4d;
    }
  }
  .centerSec {
    max-width: 46.5625vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 6.458333333333333vw;
    row-gap: 5.208333333333333vw;
    margin-top: 4.322916666666667vw;
    .imageContainer {
      .images {
        width: 8.189062499999999vw;
        height: 8.189062499999999vw;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .imageText {
        width: 8.645833333333334vw;
        height: 3.1770833333333335vw;
        font-family: Gilroy-Bold;
        font-weight: 100;
        font-size: 1.3262395833333334vw;
        line-height: 1.6145833333333333vw;
        text-align: center;
        color: #243746;
      }
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    align-items: center;
    height: 100%;
    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 40px;
      .mainText {
        width: 259px;
        height: 72px;
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: 100;
        font-size: 30px;
        line-height: 36px;
        text-align: center;

        color: #243746;
      }
      .subText {
        width: 282px;

        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: 100;
        font-size: 24px;
        line-height: 28px;
        text-align: center;

        color: #4d4d4d;
      }
    }
    .centerSec {
      max-width: 500px;
      padding: 40px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 36px;
      row-gap: 40px;
      margin-top: 83px;
      .imageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        .images {
          width: 77.49px;
          height: 77.49px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .imageText {
          width: 121px;
          height: 100%;
          font-family: Gilroy-Bold;
          font-weight: 100;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          color: #243746;
        }
      }
    }
  }
}
