.container {
  position: relative;
  overflow: hidden;
  margin-top: 10.416666666666666vw;
  // background-color: red;
  // height:100%;
  .sliderStyle {
    display: flex;
    justify-content: center;
    margin-bottom: 6.0675182481751815vw;
    .sliderContainer {
      width: 80%;
    }
  }

  .overlay1 {
    position: absolute;
    top: -0.5056265206812651vw;
    left: 40%;
    z-index: 5;
    width: 100%;
    height: 20.12393552311436vw;
    background: linear-gradient(180deg, #f6f7fb 65.42%, rgba(246, 247, 251, 0) 100%);
    transform: matrix(0, -1, -1, 0, 0, 0);
    pointer-events: none;
  }
  .overlay2 {
    position: absolute;
    top: -0.5056265206812651vw;
    right: 40%;
    z-index: 5;
    width: 100%;
    height: 20.12393552311436vw;
    background: linear-gradient(180deg, #f6f7fb 25.42%, rgba(246, 247, 251, 0) 100%);
    transform: rotate(-90deg);
    pointer-events: none;
  }
  .titleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 2.7303832116788325vw;

    .title {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 2.0833333333333335vw;
      line-height: 2.427007299270073vw;
      /* identical to box height */
      width: 41.822916666666664vw;
      height: 4.854014598540146vw;
      display: flex;
      align-items: center;
      text-align: center;

      color: hsl(206, 32%, 21%);
    }
  }
}

.ArrowContainerRight {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  background: #243746;
  top: 45%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 45%;
  left: -2%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  cursor: pointer;
}

@media (max-width: 900px) {
  .container {
    margin-top: 0px;

    .sliderStyle {
      display: flex;
      justify-content: center;
      margin-bottom: 37px;

      .sliderContainer {
        width: 315px;
        height: 100%;
        border-radius: 20px;

        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      }
    }
    .overlay1 {
      display: none;
    }
    .overlay2 {
      display: none;
    }
    .titleContainer {
      display: none;

      .title {
        display: none;
      }
    }
  }

  .ArrowContainerRight {
    height: 25px !important;
    width: 25px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 45%;
    right: -5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowContainerLeft {
    height: 25px !important;
    width: 25px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 45%;
    left: -5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
  }
}
