.main {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: 100.59px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .tab {
    font-family: 'Roboto'; 
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: center;

    color: #565656;
    padding: 10px;
  }
  .activetab {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 11px;
    line-height: 13px;
    text-align: center;

    color: #565656;
    padding: 10px;
  }
}
