.container {
  display: flex;
  flex-direction: column;
  row-gap: 88px;

  padding-top: 70px;
  padding-bottom: 110px;
}

@media (max-width: 640px) {

   .container{
  row-gap: 25px;
  }
}
@media (max-width: 480px) {

  .container{
  row-gap: 25px;

    padding-top: 42px;
  padding-bottom: 42px;
  }
}
