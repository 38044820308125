.bottom {
  display: flex;
  column-gap: 7.604166666666667vw;
  align-items: center;
  justify-content: space-between;
  // background-color: red;
  height: 28.854166666666668vw;
  width: 100%;

  .leftContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-between;
    .mainText {
      width: 26.614583333333332vw;
      height: 11.875vw;
      font-family: Gilroy-Bold;
      font-size: 3.3333333333333335vw;
      line-height: 3.5416666666666665vw;
      display: flex;
      align-items: center;
      color: #243746;
      // background-color: green;
    }

    .subText {
      width: 30.572916666666668vw;
      height: 16.979166666666668vw;

      font-family: Gilroy-Regular;
      font-size: 1.5625vw;
      line-height: 1.8229166666666667vw;
      display: flex;
      align-items: center;

      color: #243746;
    }
  }

  .rightContainer {
    width: 20.677083333333332vw;
    height: 23.708854166666665vw;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 900px) {
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: red;
    height: 100%;

    .leftContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      .mainText {
        width: 44.8vw;
        height: 80px;
        font-family: Gilroy-Bold;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;

        color: #243746;
        // background-color: green;
      }
      .subText {
        width: 42.6666vw;
        height: 100%;

        font-family: Gilroy-Light;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;

        color: #243746;
        text-overflow: clip;
        // background-color: blue;
        margin-bottom: 40px;
      }
    }

    .rightContainer {
      display: flex;
      align-items: center;
      width: 100%;
      height: 132px;
      // background-color: yellow;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
