.container {
  background: #142232;
  display: flex;
  justify-content: center;

  @media (max-width: 375px) {
    flex-direction: column;
    align-items: center;
  }

  .subContainer {
    // width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 80px;
    margin-bottom: 80px;
    justify-items: center;
    justify-content: center;
    max-width: 940px;

    @media (max-width: 975px) {
      grid-template-columns: 1fr;
      margin: 45px 0px;
      row-gap: 40px;
      justify-items: normal;
    }

    @media (max-width: 980px) {
      width: 90%;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 760px;
      @media (max-width: 375px) {
      }

      h2 {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        // font-weight: 700;
        // font-size: 60px;
        // line-height: 100%;
        font-size: 2.5em;
        line-height: 44px;
        color: #ffffff;
        // @media (max-width: 1675px) {
        //   font-size: 55px;
        // }
        // @media (max-width: 1575px) {
        //   font-size: 52px;
        // }
        // @media (max-width: 1475px) {
        //   font-size: 48px;
        // }
        // @media (max-width: 1375px) {
        //   font-size: 43px;
        // }
        // @media (max-width: 1275px) {
        //   font-size: 38px;
        // }
        // @media (max-width: 1175px) {
        //   font-size: 33px;
        // }
        @media (max-width: 975px) {
          margin-bottom: 20px;
        }

        @media (max-width: 375px) {
          font-size: 30px;
          line-height: 32px;
        }
      }
      p {
        font-family: 'Gilroy-Regular';
        font-style: normal;
        // font-weight: 500;
        // font-size: 40px;
        // line-height: 120%;
        font-size: 1.2em;
        line-height: 22px;
        /* or 48px */

        color: #ffffff;
        // @media (max-width: 1675px) {
        //   font-size: 38px;
        // }
        // @media (max-width: 1575px) {
        //   font-size: 33px;
        // }
        // @media (max-width: 1475px) {
        //   font-size: 30px;
        // }
        // @media (max-width: 1375px) {
        //   font-size: 27px;
        // }
        // @media (max-width: 1275px) {
        //   font-size: 24px;
        // }
        // @media (max-width: 1175px) {
        //   font-size: 21px;
        // }
        @media (max-width: 375px) {
          font-size: 16px;
          line-height: 120%;
          padding-top: 31px;
          padding-bottom: 23px;
        }
      }
      .btn {
        a {
          font-family: 'Gilroy-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          color: #243746;
          background: #47d7ac;
          border-radius: 40px;
          // width: 229px;
          // height: 46px;
          width: 159px;
          height: 46px;
        }
        @media (max-width: 975px) {
          display: none;
        }
      }
    }
    .rightTopContainer {
      text-align: -webkit-right;
      @media (max-width: 975px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .rightContainer {
        width: 90%;
        max-width: 600px;

        @media (max-width: 730px) {
          width: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 20px;

          // @media (max-width: 975px) {
          //   width: 100%;
          //   margin-bottom: 30px;
          // }
          // @media (max-width: 375px) {
          //   width: 330px;
          //   height: 220px;
          // }
        }
      }
    }
  }

  .Mbtn {
    display: none;
    @media (max-width: 975px) {
      a {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        // font-weight: 700;
        font-size: 14.463px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #243746;
        background: #47d7ac;
        border-radius: 40px;
        width: 100%;
        height: 45px;
        margin-bottom: 40px;
        margin-top: 46px;
      }
    }
  }
}
