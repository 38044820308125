.container {
  display: flex;
  justify-content: center;
  .gradient {
    background: linear-gradient(180deg, rgba(36, 55, 70, 0) 0%, rgba(228, 230, 237, 0.35) 100%);
    mix-blend-mode: multiply;
    transform: matrix(1, 0, 0, -1, 0, 0);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .subcontainer {
    max-width: 940px;
    width: 100%;
    margin-bottom: 190px;
    position: relative;
    @media (max-width: 920px) {
      margin-bottom: 150px;
    }
    @media (max-width: 980px) {
      width: 90%;
    }
    @media (max-width: 786px) {
      height: 600px;
      margin-bottom: 0px;
    }
    @media (max-width: 520px) {
      height: 500px;
    }

    .leftContainer {
      box-sizing: border-box;

      // max-width: 857px;
      background: #e4e6ed;
      border-radius: 20px;
      width: 50%;
      padding: 40px 40px 80px 40px;
      // height: 623px;
      // padding: 75px 258px 81px 103px;
      // max-width: 740px;

      // @media (max-width: 1950px) {
      //   padding: 75px 228px 81px 103px;
      //   height: 623px;
      // }
      // @media (max-width: 1700px) {
      //   padding: 75px 178px 81px 103px;
      //   height: 623px;
      // }
      // @media (max-width: 1575px) {
      //   padding: 75px 148px 81px 103px;
      //   height: 623px;
      // }
      // @media (max-width: 1500px) {
      //   height: 575px;
      // }

      // @media (max-width: 1450px) {
      //   padding: 55px 160px 41px 63px;
      //   height: 523px;
      // }
      // @media (max-width: 1400px) {
      //   padding: 55px 148px 41px 63px;
      //   // height: 473px;
      // }
      // @media (max-width: 1280px) {
      //   padding: 35px 128px 31px 43px;
      //   height: 373px;
      // }
      // @media (max-width: 1100px) {
      //   padding: 35px 98px 31px 43px;
      //   height: 373px;
      // }
      // @media (max-width: 980px) {
      //   width: 50.157vw;
      //   padding: 75px 48px 81px 43px;
      // }
      @media (max-width: 780px) {
        width: 70.157vw;
        padding: 75px 48px 121px 43px;
      }
      @media (max-width: 520px) {
        width: 70.157vw;
        padding: 30px;
        height: 273px;
      }

      h2 {
        font-family: 'Gilroy-Medium';
        font-style: normal;
        font-weight: bolder;
        width: 122px;
        color: #000000;

        font-size: 2.5em;
        line-height: 44px;
        // @media (max-width: 1450px) {
        //   font-size: 55px;
        // }
        // @media (max-width: 1350px) {
        //   font-size: 50px;
        // }
        // @media (max-width: 1280px) {
        //   font-size: 40px;
        //   width: 200px;
        // }
        // @media (max-width: 1080px) {
        //   font-size: 35px;
        //   width: 200px;
        // }
        @media (max-width: 980px) {
          font-size: 30px;
          width: 200px;
          line-height: 32px;
        }
        @media (max-width: 780px) {
          font-size: 25px;
          width: 100%;
        }
        @media (max-width: 520px) {
          font-size: 25px;
          width: 100%;
          // margin-bottom: 20px;
        }
      }
      p {
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 1.2em;
        line-height: 22px;
        width: 240px;
        display: flex;
        align-items: center;
        color: #000000;
        padding: 40px 100px 0px 0px;

        @media (max-width: 980px) {
          font-size: 1em;
          line-height: 18px;
          width: 60%;
        }

        @media (max-width: 780px) {
          padding: 20px 100px 0px 0px;
          width: 100%;
        }
      }
    }
    .rightContainer {
      max-width: 951px;
      box-sizing: border-box;
      position: absolute;
      top: 40%;
      border-radius: 20px;
      right: 0%;
      position: absolute;
      width: 67%;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
      }
      @media (max-width: 780px) {
        top: 35%;
        right: 0%;
        width: 75.092vw;
      }
      @media (max-width: 680px) {
        top: 43%;
        right: 0%;
        width: 75.092vw;
      }
      @media (max-width: 520px) {
        top: 47%;
        right: 3%;
        width: 70.092vw;
      }
      @media (max-width: 420px) {
        top: 49%;
        right: 1%;
        width: 75.092vw;
      }
    }
  }
}
