.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f6f7fb;
  position: relative;
  padding-bottom: 4.322916666666667vw;

  .mainText {
    width: 34.895833333333336vw;
    height: 6.041666666666667vw;
    margin-top: 4.397916666666666vw;
    font-family: Gilroy-Medium;
    font-style: normal;
    font-weight: lighter;
    font-size: 2.0833333333333335vw;
    line-height: 3.0208333333333335vw;
    text-align: center;
    color: #243746;
  }
  .gradient1 {
    position: absolute;
    width: 32.708333333333336vw;
    // top: 20%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    /* top: 5914px; */
    background: linear-gradient(to right, #f6f7fb 57.29%, rgba(246, 247, 251, 0) 100%);
    z-index: 1000;
    pointer-events: none;
  }
  .gradient2 {
    position: absolute;
    width: 32.708333333333336vw;
    // top: 20%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    /* top: 5914px; */
    background: linear-gradient(to left, #f6f7fb 57.29%, rgba(246, 247, 251, 0) 100%);
    z-index: 1000;
    pointer-events: none;
  }

  .mainSliderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-left: 4.166666666666667vw;
    .sliderContainer {
      width: 100%;
      border-radius: 8px !important;
    }
  }
}
.ArrowContainerRight {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  background: #243746;
  top: 45%;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ArrowContainerLeft {
  height: 2.6041666666666665vw !important;
  width: 2.6041666666666665vw !important;
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  top: 45%;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #243746;
  cursor: pointer;
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f6f7fb;
    position: relative;
    padding-bottom: 53px;

    .mainText {
      width: 298px;
      height: 29px;
      margin-top: 42px;
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #243746;
      margin-bottom: 31px;
    }
    .gradient1 {
      display: none;
    }
    .gradient2 {
      display: none;
    }

    .mainSliderContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 640px;
      margin-left: 0px;
      border-radius: 8px;
      .sliderContainer {
        width: 100%;
        border-radius: 8px !important;
      }
    }
  }
  .ArrowContainerRight {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 45%;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowContainerLeft {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 45%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
  }
}

@media (max-width: 650px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f6f7fb;
    position: relative;
    padding-bottom: 53px;

    .mainText {
      width: 298px;
      height: 29px;
      margin-top: 42px;
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #243746;
      margin-bottom: 31px;
    }
    .gradient1 {
      display: none;
    }
    .gradient2 {
      display: none;
    }

    .mainSliderContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 330px;
      margin-left: 0px;
      border-radius: 8px;
      .sliderContainer {
        width: 100%;
        border-radius: 8px !important;
      }
    }
  }
  .ArrowContainerRight {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    background: #243746;
    top: 45%;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowContainerLeft {
    height: 28px !important;
    width: 28px !important;
    border-radius: 50%;
    z-index: 10;
    position: absolute;
    top: 45%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #243746;
  }
}
