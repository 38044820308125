.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 7.729468599033816vh;
  margin-bottom: 80px;

  .titleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title {
      font-family: Gilroy-Bold;
      font-size: 2.34375vw;
      text-align: center;
      width: 28.802083333333332vw;
      height: 4.541062801932367vh;
      color: #243746;
    }
  }

  .statsContainer {
    display: flex;
    column-gap: 9.895833333333334vw;
    justify-content: center;
  }
}

@media (max-width: 900px) {
  .container {
    margin-bottom: 39px;

    .titleContainer {
      .title {
        width: 207px;
        height: 60px;
        font-family: Gilroy-Bold;
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #243746;
      }
    }

    .statsContainer {
      display: flex;
      flex-wrap: wrap;
      row-gap: 56px;
    }
  }
}
