.container {
  display: flex;

  // position: relative;
  .detailsContainer {
    position: absolute;
    top: 20%;
    right: -11%;
    background: #141d24;
    box-shadow: 0px 0px 3.35277px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;
    z-index: 20;

    filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.25));
    width: 176px;
    height: 260px;
    @media (max-width: 1254px) {
      top: 28%;
      right: 30%;
    }
    @media (max-width: 980px) {
      width: 146px;
      height: 210px;
    }
    @media (max-width: 700px) {
      top: 30%;
      left: 34%;
    }
    @media (max-width: 550px) {
      bottom: -5%;
      left: 30%;
      width: 146px;
      height: 170px;
      padding: 15px;
    }
    @media (max-width: 480px) {
      top: 40%;
      left: 25%;
    }
    @media (max-width: 410px) {
      top: 44%;
      left: 22%;
    }
    @media (max-width: 375px) {
      top: 40%;
      left: 20%;
    }

    .iconContainer {
      width: 50.23px;
      height: 50.23px;
      background: #3b6cb3;
      border-radius: 16.4747px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18px;
      @media (max-width: 980px) {
        margin-bottom: 13px;
        width: 45px;
        height: 45px;
      }
      @media (max-width: 700px) {
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
      }
      @media (max-width: 600px) {
        width: 35px;
        height: 35px;
      }

      img {
        width: 30px;
        height: 30px;
        @media (max-width: 980px) {
          width: 27px;
          height: 27px;
        }
        @media (max-width: 700px) {
          width: 22px;
          height: 22px;
        }
        @media (max-width: 375px) {
          width: 17px;
          height: 17px;
        }
      }
    }
    .highlightText {
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 700;
      font-size: 19.2px;
      line-height: 23px;
      color: #64ddb9;
      margin-bottom: 23px;
      @media (max-width: 980px) {
        font-size: 14px;
        margin-bottom: 15px;
      }
      @media (max-width: 550px) {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .detailsText {
      font-family: 'Gilroy-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 19.2px;
      line-height: 23px;
      color: #ffffff;
      margin-bottom: 23px;
      width: 156px;

      @media (max-width: 980px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
      @media (max-width: 550px) {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .displayNone {
    // height: 0px;
    // width: 0px;
    display: none;
    visibility: hidden;
  }
  .diplay {
    // height: fit-content;
    // width: 100%;
    display: block;
    visibility: visible;
  }

  .pulseButton {
    position: absolute;
    right: 19%;
    top: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    font-size: 1.3em;
    font-weight: light;
    font-family: 'Trebuchet MS', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 100px;
    letter-spacing: -1px;
    color: white;
    border: none;
    border-radius: 50%;
    background: #47d7ac;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 0 0 0 rgba(#47d7ac, 0.5);
    -webkit-animation: pulsing 1.5s infinite;
    @media (max-width: 980px) {
      width: 50px;
      height: 50px;
    }
    @media (max-width: 700px) {
      width: 40px;
      height: 40px;
      right: 14%;
      top: 25%;
    }

    @media (max-width: 450px) {
      right: 14%;
      top: 22%;
    }
    @media (max-width: 375px) {
      width: 30px;
      height: 30px;
    }

    img {
      width: 30px;
      height: 30px;
      @media (max-width: 980px) {
        width: 27px;
        height: 27px;
      }
      @media (max-width: 700px) {
        width: 22px;
        height: 22px;
      }
      @media (max-width: 600px) {
        width: 19px;
        height: 19px;
      }
      @media (max-width: 450px) {
        width: 17px;
        height: 17px;
      }
    }
  }

  .pulseButton:hover {
    background: #3b6cb3;
    box-shadow: 0 0 0 0 rgba(#3b6cb3, 0.5);
  }

  @-webkit-keyframes pulsing {
    0% {
      transform: scale(0.9);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 50px rgba(#47d7ac, 0);
    }
    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(#47d7ac, 0);
    }
  }

  @-moz-keyframes pulsing {
    0% {
      transform: scale(0.9);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 50px rgba(#47d7ac, 0);
    }
    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(#47d7ac, 0);
    }
  }

  @-ms-keyframes pulsing {
    0% {
      transform: scale(0.9);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 50px rgba(#47d7ac, 0);
    }
    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(#47d7ac, 0);
    }
  }

  @keyframes pulsing {
    0% {
      transform: scale(0.9);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 50px rgba(#47d7ac, 0);
    }
    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(#47d7ac, 0);
    }
  }
}
