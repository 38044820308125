.container {
  width: 100%;
  position: absolute;
  z-index: 9999;
  top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    width: 90%;
    position: relative;
    top: 0%;
    background-color: #f6f7fb;
    padding: 10px 10px;
    display: block;
    box-sizing: border-box;
  }

  .subContainer {
    // width: 80%;
    width: 100%;
    max-width: 940px;
    display: flex;
    justify-content: space-between;

    .logoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navbarContainer {
      @media (max-width: 900px) {
        display: none;
      }
      .solutionsButton {
        &:hover {
          background-color: #3b6cb3;
          color: #f6f7fb !important;
        }
      }
      padding: 5px 5px;
      background: #f6f7fb;
      border-radius: 40px;
      display: flex;
      column-gap: 15px;
      align-items: center;

      .navbarItem {
        padding: 5px 10px;
        font-family: 'Gilroy-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        display: flex;
        align-items: center;
        text-align: center;
        color: #243746;
        // @media (max-width: 1200px) {
        //   font-size: 18px;
        // }
        @media (max-width: 1150px) {
          font-size: 16px;
          padding: 10px 15px;
        }
        @media (max-width: 1100px) {
          font-size: 16px;
          padding: 10px 10px;
        }
      }
      .navbarHighlightItem {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        width: 159px;
        height: 46px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        color: #243746;
        background: #47d7ac;
        border-radius: 40px;

        @media (max-width: 1150px) {
          font-size: 16px;
        }
        @media (max-width: 1100px) {
          font-size: 16px;
        }
      }
      .disabled {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        width: 159px;
        height: 46px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        color: #243746;
        background: #929ba3;
        border-radius: 40px;
        cursor: not-allowed;

        @media (max-width: 1150px) {
          font-size: 16px;
        }
        @media (max-width: 1100px) {
          font-size: 16px;
        }
      }
    }
  }
}
