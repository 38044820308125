.container {
  display: flex;
  justify-content: center;
  width: 100%;

  .subContainer {
    max-width: 940px;
    display: flex;
    justify-content: center;
    width: 100%;

    .navigationBar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      column-gap: 16px;
      padding: 5px;
      border: 1px solid #bdc2c7;
      box-shadow: 0px 0px 28.2051px rgba(0, 0, 0, 0.25);
      border-radius: 28.2051px;
      box-sizing: border-box;
      overflow: hidden;
      @media (max-width: 980px) {
        display: flex;
        justify-content: space-between;
        column-gap: 16px;
        padding: 5px;
        border: 1px solid #bdc2c7;
        box-shadow: 0px 0px 28.2051px rgba(0, 0, 0, 0.25);
        border-radius: 28.2051px;
        box-sizing: border-box;
      }

      .itemContainer {
        width: 219.95249938964844px;
        height: 40px;
        border-radius: 28.205127716064453px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 11px;
        cursor: pointer;
        transition: 0.5ms all ease-in-out;

        // &:hover {
        //   background: #ffffff;

        //   // border: 1px solid #141d24;
        //   border-radius: 28.2051px;

        //   img {
        //     filter: invert(100);
        //   }
        // }

        .iconText {
          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          /* identical to box height, or 14px */

          /* Gray/gray-10 */

          color: #fff;
        }
      }
    }
    .dropdownTopContainer {
      position: relative;
      width: 100%;
      height: 55px;
      .dropdownContainer {
        border: 1px solid #bdc2c7;
        box-shadow: 0px 0px 28.2051px rgba(0, 0, 0, 0.25);
        border-radius: 28.2051px;
        width: 100%;
        height: 55px;
        padding: 8px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .selectedOption {
          width: 88%;
          background: #ffffff;
          height: 40px;
          background: #ffffff;
          /* Gray/gray-12 */

          border: 1px solid #141d24;
          border-radius: 28.2051px;
          display: flex;
          justify-content: center;
          align-items: center;

          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;

          color: #21303d;
          img {
            filter: invert(100);
            margin-right: 10px;
          }
        }

        .dropdown {
          margin-top: 5px;
        }
      }
      .optionsContainer {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        background: #fff;
        position: fixed;
        width: fit-content;
        height: auto;
        padding: 15px;
        border-radius: 20px;

        .selectedOptionInactive {
          width: 300px;
          height: 40px;

          border-radius: 28.2051px;
          display: flex;
          // justify-content: center;
          align-items: center;

          font-family: 'Gilroy-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;

          color: #21303d;
          img {
            filter: invert(100);
            margin-right: 10px;
          }
        }
      }
      .hidden {
        opacity: 0;
        visibility: hidden;
      }
      .display {
        opacity: 1;
        visibility: visible;
        z-index:100;
      }
    }
    .active {
      background: #ffffff;
      // border: 1px solid #141d24;
      border-radius: 28.2051px;
      .iconText {
        color: #21303d !important;
      }
      img {
        filter: invert(100);
      }
    }
  }
}
