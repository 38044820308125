.mainContainer {
  width: 100%;

  margin-right: 30px;

  .logoContainer {
    width: 9.53125vw;
    height: 94px;

    .logoImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 900px) {
  .mainContainer {
    width: 130px;
    height: 94px;
    .logoContainer {
      width: 90%;
      height: 104px;
      .logoImage {
        width: 100%;
        height: 80px;
      }
    }
  }
}

@media (max-width: 480px) {
  .mainContainer {
    width: 110px;
    height: 94px;
    .logoContainer {
      width: 110px;
      height: 104px;
      .logoImage {
        width: 100%;
        height: 80px;
      }
    }
  }
}
