.container {
  display: flex;
  width: 100%;
  height: 100%;
  background: #243746;

  .leftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43%;
    height: auto;
    position: relative;
    .leftSubContainer {
      width: 25.78125vw;
      .textContainer {
        margin-bottom: 3.5393856447688568vw;

        .text {
          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: 900;
          font-size: 3.125vw;
          line-height: 4.015700483091787vw;
          text-transform: uppercase;

          color: #ffffff;
        }
      }
      .divider {
        border: 1px solid #47d7ac;
        width: 15.157916666666667vw;
        margin-bottom: 2.725074133211679vw;
      }

      .highlightedText {
        font-family: Gilroy-Bold;
        font-style: normal;
        font-weight: 900;
        font-size: 1.5625vw;
        line-height: 1.9810789049919486vw;
        text-transform: uppercase;

        color: #4fdcb5;
        width: 15.78125vw;
        height: 7.977858293075684vw;
        margin-bottom: 2.6771336553945253vw;
      }
      .btnContainer {
        display: flex;
        column-gap: 1.6145833333333333vw;
      }
    }
  }
  .rightContainer {
    width: 57%;
    height: 50.11594202898551vw;

    .imageContainer {
      width: 100%;
      height: 50.11594202898551vw;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .MbtnContainer {
    display: none;
  }
}

@media (max-width: 900px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #243746;
    padding-top: 50px;

    .leftContainer {
      display: block;
      padding: 30px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      position: relative;
      .leftSubContainer {
        width: 80%;
        .textContainer {
          margin-bottom: 33px;

          .text {
            font-family: Gilroy-Bold;
            font-style: normal;
            font-weight: 100;
            font-size: 30px;
            line-height: 36px;
            text-transform: uppercase;

            color: #ffffff;
          }
        }
        .divider {
          border: 0.1px solid #47d7ac;
          width: 303px;
          margin-bottom: 21px;
        }

        .highlightedText {
          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: 100;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
          height: 100%;
          color: #4fdcb5;

          margin-bottom: 0px;
          width: 303px;
        }
        .btnContainer {
          display: none;
        }
      }
    }
    .rightContainer {
      width: 100%;
      height: 100%;

      .imageContainer {
        width: 100%;
        height: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .MbtnContainer {
      margin: 31px 0px;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 23px;
    }
  }
}
