.container {
  margin-bottom: 7.291666666666667vw;
  height: 100%;

  .subContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
  }
  .tabPanelContainer {
    display: flex;
    width: 66.66666666666667vw;
    flex-wrap: wrap;
    column-gap: 3.1770833333333335vw;
    row-gap: 7.135416666666667vw;
    justify-content: center;
  }
}
