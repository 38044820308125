.container {
  // min-height: 100vh;
  background: #21303d;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 190px;
  @media (max-width: 880px) {
    padding-top: 100px;
    min-height: 100%;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 940px;
    justify-content: center;
    // row-gap:80px;
    .heading {
      font-family: Gilroy-Medium;
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 100%;

      color: #ffffff;
      max-width: 1300px;

      padding: 80px 0px;

      @media (max-width: 980px) {
        font-size: 30px;
        padding: 45px 0px;
      }
    }

    .heroImage {
      display: flex;
      justify-content: end;
      flex-direction: column;
      align-items: center;
      width: 100%;
      object-fit: contain;
      padding-bottom: 55px;
      // min-height: 740px;
      img {
        width: 100%;
        height: 100%;
      }
      .laptop {
        position: relative;
        margin-top: -180px;


        @media(max-width:500px){
          margin-top:-120px;
        }
        .videoContainer {
          position: absolute;
          top: 0;
          left: 50%;
          width: 74%;
          transform: translate(-50%, 10%);
          video {
            height: 100%;
            width: 100%;
          }
        }
      }
      @media (max-width: 980px) {
        min-height: 100%;
      }
    }
    .navigationBar {
      // width: 940px;

      // height: 55px;
      padding: 17px 65px;
      border: 1px solid #bdc2c7;
      box-shadow: 0px 0px 28.2051px rgba(0, 0, 0, 0.25);
      border-radius: 28.2051px;
      .navigationItem {
        display: flex;
        column-gap: 100px;
        .itemContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 11px;
          // padding: 0px 50px;

          .iconText {
            font-family: Gilroy-Medium;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;

            color: #fbfcfd;
          }
        }
      }
    }

    .navBar {
      display: flex;
      justify-content: center;

      padding-bottom: 80px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
