.ArrowContainer {
  background-color: #ffffff;
  height: 2.0833333333333335vw !important;
  width: 2.0833333333333335vw !important;
  border-radius: 50%;
  z-index: 10;
  position: absolute;
  top: 45%;
  right: -1.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.ArrowContainer:hover {
  z-index: 10;
}

@media (max-width: 900px) {
  .ArrowContainer {
    top: 50%;
    right: -2%;
    height: 25px !important;
    width: 25px !important;
  }
}
